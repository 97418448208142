import '@material/react-chips/dist/chips.css';
import {Button, Card, CardActions, CardContent, MenuItem, MenuList, Paper, TextField, makeStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import { Chip } from '@material/react-chips';
import PropTypes from 'prop-types';
import SelectInput from '../../../../../../SelectInput';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

const useStyles = makeStyles( () => ({ menuChips: { position: 'absolute', top: 44, left: 2, } }));

const CustomInputChip = ({
  chipsData,
  t,
  customers,
  filterData,
  id,
  label,
  onApply,
  professionals,
}) => {
  const classes = useStyles();

  const dayOfWeekData = [
    {'id': 1, 'value': t('day.monday')},
    {'id': 2, 'value': t('day.tuesday')},
    {'id': 3, 'value': t('day.wednesday')},
    {'id': 4, 'value': t('day.thursday')},
    {'id': 5, 'value': t('day.friday')},
    {'id': 6, 'value': t('day.saturday')},
    {'id': 7, 'value': t('day.sunday')},
  ];

  const oddEvenDayData = [
    {'id': 1, 'value': t('day.par')},
    {'id': 2, 'value': t('day.impar')},
  ];

  const filtersData = [
    { id: 'employeeId', title: t('label.driver'), code: 'employeeId', type: 'select', 'multiple': false },
    { id: 'appointmentId', title: t('appointment.title'), code: 'appointmentId', type: 'text', 'multiple': false },
    { id: 'customerId', title: t('reassignAppointments.filter.customer'), code: 'customerId', type: 'select' },
    { id: 'dayOfWeek', title: t('reassignAppointments.filter.day_of_week'), code: 'dayOfWeek', type: 'select', 'multiple': true },
    { id: 'evenDay', title: t('reassignAppointments.filter.day_par_impar'), code: 'evenDay', type: 'select', 'multiple': false },
    { id: 'startTime', title: t('reassignAppointments.filter.time_start'), code: 'startTime', type: 'select', 'multiple': true },
  ];

  const timesData = [];
  for (let h = 0; h < 24; h += 1) {
    const hour = moment(h, 'H').format('hh a');
    const toHour = moment(h, 'H').add(1, 'hours').format('hh a');

    timesData.push({
      'id': h,
      'value': t('reassignAppointments.filter.betweenAnd', {
        initialTime: hour,
        endTime: toHour,
      }),
    });
  }

  const [showItems, setShowItems] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const dayOfWeek = dayOfWeekData;
  const oddEvenDay = oddEvenDayData;
  const startTimes = timesData;
  const filters = filtersData;

  const onChipClick = () => setShowItems((prevState) => !prevState);

  const handleEvent = (e) => {
    const selected = filters.find(f => f.id === e.target.id);
    const optionsMap = {
      'customerId': customers,
      'practiceTypeId': filterData.practicesType,
      'dayOfWeek': dayOfWeek,
      'evenDay': oddEvenDay,
      'startTime': startTimes,
      'employeeId': professionals
    };
  
    const optionsItems = optionsMap[selected.code] || null;
  
    setItems(optionsItems);
    setShowItems(!showItems);
    setSelectedType(selected);
  };

  const findValue = (value) =>  value && items.find(f => value == f.id).value;
  
  const findValueMultiple = (value) => {
    let desc = ' ';
    value.forEach(e => {
      desc = `${desc} '${items.find(f => e === f.id).value}'`;
    });
    return desc;
  }

  const handleValue = (value) => {
    const select = selectedType;
    select['value'] = value;
    if (select.type === 'text') {
      select['name'] = value;
    }
    if (select.type === 'select' && !select.multiple) {
      select['name'] = findValue(value);
    }
    if (select.type === 'select' && select.multiple) {
      select['name'] = findValueMultiple(value);
    }
    setSelectedType(select);
  }

  const handleCloseDialog = () => setSelectedType(null);

  const handleApply = () => {
    if (!selectedType.value) {
      return;
    }

    const selected = selectedType;
    onApply(selected);
    setSelectedType(null);
  }

  const renderCardDialogItem = (data) => {
    if (data.type === 'select') {
      return (
        <SelectInput
          key={`${data.code}${data.id}`}
          className="customInputChipSelect" 
          elements={items}
          multi={data.multiple}
          onSelectedValue={(value) => handleValue(value)}
          onGetOptionLabel={(option) => option.value}
          getOptionSelected={(option) => option.id || undefined}
          label={data.title}
        />
      )
    }
    return (
      <TextField
        key={`${selectedType.code}${selectedType.id}`}
        fullWidth
        type="number"
        label={selectedType.title}
        value={filterData[selectedType.id]}
        onChange={(value) => handleValue(value.target.value)}
      />
    )
  }

  const renderCardDialog = () => {
    return (
      <Card
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
          <CardContent>
            <div style={{width: '300px'}}>
              {renderCardDialogItem(selectedType)}
            </div>
          </CardContent>
          <CardActions>
            <Button id="button-filters-close" onClick={handleCloseDialog} color="primary">
              {t('dashboardprofesional.filters.close')}
            </Button>
            <Button id="button-filters-apply" onClick={handleApply} color="primary">
              {t('dashboardprofesional.filters.apply')}
            </Button>
          </CardActions>
      </Card>
    );
  }

  const hasValue = (v) => chipsData.some(item => item.code === v);

  return (
    <>
      {selectedType && renderCardDialog()}
      <Chip
        id={id}
        label={label}
        handleInteraction={onChipClick}
      />
      {showItems ?
        <div className={classes.menuChips}>
          <Paper>
            <MenuList>
              {filters.map((i, key) => {
                if (hasValue(i.code)) {
                  return <div key={1}></div>
                }
                return (
                  <MenuItem key={i.id} id={i.id} onClick={handleEvent}>{i.title}</MenuItem>)}
                )}
            </MenuList>
          </Paper>
        </div>
      : <div/>}
    </>
  )
}

CustomInputChip.defaultProps = {
  professionals: [],
  customers: [],
  onApply: () => {},
  id: '',
  label: '',
};

CustomInputChip.propTypes = {
  t: PropTypes.func,
  id: PropTypes.string,
  professionals: PropTypes.array,
  customers: PropTypes.array,
  onApply: PropTypes.func,
  chipsData: PropTypes.array,
  label: PropTypes.string,
  filterData: PropTypes.object,
}

export default withTranslation()(CustomInputChip);
