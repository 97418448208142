import './NewSchedule.css';

import React, { Component } from 'react';

import AppointmentToolsService from '../../containers/AppointmentsToolsService';
import Button from '../CustomButtons/Button';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardHeader from '../Card/CardHeader';
import CardIcon from '../Card/CardIcon';
import CustomSweetAlert from '../CustomSweetAlert';
import FullLoading from '../FullLoading';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import { HealthBook } from '../../icons';
import PropTypes from 'prop-types';
import SchedulerInvoker from '../../api/SchedulerInvoker'
import SchedulerService from '../../containers/SchedulerService';
import Snackbar from '../../components/Snackbar/Snackbar';
import _ from 'lodash';
import { browserHistory } from 'react-router';
import config from '../../config/config';
import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';
import { withTranslation } from 'react-i18next';

const formatDate = config.getDateFormat();
const dateServer = config.getDateToServer();
const dateTimeToServer = config.getDateTimeFormatServer();

class NewSchedule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmOpen: false,
      customer: {},
      performAppointmentToolsSave: false,
      provider: {},
      diagnostic: '',
      schedule: [],
      loading: false,
      checkInvalid: false,
      alertOpen: false,
      alertStatus: '',
      bookingErrors: [],
      errorQuantity0: false,
      bookingsResult: null,
      financierId: '',
      affiliateId: '',
      metadata: {
        requestor: {},
        auditor: {},
        family: {},
        disability: {},
      },
      records: {},
      alertMessageOpen: false,
      alertMessageColor: '',
      alertMessageMsg: '',
      isDateRangeValid: true,
    }
  }
  // Function to Detect Page Reload by MH-
  onUnload = (event) => {
    event.preventDefault();
    // Chrome requires returnValue to be set.
    event.returnValue = '';
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.onUnload, false);
    if (this.props.schedule) {
      this.setState({
        customer: this.props.schedule.customer,
        provider: this.props.schedule.provider,
        diagnostic: this.props.schedule.diagnostic,
        schedule: this.props.schedule.schedule,
        financierData: this.props.schedule.financierData,
        records: this.props.records,
      });
    }
  }

  componentWillUnmount() {
    this.props.onResetSchedule();
    this.props.onFilledBarthel({});
    this.props.onFilledComplexity({});
    this.props.onFilledPatology({});
    this.props.onFilledHospitalPatology({});
    window.removeEventListener('beforeunload', this.onUnload, false);
  }

  checkScheduleQuantity = () => {
    let found = false;
    this.state.schedule.forEach((r, index) => {
      if (r.quantity === 0) {
        found = true;
      }
    });
    return found;
  }

  onDateRange (isValid) {
    this.setState({ isDateRangeValid: isValid });
  }

  loadBookings() {
    const { t } = this.props;
    const isRequired = this.isRequiredFields();
    if (!isRequired) {
      this.setState({ checkInvalid: true });
      this.openAlert('warning', t('appointment.new.requiredFields'));
      return;
    }

    if (!this.state.isDateRangeValid) {
      this.openAlert('warning', t('appointment.new.invalidRangeDates'));
      return;
    }
    const isQuantity = this.checkScheduleQuantity();
    if (isQuantity) {
      this.setState({ errorQuantity0: true });
      return;
    }

    this.setState({ confirmOpen: true });
  }

  getAppointmentDates() {
    const { provider: { dateStart, dateEnd } } = this.state;
    const startDate = moment(dateStart).format(dateServer);
    const endDate = moment(dateEnd).format(dateServer);
    return { startDate, endDate };
  }

  buildBodyNewSchedule() {
    const { affiliateId, customer, diagnostic, financierId, metadata, observations, schedule } = this.state;
    const { barthel, complexity, hospitalPatology, patology } = this.props;
    const items = this.formatItemsBody(schedule)
    const { startDate, endDate } = this.getAppointmentDates();
    const body = {
      affiliateId,
      financierId,
      addressId: customer.address,
      customerId: customer.customer,
      creationEmployeeId: localStorage.getItem('user_id'),
      description: diagnostic,
      items,
      startDate,
      endDate,
      metadata: {
        // DO NOT DELETE
        sensibility: 1,
        hasTubes: 0,
        medicalData: {
          requestor: metadata.requestor,
          auditor: metadata.auditor,
          family: metadata.family
        },
        diagnostic: {
          principalDiagnostic: {
            id: patology.recordId,
            description: patology.name,
            category: patology?.parent?.parent?.recordId,
            subcategory: patology?.parent?.recordId,
          },
          hospitalizationDiagnostic: {
            id: hospitalPatology.recordId,
            description: hospitalPatology.name,
            category: hospitalPatology?.parent?.parent?.recordId,
            subcategory: hospitalPatology?.parent?.recordId
          },
          complexity: {
            id: complexity?.id,
            description: complexity?.name,
          },
          barthelIndex: {
            id: barthel?.id,
            description: barthel?.name,
          },
          observations,
        },
        disability: {
          disabledPatient: metadata?.disability?.disabledPatient,
          activeDisabilityCertificate: metadata?.disability?.activeDisabilityCertificate,
          disabilityCertificateCode: metadata?.disability?.disabilityCertificateCode,
        }
      }
    };
    return body;
  }

  saveNewSchedule() {
    const { customer, diagnostic, financierData, provider, schedule } = this.state;
    this.setState({ loading1: true, confirmOpen: false });
    const body = this.buildBodyNewSchedule();
    
    SchedulerInvoker.postAppointment(body, appointement => {
      if (appointement.status === 'OK') {
        this.props.onSaveSchedule({
          customer,
          provider,
          diagnostic,
          schedule,
          financierData,
          appointmentId: appointement.appointmentId,
          customerId: appointement.customerId,
          startDate: appointement.startDate,
        });
        this.setState({ appointmentId: appointement.appointmentId }, () => {
          this.performSaveTools();
        });
        return;
      }
      this.setState({
        alertOpen: true,
        confirmOpen: false,
        checkInvalid: false,
        bookingErrors: appointement.errors,
        alertStatus: appointement.status,
        bookingsResult: appointement,
        loading: false,
      });
    }, (error) => {
      console.log('** error postAppointment', error);
      this.setState({ loading: false });
      const message = error.message ?? error;
      this.openAlert('danger', message + '');
    });
  }

  performSaveTools() {
    this.setState({ performAppointmentToolsSave: true });
  }

  saveAppointmentTools() {
    browserHistory.push('/solicitudes');
  }

  cancel() {
    this.setState({
      alertOpen: false,
      bookingErrors: [],
      alertStatus: '',
      bookingsResult: null
    })
  }

  continue() {
    this.saveAppointmentTools();
  }

  isRequiredFields() {
    const {
      affiliateId,
      financierId,
      schedule,
      provider: { provider, dateStart, dateEnd },
      customer: { customer, address },
    } = this.state;
    const { hospitalPatology, patology } = this.props;
  
    return (
      !!(customer &&
      address &&
      provider &&
      dateStart &&
      dateEnd &&
      financierId &&
      affiliateId &&
      patology.recordId &&
      hospitalPatology.recordId &&
      schedule.length > 0)
    );
  }

  buildSchedulePlanOnEmpty(s) {
    const isNotOcurrences = (s.plan.recurrence.finish === '1' || s.plan.recurrence.endType === 'DATE') ? 'DATE' : null;
    const isNotWeekRecirrence = Array.isArray(s.plan.recurrence.weekDays) ? s.plan.recurrence.weekDays.join(',') : s.plan.recurrence.weekDays;
    return {
      endType: (s.plan.recurrence.finish === '2' || s.plan.recurrence.endType === 'OCURRENCES') ? 'OCURRENCES' : isNotOcurrences,
      every: s.plan.recurrence.every,
      monthDay: s.plan.recurrence.monthRecurrence || s.plan.recurrence.monthDay,
      ocurrences: s.plan.recurrence.finishOcurrences || s.plan.recurrence.ocurrences,
      recurrenceType: s.plan.recurrence.recurrenceType || null,
      weekDays: s.plan.recurrence.weekRecurrence ? s.plan.recurrence.weekRecurrence.join(',') : isNotWeekRecirrence,
    }
  }

  buildSchedulePlan(s) {
    let schedulePlan = s.plan;
    let estimatedDuration = null;
    if (schedulePlan != null) {
      if (schedulePlan?.plan?.estimatedDuration) {
        estimatedDuration = schedulePlan.plan.estimatedDuration;
      }
      schedulePlan = this.buildSchedulePlanOnEmpty(s);

      if (s.plan.recurrence.recurrenceType === 'INTRADAY') {
        schedulePlan.processingScheduleType = 'INTRADAY';
        schedulePlan.processingStartTimes = null;
        schedulePlan.every = 1;
        const estimatedDurationNumber = estimatedDuration ?? 1;
        const isNotProcessingDurationTime = parseInt(s.plan.recurrence.quantityIntraday) * estimatedDurationNumber;
        schedulePlan.processingDurationTime = s.plan.recurrence.processingDurationTime ? s.plan.recurrence.processingDurationTime : isNotProcessingDurationTime;
        schedulePlan.recurrenceType = 'WEEK';
      }
    }
    return schedulePlan;
  }

  buildItemRequestPackage(m) {
    const row = {}
    row['practiceTypeId'] = m['practiceType']['practiceTypeId'];
    row['recurrentType'] = m.schedulePlan['recurrenceType'];
    row['every'] = m.schedulePlan['every'];
    row['endType'] = m.schedulePlan['endType'];
    row['processingScheduleType'] = m.schedulePlan['processingScheduleType'];
    row['processingDurationTime'] = m.schedulePlan['processingDurationTime'];
    const onValidRecurrence = Array.isArray(m.schedulePlan.weekRecurrence) ? m.schedulePlan.weekRecurrence.join(',') : m.schedulePlan.weekRecurrence;
    const isNotSchedPlanRecurrence = Array.isArray(m.schedulePlan.weekDays) ? m.schedulePlan.weekDays.join(',') : m.schedulePlan.weekDays;
    row['weekDays'] = m.schedulePlan.weekRecurrence ? onValidRecurrence : isNotSchedPlanRecurrence;

    let startTime = m['startTime'];
    let endTime = m['endTime']

    if (startTime != null) {
      startTime = moment(startTime, 'hh:mm a').format('HH:mm');
    }
    if (endTime != null) {
      endTime = moment(endTime, 'hh:mm a').format('HH:mm');
    }
    row['monthDay'] = m.schedulePlan.monthRecurrence ? m.schedulePlan.monthRecurrence : null;
    row['ocurrences'] = m.schedulePlan['ocurrences'];
    row['startTime'] = startTime ?? '10:00 am';
    row['endTime'] = endTime ?? '11:00 pm';
    row['observations'] = m.observations;
    return row;
  }

  buildItemRequestPackageDetails(s) {
    let itemRequestPackageDetails = [];
    if (s.itemRequestPackageDetails) {
      itemRequestPackageDetails = [];
      s.itemRequestPackageDetails.forEach(m => {
        const row = this.buildItemRequestPackage(m);
        itemRequestPackageDetails.push(row);
      });
    }
    return itemRequestPackageDetails;
  }

  buildCompanyPartnership(s) {
    if (s.companyIdPartnership) {
      return { "companyId": s.companyIdPartnership };
    }
    if (s.plan?.companyIdPartnership != null) {
      return { "companyId": s.plan.companyIdPartnership };
    }
    if (s.module?.companyIdPartnership != null) {
      return { "companyId": s.module.companyIdPartnership };
    }
    return null;
  }

  buildFormatDate(date) {
    return moment(date, [formatDate, dateTimeToServer]).format(dateServer);
  }

  buildStartDate(data) {
    const startDate = data.plan ? _.get(data, 'plan.startDate', data.startDate) : _.get(data, 'module.startDate', data.startDate);
    if (!startDate) {
      return null;
    }
    return this.buildFormatDate(startDate);
  }

  buildEndDate(data) {
    const endDate = data.plan ? _.get(data, 'plan.endDate', data.endDate) : _.get(data, 'module.endDate', data.endDate);
    if (!endDate) {
      return null;
    }
    return this.buildFormatDate(endDate);
  }

  formatItemsBody(schedules) {
    return schedules.map((s, i) => {
      const schedulePlan = this.buildSchedulePlan(s);
      const itemRequestPackageDetails = this.buildItemRequestPackageDetails(s);
      const companyIdPartnership = this.buildCompanyPartnership(s)
      const startDate = this.buildStartDate(s);
      const endDate = this.buildEndDate(s);
      const data = {
        serviceProvider: { serviceProviderId: this.state.provider.provider },
        order: i + 1,
        appointmentItemRequestStatus: 'CREATED',
        startDate,
        endDate,
        startTime: s.plan ? _.get(s, 'plan.startTime', s.startTime) : _.get(s, 'module.startTime', s.startTime),
        endTime: s.plan ? _.get(s, 'plan.endTime', s.endTime) : _.get(s, 'module.endTime', s.endTime),
        packageId: (s.module || s.packageId) ? _.get(s, 'module.module.packageId', s.packageId) : null,
        packageQuantity: s.module?.module ? _.get(s, 'module.module.packageQuantity', s.module.packageQuantity) : s.packageQuantity,
        practiceTypeId: s.plan ? _.get(s, 'plan.plan.id', _.get(s, 'plan.practiceTypeId')) : null,
        itemRequestDetails: s.plan ? _.get(s, 'plan.itemRequestDetails', _.get(s, 'itemRequestDetails')) : _.get(s, 'module.itemRequestDetails', _.get(s, 'itemRequestDetails')),
        schedulePlan,
        companyIdPartnership,
        itemRequestPackageDetails,
      }
      if (s?.observations || s?.module) {
        data.observations = s?.observations || s?.module.observations;
      } else {
        if (s?.observations || s?.plan) {
          data.observations = s?.observations || s?.plan.observations;
        }
      }
      return data;
    });
  }

  setProvider(value) {
    this.setState({ provider: value }, () => {
      this.props.onSaveDates({
        startDate: this.state.provider.dateStart,
        endDate: this.state.provider.dateEnd,
      });
    });
  }

  setFinancier(value) {
    this.setState({
      financierData: value,
      financierId: value?.financierId ?? '',
      affiliateId: value?.affiliateId ?? '',
    });
  }

  setMetadata(value) {
    this.setState({ metadata: value });
  }

  setDisabilityMetadata(value) {
    const currMeta = this.state.metadata;
    const { activeDisabilityCertificate, disabledPatient, disabilityCertificateCode } = value;
    const newMeta = {
      ...currMeta,
      disability: {
        disabledPatient: disabledPatient || false,
        activeDisabilityCertificate: activeDisabilityCertificate || false,
        disabilityCertificateCode: disabilityCertificateCode || null,
      }
    };
    this.setState({ metadata: newMeta });
  }

  setObservations(value) {
    this.setState({ observations: value });
  }

  openAlert = (color, message) => {
    this.setState({
      alertMessageColor: color,
      alertMessageMsg: message,
      alertMessageOpen: true
    });
    
    setTimeout(() => {
      this.setState({ alertMessageOpen: false });
    }, 5000);
  }

  handleAppointmentToolsSaveError(error) {
    this.setState({
      loading: false,
      bookingErrors: error.errors,
      alertStatus: error.status,
      bookingsResult: error
    });
  }

  setCompareItems = (items, compare) => {
    const itemsFilter = items.filter((f) => f[compare]);
    const countItems = itemsFilter.length;
    const uniqueItems = _.uniqBy(itemsFilter, compare);
    const countUniqueItems = uniqueItems.length;
    return countItems === countUniqueItems;
  }

  getEqualsItems = (items) => {
    const isUniquePractices = this.setCompareItems(items, 'specialty');
    const isUniqueModules = this.setCompareItems(items, 'packageId');
    return isUniqueModules && isUniquePractices;
  }

  getBookingErrors(bookingErrors, keyText = 'error') {
    const bookingErrorsList = bookingErrors.map((e, index) => ({ ...e, key: index+1 }));
    return bookingErrorsList.map((e) => <li key={`${keyText}-${e.key}`} className="schedule-errors-item">- {e.detail}</li>);
  }

  renderAlertOpen = (alertStatus, bookingErrors, t) => {
    if (alertStatus === 'ERROR') {
      return (
        <CustomSweetAlert
          type="error"
          title={t('appointment.new.appointmentReview')}
          onConfirm={() => this.cancel()}
          confirmBtnCssClass="btn danger"
          confirmBtnText={t('cancel')}
          showCancel={false}
          message={
            bookingErrors.length > 0 &&
              <ul className="schedule-errors-list">
                {this.getBookingErrors(bookingErrors)}
              </ul>
          }
        />
      )
    }
    if (alertStatus === 'WARNING') {
      return (
        <CustomSweetAlert
          type="warning"
          title={t('appointment.new.appointmentReview')}
          onConfirm={() => this.continue()}
          confirmBtnCssClass="btn primary"
          confirmBtnText={t('accept')}
          showCancel={false}
          message={bookingErrors.length > 0 &&
            <ul className="schedule-errors-list">
              {this.getBookingErrors(bookingErrors, 'warning')}
            </ul>
          }
        />
      )
    }
  }

  render() {
    const { t, barthel, complexity, hospitalPatology, onFilledPatology, onFilledHospitalPatology, onFilledBarthel,
      onFilledComplexity, patology, schedule } = this.props;
    const {
      alertMessageColor, alertMessageMsg, alertMessageOpen, alertOpen, alertStatus, appointmentId, bookingErrors, checkInvalid, confirmOpen,
      customer, errorQuantity0, loading, financierData, performAppointmentToolsSave, provider, isDateRangeValid,
    } = this.state;

    const schedulesState = this.state.schedule;

    return (
      <GridContainer>
        {loading && <FullLoading />}
        <Snackbar
          place="tr"
          color={alertMessageColor}
          message={alertMessageMsg}
          open={alertMessageOpen}
        />
        <GridItem className="schedule-form" xs={12} sm={12} md={6}>
          <SchedulerService.CustomerFormService
            onFilledCustomer={value =>
              this.setState({ customer: value })
            }
            value={schedule ? schedule.customer : null}
            checkInvalid={checkInvalid}
            className="customer-form-container"
          />
        </GridItem>

        <SchedulerService.ProviderFormService
          onFilledProvider={value => this.setProvider(value)}
          onFilledFinancier={value => this.setFinancier(value)}
          value={schedule ? schedule.provider : null}
          financierData={financierData}
          customerId={customer}
          checkInvalid={checkInvalid}
          isDateRangeValid={(isValid) => this.onDateRange(isValid)}
        />

        <GridItem xs={12} id="authorizer-diagnostic">
          <Card className="authorizer-diagnostic-forms">
            <CardHeader icon className={this.props.classes.cardHeader}>
              <CardIcon color="secondary">
                <HealthBook />
              </CardIcon>
              <h3 className="card-icon-title">{t('appointment.clinicalData')}</h3>
            </CardHeader>
            <CardBody>
              <SchedulerService.AuthorizerFormService
                onFilledAuthorizer={value => this.setMetadata(value)}
                value={{ metadata: this.state.metadata }}
              />
              <SchedulerService.DisabilityFormService
                value={{ metadata: this.state.metadata?.disability }}
                onFilledDisability={(value) => this.setDisabilityMetadata(value)}
              />
              <SchedulerService.DiagnosticFormService
                onFilledDiagnostic={value => this.setState({ diagnostic: value })}
                onFilledPatology={onFilledPatology}
                onFilledHospitalPatology={onFilledHospitalPatology}
                onFilledBarthel={onFilledBarthel}
                onFilledComplexity={onFilledComplexity}
                onFilledObservation={this.setObservations.bind(this)}
                barthel={barthel}
                complexity={complexity}
                patology={patology}
                hospitalPatology={hospitalPatology}
                checkInvalid={checkInvalid}
                accessor={'recordId'}
              />
            </CardBody>
          </Card>
        </GridItem>

        <SchedulerService.ScheduleDetailService
          action="create"
          onFilledSchedule={value =>  this.setState({ schedule: value })}
          checkInvalid={checkInvalid}
          dates={{
            startDate: provider?.dateStart,
            endDate: provider?.dateEnd,
          }}
          customer={customer}
          value={schedule ? schedule.schedule : null}
        />

        <AppointmentToolsService.Form
          hideControlButtons={true}
          appointmentId={appointmentId}
          afterAppointmentToolsSave={this.saveAppointmentTools.bind(this)}
          onAppointmentToolsSaveError={this.handleAppointmentToolsSaveError}
          performSave={performAppointmentToolsSave}
          startDate={moment(provider?.dateStart).format(dateServer)}
          endDate={moment(provider?.dateEnd).format(dateServer)}
        />

        <GridItem xs={12} className="schedule-grid-submit justify-space-between">
          <Button id="button-cancel" onClick={() => browserHistory.push('/solicitudes')}>
            {t('appointment.new.cancel')}
          </Button>
          <Button color="blue" id="button-save" disabled={!isDateRangeValid} onClick={() => this.loadBookings()}>
            {t('appointment.new.request')}
          </Button>
        </GridItem>

        {errorQuantity0 &&
          <CustomSweetAlert
            type="error"
            title={t('appointment.new.dialog.reviewappointment.title')}
            showConfirm={false}
            onCancel={() => this.setState({ errorQuantity0: false })}
            cancelBtnCssClass="btn danger"
            cancelBtnText={t('appointment.new.dialog.confimation.button_cancel')}
            showCancel
            message={
              <>
                <p>{t('appointment.new.dialog.reviewappointment.description1')}</p>
                <p>{t('appointment.new.dialog.reviewappointment.description2')}</p>
              </>
            }
          />
        }

        {confirmOpen &&
          <CustomSweetAlert
            type="warning"
            title={t('appointment.new.dialog.confimation.title')}
            onConfirm={() => this.saveNewSchedule()}
            onCancel={() => this.setState({ confirmOpen: false })}
            confirmBtnCssClass="btn primary"
            cancelBtnCssClass="btn danger"
            confirmBtnText={t('appointment.new.dialog.confimation.button_confirm')}
            cancelBtnText={t('appointment.new.dialog.confimation.button_cancel')}
            showCancel
            message={
              <>
                {!this.getEqualsItems(schedulesState) &&
                  <p>{t('appointment.practicesModules.sameSpeciality')}</p>
                } 
                <p>{t('appointment.new.dialog.confimation.description')}</p>
              </>
            }
          />
        }

        {alertOpen && this.renderAlertOpen(alertStatus, bookingErrors, t)}
      </GridContainer>
    )
  }
}

NewSchedule.defaultProps = {
  hospitalPatology: {},
  patology: {},
}

NewSchedule.propTypes = {
  t: PropTypes.func,
  onSaveSchedule: PropTypes.func,
  onSaveDates: PropTypes.func,
  schedule: PropTypes.object,
  records: PropTypes.object,
  complexity: PropTypes.object,
  barthel: PropTypes.object,
  onResetSchedule: PropTypes.func,
  onFilledBarthel: PropTypes.func,
  onFilledComplexity: PropTypes.func,
  onFilledPatology: PropTypes.func,
  onFilledHospitalPatology: PropTypes.func,
  hospitalPatology: PropTypes.object,
  patology: PropTypes.object,
  classes: PropTypes.object,
}

const styles = {
  cardHeader: {
    zIndex: '0 !important',
  }
}

export default withStyles(styles)(withTranslation()(NewSchedule));
