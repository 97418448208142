import React, { Component } from "react";

import AppointmentApiInvoker from "../../../api/AppointmentApiInvoker";
import Button from "../../CustomButtons/Button";
import CardFooter from "../../Card/CardFooter";
import CustomDialog from "../../CustomDialog";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import PropTypes from "prop-types";
import SelectInput from "../../SelectInput";
import memory from "../../../config/memory";
import { withTranslation } from "react-i18next";
import DialogTraslado2 from "./DialogTraslado2";
import ApiInvoker from "../../../api/ApiInvoker";

class ModalDetailServiceTraslado extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;

    this.state = {
      open: false,
      types: [
        {
          id: 1,
          value: t("Practice"),
        },
        {
          id: 2,
          value: t("Traslado"),
        },
      ],
      drivers: [],
      hospitals: [],
      traslados: memory.traslados,
      type: "",
      module: "",
      component: null,
      current: null,
      action: "",
      appointmentAction: "",
      openDrivers: false,
    };
  }

  

  componentWillReceiveProps(next) {
    this.getDrivers()
    if (next.open) {
      this.setState({ open: next.open });
      if (memory.tempService && memory.tempService.type) {
        this.setState({
          component: "traslado",
          type: 2,
        });
      }
    }
    if (next.appointment) {
    }
  }

  getDrivers = () => {
    ApiInvoker.getDrivers(
      "?page=0&pageSize=20",
      (response) => {
        this.setState({ drivers: this.formatDrivers(response) });
          
      },
      
  );
  };

  getHospital = () => {
    AppointmentApiInvoker.getHospital((response) => {
      this.setState({ hospitals: this.formatHospital(response) });
    });
  };

  formatHospital = (data) => {
    let result = data.map((c) => {
      return {
        id: c.hospitalId,
        value: `${c.businessName}`,
        address: c.address,
        latitude: c.latitude,
        longitude: c.longitude,
      };
    });
    return result;
  };

  formatDrivers = (drivers) => {
    let result = drivers.map((c) => {
      return {
        id: c.personId,
        value: `${c.firstName} ${c.lastName}`,
        DriverId: c.personId,
      };
    });
    return result;
  };

  closeModal() {
    this.setState({
      open: false,
      type: "",
      component: null,
      current: null,
    });
    this.props.onCloseModal();
  }

  closeModalDrivers() {
    this.setState({
      openDrivers: false,
    });
  }

  selectedType(value) {
    this.setState({
      type: value,
      current: null,
      component: value === 2 ? "traslado" : null,
    });
  }

  onChangeItem = (value) => {
    alert(value);
  };

  openDrivers = () => {
    
    this.setState({
      openDrivers: true,
    });
  };

  handleChangeValue = (key, value) => {
    this.setState({ [key]: value });
    
  };
  formatRecureence = (data)=>{
    return{
        recurrenceType: data.recurrenceType,
        every: data.every,
        quantityIntraday: data.quantityIntraday,
        finish: data.finish,
        finishOcurrences: data.finishOcurrences,
        finishDate: data.finishDate,
        monthRecurrence: data.monthRecurrence,
        weekRecurrence: data.weekRecurrence
    }
  }

  saveService = () => {
    if(this.props.item){
      console.log("estado a guardar",this.state)
      console.log(this.props.editData)
      let itemToEdit= {
        recurrence:this.state.recurrence ? this.state.recurrence: {
          recurrenceType:this.props.editData?.items?.[0].recurrenceType,
          every: parseInt(this.props.editData?.items?.[0].every ?? '', 10) || null,
          quantityIntraday:this.props.editData?.items?.[0].quantityIntraday
        },
        driver:this.state.driver
      }
      this.props.actionEdit(this.props.item, itemToEdit)
      this.closeModal();
    }
    /*const nosocomioObject = memory.nosocomios.filter(
      (mo) => mo.id === this.state.nosocomio
    );
    const transladoObject = memory.traslados.filter(
      (tra) => tra.id === this.state.traslado
    );

    const driverObject = memory.drivers.filter(
      (drive) => drive.id === this.state.driver
    );
    let recurrence = this.state.recurrence;
    recurrence.endType = "FINISH";
    let body = {
      type: this.state.type,
      traslado: transladoObject[0],
      nosocomio: nosocomioObject[0],
      driver: driverObject.length > 0 ? driverObject[0] : null,
      startDate: this.state.startDate,
      startTime: this.state.startTime,
      recurrence: recurrence,
      hospitalId: this.state.nosocomio,
    };
    memory.tempService = body;*/
    else{
      console.log(this.state)
      console.log(this.props.editData?.[0].recurrence.items[0] , this.state.driver)
      const newItem = {
        ...this.props?.editData?.[0]?.recurrence?.items[0],
        driverId: { personId: this.state?.driver || this.props?.editData?.[0]?.recurrence?.items?.[0]?.driver.personId },
        recurrenceType:this.state?.recurrence?.recurrenceType || this.props.editData?.[0].recurrence.items[0].recurrenceType,
        every: parseInt(this.state.recurrence?.every || this.props.editData?.[0].recurrence.items[0].every, 10) || null,
        quantityIntraday:this.state.recurrence?.quantityIntraday || this.props.editData?.[0].recurrence.items[0].quantityIntraday,
        
      };
    
      console.log(newItem);
      AppointmentApiInvoker.postEditTransferAppointment(this.props.editData?.[0].recurrence.items[0].transferItemRequestId,newItem, (response) => {
        this.props.actionEdit()
        this.closeModal();
        
      }, (error) => {
        
        console.error('** error', error);
      });
    }
  };

  render() {
    const { t } =
      this.props;
    const { component, open } =
      this.state;
    let widthModal = "xs";
    if (this.state.component) {
      widthModal = "md";
    }
    console.log("el edit",this.props.editData)
    return (
      <div>
        <CustomDialog
          maxWidth={"md"}
          title={t("service.title")}
          open={open}
          onClose={() => this.closeModal()}
        >
          <div>
            <GridContainer
              className={
                component == "module"
                  ? "ps-child modules-form"
                  : "ps-child plan-form"
              }
            >
              <GridItem xs={12} sm={12} md={12} className="componentType">
                <DialogTraslado2
                  type={this.props.type}
                  openDrivers={this.openDrivers}
                  customer={this.props.customer}
                  appointment={this.props.appointment}
                  event={this.props.event}
                  viewMode={this.props.viewMode}
                  provider={this.props.provider}
                  disabled={this.props.disabled}
                  saveService={this.saveService}
                  hospitals={this.state.hospitals}
                  handleChangeValue={this.handleChangeValue}
                  editData={this.props.editData}
                  item={this.props.item}
                  itemSelect={this.props.itemSelect}
                />
              </GridItem>
            </GridContainer>
          </div>
        </CustomDialog>

        <CustomDialog
          maxWidth={"xs"}
          title={t("service.drivers.title")}
          open={this.state.openDrivers}
          onClose={() => this.closeModalDrivers()}
        >
          <div>
            <GridContainer
              className={
                component == "module"
                  ? "ps-child modules-form"
                  : "ps-child plan-form"
              }
            >
              <GridItem xs={12} sm={12} md={12} className="componentType">
                <SelectInput
                  label={t("service.drivers.title")}
                  elements={this.state.drivers}
                  onSelectedValue={(value) =>
                    this.handleChangeValue("driver", value)
                  }
                  value={
                    this.props.editData?.recurrence?.items?.find(service => service.order == this.props?.item).driver?.personId
                  }
                />
              </GridItem>
            </GridContainer>
          </div>
          <CardFooter className="schedule-form-submit buttonFooter">
            <Button
              className="buttonPlusFooter"
              onClick={() => this.closeModalDrivers()}
              color="primary"
            >
              {t("service.drivers.save")}
            </Button>
          </CardFooter>
        </CustomDialog>
      </div>
    );
  }
}

ModalDetailServiceTraslado.defaultProps = {
  onGetModuleTypes: () => [],
  onGetPracticeTypes: () => [],
};

ModalDetailServiceTraslado.propTypes = {
  open: PropTypes.bool,
  onCloseModal: PropTypes.func,
  onCreatedModule: PropTypes.func,
  onCreatedPlan: PropTypes.func,
  actionEdit: PointerEvent.func,
  onGetModuleTypes: PropTypes.func,
  onGetPracticeTypes: PropTypes.func,
  current: PropTypes.object,
  action: PropTypes.string,
};

export default withTranslation()(ModalDetailServiceTraslado);
