import React from 'react';
import PropTypes from 'prop-types';

const SidebarFooter = ({ logos }) => {
    const renderLogos = () => {
        return (
            logos.map(({id, title, linkTo, img}) => (
              <a key={`sidebarfooter-link-${id}`} href={linkTo} target={linkTo !=='#' ? '_blank': ''} rel="noopener noreferrer">
                <img className={title ? title.toLowerCase(): ''} src={img} title={title} alt={title} />
              </a>
            ))
        )
    }

    return (
      <div className="logos">
        {renderLogos()}
      </div>
    )
}

SidebarFooter.propTypes = {
    logos: PropTypes.array.isRequired,
}

export default SidebarFooter;
