import { ReasonRejected } from "../domain/rejected-reason";
import { ApiReasonRejectedFromMap } from "./api-reason-rejected.from-map";

export class ApiReasonRejectedFromMappper {
  fromMap(map: ApiReasonRejectedFromMap): ReasonRejected {
    const availableSchedule = new ReasonRejected({
      id: map.rejectedPracticeModuleId,
      detail: map.detail,
      isActive: map.active,
      companyId: map.companyId,
    });
    return availableSchedule;
  }
}
