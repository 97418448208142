import "./AppointmentDetail.css";

import { Calendar, momentLocalizer } from "react-big-calendar";
import React, { Component } from "react";

import _ from 'lodash';
import Add from "@material-ui/icons/Add";
import AppointmentApiInvoker from "../../../api/AppointmentApiInvoker";
import Assignment from "@material-ui/icons/Assignment";
import BusinessApiInvoker from "../../../api/BusinessApiInvoker";
import Button from "../../CustomButtons/Button";
import CalendarToday from "@material-ui/icons/CalendarToday";
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import CardFooter from "../../Card/CardFooter";
import CardHeader from "../../Card/CardHeader";
import CardIcon from "../../Card/CardIcon";
import CustomInput from "../../CustomInput/CustomInput";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import EventWithIcon from "../../EventWithIcon/EventWithIcon";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import ModalDetailServiceTraslado from "../Traslado/ModalDetailServiceTraslado";
import PersonApiInvoker from "../../../api/PersonApiInvoker";
import PropTypes from "prop-types";
import SchedulerService from "../../../containers/SchedulerService";
import Table from "../../Table/Table";
import Tooltip from "@material-ui/core/Tooltip";
import ValidationInput from "../../ValidationInput";
import ViewList from "@material-ui/icons/ViewList";
import { browserHistory } from "react-router";
import memory from "../../../config/memory";
import moment from "moment";
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from "react-i18next";
import FullLoading from "../../FullLoading";
import CustomSweetAlert from "../../CustomSweetAlert";
import config from "../../../config/config";
import CustomDialog from "../../CustomDialog";
import SelectInput from "../../SelectInput";

require("moment/locale/es.js");
const dateFormat = config.getDateFormat();
const dateFormatServer = config.getDateToServer();
const localizer = momentLocalizer(moment);

class AppointmentDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      metadata: {
        medicalData: {},
        diagnostic: {},
      },
      types: [
        { value: 'Sólo ida', id: 1 },
        { value: 'Sólo vuelta', id: 2 },
        { value: 'Ida y vuelta', id: 3 },
      ],
      cancellations: [],
      cancellationReason: "",
      confirmMetadataEdit: false,
      saveChangesDisabled: false,
      bookings: [],
      practiceModulesCopy: [],
      loading: false,
      openDetail: false,
      openConfirmCancel: false,
      openConfirmChanges: false,
      currentEvent: {},
      events: [],
      event_original: [],
      items: [],
      itemsCopy: [],
      updateErrors: {},
      status: "",
      alertErrorOpen: false,
      alertErrorMessage: "",
      alertOpen: false,
      alertStatus: "",
      appointment: null,
      showConfirmUndo: false,
      showConfirmSave: false,
      bookingErrors: [],
      alertMessageOpen: false,
      alertMessageColor: "danger",
      alertMessageMsg: "danger",
      rejecteds: [],
      calendarViewMode: "month",
      scheduleStatus: "",
      serviceProvider: null,
      confirmOpenNewAppointment: false,
      busy: false,
      services: [],
      openService: false,
      openAddItem:false,
      disabledButton: true,
      customersOptions: [],
      selectedItems:[],
      transfer:{},
      
    };
    this.componentCleanup = this.componentCleanup.bind(this);
  }

  componentCleanup() {
    this.confirmUndo();
  }

  onUnload = (event) => {
    event.preventDefault();
    // Chrome requires returnValue to be set.
    event.returnValue = "";
  };

  setDataToView = (data) => {};

  componentDidMount() {
    this.setCustomersOptions();
    memory.tempService = null;
   
    if (this.props.params.id) {
      
      let appointment = this.formatAppointment(this.props.params.id);
      this.setDataToView(appointment);
    }

    this.getAppointment();
    this.getCancellations();
  }

  isLoading(props, state) {
    const { selectedAppointment } = props;
    const { appointmentId, loading } = state;
    console.log(appointmentId,selectedAppointment,loading)
    return loading;
  }

  fetchItemsDetails = async () => {
    AppointmentApiInvoker.getAppointmentById(
      this.props.params.id,
      items => {
        
        const resultItems = this.formatPracticeModule(items.items);
        this.setState({ items: resultItems });
      }, (error) => {
        console.error('** error getAppointmentItemsDetails: ', error);
      }
    )
  }

  handleSelectItem = (item) => {
    this.setState((prevState) => {
        const selectedItems = [...prevState.selectedItems];
        const index = selectedItems.findIndex((selectedItem) => selectedItem.id === item.id);
        
        if (index > -1) {
            selectedItems.splice(index, 1); 
        } else {
            selectedItems.push({ id: item.id, startDate: item.startDate, endDate: item.endDate });
        }
        
        return { selectedItems };
    }, () => {
        console.log("Selected items:", this.state.selectedItems);
    });
};

  setAppointmentCopy(app) {
    const today = moment().format(dateFormatServer);
    const endDateServ = moment(app.endDate);
    let startDate = moment(endDateServ).add(1, 'days');
    const realOriginStartDate = app.startDate;
    const realOriginEndDate = app.startDate;

    const diffStart = moment().diff(startDate, 'days');
    if (diffStart > 0) startDate = today;
    const diffEnd = moment(app.endDate).diff(app.startDate, 'days');
    const endDate = moment(startDate).add(diffEnd, 'days');

    app.startDate = startDate;
    app.endDate = moment(endDate).format(dateFormatServer);
    app.toolsList = [];

    this.setState({
      customerId: app.customer.personId,
      provider: {},
      affiliateId: app.financier.financierId,
      addressId: app.address.addressId,
      creationEmployeeId: app.creationEmployee ? app.creationEmployee.personId : null,
      description: app.description,
      startDate: '',
      endDate: '',
      metadata: app.metadata,
      tools: [],
    });
    app.appointmentStatus = 'CREATED';
    this.fetchItems(app, realOriginStartDate, realOriginEndDate);
    this.props.onSelectAppointmentObject(app);
    this.setState({
      loading: false,
      customer: { zone: app.address.geographicZone.geographicZoneId }
    });
  }

  formatPracticeModule = (items) => {
    const { t } = this.props;
    const result = items.map(b => {
      const status = t(`status.appointmentItemRequest.${b.itemRequestStatus}`);
      
      return {
        order: b.order,
        practiceTypeLabel:"prueba",
        startDate: `${moment(b.startDate).format(dateFormat)}`,
        quantity: 1,
        practiceModule:"prueba practica",
        serviceProvider:"servicio",
        status:b.transferItemRequestStatus,
        lender:"AXA",
      }
    });
    return result;
  }

  getAppointment = () => {
    const { location, params } = this.props;
    AppointmentApiInvoker.getAppointmentById(params.id, app => {
      if (location.state?.mode === 'copy') {
        this.setAppointmentCopy(app.items);
      } else {
        if (app.status === 'TRANSFER_SCHEDULES_ASSIGNED') {
          this.fetchItemsDetails();
        }
        this.fetchItemsDetails()
        this.props.onSelectAppointmentObject(app);
        this.setState({ metadata: app.metadata, loading: false, customer: { zone: app.address.geographicZone.geographicZoneId } });
        
      }
    }, (error) => {
      console.error('** error getAppointment: ', error);
    });
  }

  getCancellations = () => {
    AppointmentApiInvoker.getTransferAppointmentCancellations(this.props.params.id, cancellations => {
      const cancelledTotal = cancellations.find(c => c.cancelType === 'Total') || {}
      this.setState({
        cancellationReason: cancelledTotal.reason || '',
        cancellations
      });
    }, (error) => {
      console.error('** error getAppointmentCancellations: ', error);
    })
  }

  fillSchedules = (appointment, services) => {
    try {
      const d = new Date();
      appointment.provider.dateStart = d.setDate(d.getDate() - 5);
      appointment.provider.dateEnd = d.setDate(d.getDate() + 30);
      appointment.services.startDate = appointment.provider.dateStart;
      services.startDate = d.setDate(d.getDate() - 5);
      //const schedules = this.makeBookingsFromAppointment(appointment);
      //appointment.schedules = schedules;
      return appointment, services;
    } catch (ex) {
      alert(JSON.stringify(appointment.provider));
    }
  };

 
  
  // Función de traducción
  translateStatus = (status) => {
    const statusTranslations = {
      APPROVED: 'Aprobado',
      REJECT: 'Rechazado',
      CANCELLED: 'Cancelado',
      REJECTED: 'Rechazado',
      CREATED: 'Creado'
    };

    return statusTranslations[status] || status;
  };

  formatDate = (date) => {
    let newDate = moment(date);
    if (!newDate.isValid()) {
        console.error("Fecha inválida");
        return null;
    }

    let result = newDate.format('DD/MM/YYYY');
    return result;
  };


  formatAppointment = (id) => {
    AppointmentApiInvoker.getQuantity(id, (quantity) => {
      AppointmentApiInvoker.getAppointmentById(id, (response) => {
        console.log("RESPUESTA",response)
        if (response.items) {
          let newServices=[]
          let services = response.items.map((item, index) => ({
            order: index + 1, 
            id:item.transferItemRequestId,
            type: "Traslado",
            driver:item.driver ? (item?.driver?.lastName + ' '+item?.driver?.firstName) : 'Sin asignar',  
            status: this.translateStatus(item.transferItemRequestStatus),
            serviceName: this.formatTransferTypeRecive(item.transferType ?? "STANDAR"),
            events: this.formatBookings(response.schedules),
            startDate: this.formatDate(item.startDate || response.startDate),
            endDate: this.formatDate(item.endDate || response.endDate), 
            recurrence: response,
            quantity: quantity.quantity,
            actions:
            (item.transferItemRequestStatus === "APPROVED" || item.transferItemRequestStatus === "TRANSFER_SCHEDULES_ASSIGNED" ||
            item.transferItemRequestStatus === "CANCELED" || item.transferItemRequestStatus === "ENDED" || 
            this.props.location.state?.mode === 'view') ? (
                <div />
              ) : (
                this.renderActionComponent(item, response)
              ),
          }));

          if(this.props.location.state?.transferObject){
            console.log("RESPUESTA2",this.props.location.state.transferObject )
              newServices = this.props.location.state.transferObject.items.map((item, index) => ({
              order: services.length + index+1 , 
              id:item.transferItemRequestId,
              type: "Traslado",
              driver:item.driver ? (item?.driver?.lastName + ' '+item?.driver?.firstName) : 'Sin asignar',  
              status: this.translateStatus(item.transferItemRequestStatus),
              serviceName: this.formatTransferTypeRecive(item.transferType ?? "STANDAR"),
              events: this.formatBookings(response.schedules),
              startDate: this.formatDate(item.startDate || response.startDate),
              endDate: this.formatDate(item.endDate || response.endDate), 
              recurrence: response,
              quantity: quantity.quantity,
              /*actions:
              (item.transferItemRequestStatus === "APPROVED" || item.transferItemRequestStatus === "TRANSFER_SCHEDULES_ASSIGNED" ||
              item.transferItemRequestStatus === "CANCELED" || item.transferItemRequestStatus === "ENDED" || 
              this.props.location.state?.mode === 'view') ? (
                  <div />
                ) : (
                  this.renderActionComponent(item, response)
                ),*/
            }));
            services = [...services, ...newServices];
          }



          this.setState({
            loading: false,
            services: services,
            userCreator: `${response.createEmployeeFirstName} ${response.createEmployeeLastName}`,
          });
        }
        PersonApiInvoker.getCustomer(response.customerId, (customer) => {
          PersonApiInvoker.getPersonsAddressMobility(response.customerId, (address) => {
            this.setState({
              loading: false,
              customerId: customer.id,
              
              appointment: {
                customer: {
                  customer: customer.personId,
                  name: customer.firstName,
                  lastname: customer.lastName,
                  typeDocument: customer.idType.name,
                  identity: customer.id,
                  address: address[0].addressId,
                  zone: address[0].geographicZone.detail,
                  location: address[0].location.locationId,
                  province: address[0].provinceName,
                  address_name: address[0].street,
                  latitude: address[0].latitude,
                  longitude: address[0].longitude,
                },
                startDate: response.startDate,
                endDate: response.endDate
              },
            });
          });
          BusinessApiInvoker.getCustomersFinanciers(
            customer.personId,
            (financier) => {
              this.setState((prevState) => ({
                loading: false,
                appointment: {
                  ...prevState.appointment,
                  financier: {
                    financierId: financier[0].financier.financierId,
                    affiliateId: financier[0].affiliateId,
                    financiers: financier,
                  },
                  provider: {
                    dateStart: response.startDate,
                    dateEnd: response.endDate,
                  },
                  appointmentId: response.customerId,
                  appointmentStatus: response.status,
                  schedules: response.schedules,
                },
              }));
            }
          );
        });
        return this.state.appointment;
      });
    });
  };

  setCustomersOptions = () => {
    PersonApiInvoker.getCustomersMobility((response) => {});
  };

  componentWillMount() {
    this.setCustomersOptions();
    window.addEventListener("unload", this.componentCleanup);
  }

  getServiceProviderOnEdit = stateItems => {
    let sprov = this.state.cancellations && this.state.cancellations.length > 0 ? this.state.cancellations[0].serviceProvider : {};
    sprov = this.state.serviceProvider ? this.state.serviceProvider : sprov;
    const itemsExist = stateItems && stateItems.length > 0;
    return itemsExist ? stateItems[0].serviceProvider : sprov; // agregar condicion para solicitudes canceladas --> this.state.cancellations;
  }

  getProvider = () => {
    const { financier, customer, startDate, endDate } = this.state.appointment;
    return {
      loading: false,
      customerId: {
        customer: customer?.customer,
      },
      financier:customer?.financierId,
      provider: this.getServiceProviderOnEdit(this.state.items),
      dateStart: startDate,
      dateEnd: endDate,
    };
  };

  onBack = () => {
    browserHistory.push(`/solicitudes-traslado`);
  };

  setProviderF(value) {
    this.setState(
      {
        provider: value,
      },
      () => {}
    );
  }

  setFinancier(value) {
    this.setState({
      financierData: value,
      financierId: value.financierId ? value.financierId : "",
      affiliateId: value.affiliateId ? value.affiliateId : "",
    });
  }

  getPlanQuantity(plan, startDate, endDatePlan, endDateAppointment, makeEvent) {
    const events = [];
    if (startDate == "") {
      return 0;
    }
    let endDate = endDateAppointment;
    if (!(endDate instanceof Date)) {
      endDate = moment(endDate, "YYYY-MM-DD");
    }
    if (endDatePlan != null) {
      endDate = endDatePlan;
    }

    let quantity = 1;
    const daysTotal = moment(endDate).diff(startDate, "days") + 1;
    const every = plan.recurrence.every;

    if (
      plan.recurrence.endType === "OCURRENCES" &&
      (plan.recurrence.finishOcurrences || plan.recurrence.ocurrences)
    ) {
      quantity = plan.recurrence.finishOcurrences || plan.recurrence.ocurrences;
    } else {
      if (moment(startDate).diff(endDate, "days") > 0) {
        return 0;
      }

      let currentDate = startDate;
      const endDatePlusOne = moment(endDate, "YYYY-MM-DD").add(1, "d");
      let e = every;
      let q = 0;

      if (plan.recurrence.recurrenceType === "DAY") {
        while (!moment(currentDate).isSame(endDatePlusOne, "day") && q < 100) {
          if (e == every) {
            q++;
            if (makeEvent) this.makeEvent(events, currentDate, q);

            e = 1;
          } else {
            e++;
          }
          currentDate = moment(currentDate).add(1, "days");
        }
        quantity = q;
      } else if (
        plan.recurrence.recurrenceType === "WEEK" ||
        plan.recurrence.recurrenceType === "INTRADAY"
      ) {
        let weekDays =
          plan.recurrence.weekRecurrence || plan.recurrence.weekDays;
        if (weekDays == null) {
          weekDays = "";
        }
        weekDays =
          typeof weekDays === "string" ? weekDays.split(",") : weekDays;
        let weekDaysSchedulePlan = [];
        if (weekDays.includes("L")) {
          weekDaysSchedulePlan.push(1);
        }
        if (weekDays.includes("M")) {
          weekDaysSchedulePlan.push(2);
        }
        if (weekDays.includes("X")) {
          weekDaysSchedulePlan.push(3);
        }
        if (weekDays.includes("J")) {
          weekDaysSchedulePlan.push(4);
        }
        if (weekDays.includes("V")) {
          weekDaysSchedulePlan.push(5);
        }
        if (weekDays.includes("S")) {
          weekDaysSchedulePlan.push(6);
        }
        if (weekDays.includes("D")) {
          weekDaysSchedulePlan.push(0);
        }
        let yesterdary;
        while (!moment(currentDate).isSame(endDatePlusOne, "day")) {
          if (e == every) {
            if (weekDaysSchedulePlan.includes(currentDate.day())) {
              if (plan.recurrence.recurrenceType === "INTRADAY") {
                q =
                  q +
                  parseInt(
                    plan.recurrence.quantityIntraday
                      ? plan.recurrence.quantityIntraday
                      : 1
                  );
              } else {
                q++;
                if (makeEvent) this.makeEvent(events, currentDate, q);
              }
            }
          }
          yesterdary = currentDate;
          currentDate = moment(currentDate).add(1, "days");
          if (plan.recurrence.recurrenceType === "WEEK") {
            if (!moment(yesterdary).isSame(currentDate, "week")) {
              if (e == every) {
                e = 1;
              } else {
                e++;
              }
            }
          }
        }
        quantity = q;
      } else if (plan.recurrence.recurrenceType === "MONTH") {
        let yesterdary;
        let monthDay =
          plan.recurrence.monthRecurrence || plan.recurrence.monthDay;

        while (!moment(currentDate).isSame(endDatePlusOne, "day")) {
          if (e == every) {
            if (currentDate.date() == monthDay) {
              q++;
              if (makeEvent) this.makeEvent(events, currentDate, q);
            }
          }
          yesterdary = currentDate;
          currentDate = moment(currentDate).add(1, "days");
          if (!moment(yesterdary).isSame(currentDate, "month")) {
            if (e == every) {
              e = 1;
            } else {
              e++;
            }
          }
        }
        quantity = q;
      }
    }
    if (makeEvent) {
      return events;
    }
    return quantity;
  }

  openAddService = (a) => {
    this.setState({ openService: true, itemSelect: a.transferItemRequestId });
  };
  
  openAddItem = ()=>{
    this.setState({ openAddItem: true });
  }

  formatService2 = (item, appointment) => {
    let provider = this.state.provider;
    if (appointment) {
      provider = appointment.provider;
    }
    return {
      order: 1,
      type: "Traslado",
      name: item.traslado.value,
      //startDate: item.startDate,
      startDate: "Hola 2",
      quantity: this.getPlanQuantity(
        item,
        moment(new Date(item.startDate)),
        null,
        provider ? provider.dateEnd : null,
        false
      ),
      actions:
        appointment &&
        (item.transferItemRequestStatus === "APPROVED" || item.transferItemRequestStatus === "TRANSFER_SCHEDULES_ASSIGNED" || item.transferItemRequestStatus === "CANCELED" || item.transferItemRequestStatus === "ENDED" ) ? (
          <div />
        ) : (
          this.renderActionComponent(item, appointment)
        ),
    };
  };

  constructCustomerFormValue = () => {
    const { appointment } = this.state;
    if (appointment && appointment.customer) {
      return {
        customer: appointment.customer.customer || null,
        address: appointment.customer.address || null,
        zone: appointment.customer.zone || null,
        location: appointment.customer.location || null,
        province: appointment.customer.province || null,
        address_name: appointment.customer.address_name || null,
      };
    }
    return null;
  };

  formatService = (item, appointment) => {
    let provider = this.state.provider;
    if (appointment) {
      provider = appointment.provider;
    }
    let startDate = item.startDate;
    let dateEnd = provider.dateEnd;

    if (
      appointment &&
      (appointment.appointmentId == 100 || appointment.appointmentId == 101)
    ) {
      const d = new Date();
      startDate = d.setDate(d.getDate() - 5);
      startDate = new Date(startDate);
      dateEnd = d.setDate(d.getDate() + 30);
      dateEnd = new Date(dateEnd);
    }
    return {
      order: 1,
      type: "Traslado",
      serviceName: item.traslado.value,
      recurrenceType: item.recurrence.recurrenceType,
      endType: "DATE",
      companyIdPartnership: localStorage.getItem("itlg_default_company_id"),
      processingScheduleType: "SCHEDULE",
      //startDate: item.startDate,
      startDate: this.formatDate(item.startDate),
      startDateSend: item.startDate,
      endDate: null,
      startTime: item.startTime,
      endTime: null,
      driver: item.driver.id,
      hospitalId: item.hospitalId,
      quantity: this.getPlanQuantity(
        item,
        moment(new Date(item.startDate)),
        null,
        provider ? provider.dateEnd : null,
        false
      ),
      actions:
        appointment &&
        appointment.appointmentStatus === "TRANSFER_SCHEDULES_ASSIGNED" ? (
          <div />
        ) : (
          this.renderActionComponent(item, appointment)
        ),
    };
  };

  renderActionComponent = (a, appointment) => {
    const { t } = this.props;
    

    if (appointment && appointment.appointmentStatus === "CREATED" && this.props.location.state?.mode !== "edit_agenda") {
      return (
        <div className="appointments-actions">
          <Tooltip title={t("appointment.service.edit")}>
            <div>
              <Button
                simple
                justIcon
                color="info"
                onClick={() => this.openAddService()}
              >
                <Assignment />
              </Button>
            </div>
          </Tooltip>
        </div>
      );
    } else  if (this.props.location.state?.mode !== "edit_agenda"){
      return (
        <div className="appointments-actions">
          <Tooltip title={t("appointment.service.edit")}>
            <div>
              <Button
                simple
                justIcon
                color="info"
                onClick={() => this.openAddService(a)}
              >
                <Edit />
              </Button>
            </div>
          </Tooltip>
          <Tooltip title={t("appointment.service.delete")}>
            <div>
              <Button
                simple
                justIcon
                color="info"
                authority="appointment_view,appointment_view_related"
                //onClick={() => this.deleteService()}
                onClick={() => this.openCancelAppointment(a.transferItemRequestId)}
              >
                <Delete />
              </Button>
            </div>
          </Tooltip>
        </div>
      );
    }else{
      return(<div/>)
      
    }
  };

  closeService = () => {

      this.setState((prevState) => ({
        ...prevState,
        openService: false,
      }));

      this.setState((prev)=>({
        ...prev,
        type:'',
      }))
      this.setState((prev)=>({
        ...prev,
        openAddItem:false,
      }))
        
  };

  deleteService = () => {
    memory.tempService = null;
    this.setState({ services: [] });
  };

  makeEvent = (events, date, index) => {
    const time = memory.tempService.startTime;
    date = new Date(date).setHours(
      parseInt(time.substring(0, 2)),
      parseInt(time.substring(3, 5)),
      0
    );
    const label = memory.tempService.traslado.value;
    events.push(this.makeBooking(label, date, time, index));
};

  makeBooking = (label, date, time, lastIndexOf) => {
    return {
      id: lastIndexOf,
      title: label,
      label,
      time_display: time,
      time_start: time,
      allDay: false,
      view: "month",
      start: date,
      end: date,
      color: "F06292",
      employeeId: -1,
      status: "PENDING_APPROVAL",
      type: "booking",
      errorDetail: "",
    };
  };

  makeBookings = () => {
    if (!this.state.appointment.provider.dateEnd) {
      return [];
    }
    return this.getPlanQuantity(
      this.state.services,
      moment(new Date(this.state.services.startDate)),
      null,
      this.state.appointment.provider
        ? moment(new Date(this.state.appointment.provider.dateEnd))
        : null,
      true
    );
  };

  makeBookingsFromAppointment = (appointment) => {
    return this.getPlanQuantity(
      memory.tempService,
      moment(memory.tempService.startDate),
      null,
      appointment.provider ? moment(appointment.provider.dateEnd) : null,
      false
    );
  };

  asignSchedules = () => {
    let items = this.state.selectedItems.map(item => item.id);
    let self = this; // Referencia a `this` para usar dentro del callback
  
    AppointmentApiInvoker.newScheduleWithItems(
      this.props.params.id,
      items,
      (response) => {
        // Almacena la respuesta en una variable de instancia
        self.response = response;
  
        // Ejecuta la redirección fuera del callback, asegurándote de que la respuesta esté disponible
        self.redirectToTurnosTraslado();
      },
      (error) => {
        console.error("Error en la llamada al API:", error);
      }
    );
  };
  
  redirectToTurnosTraslado = (bookings) => {
  
    browserHistory.push({
      state: {
        transferId:this.props.params.id,
        appointment: this.state?.appointment ?? "",
        bookings: this.response?.schedules ?? [],
        fromAppointmentAssignAgenda:true,
        dates: {
          startDate: this.state?.appointment?.provider?.dateStart ?? "",
          endDate: this.state?.appointment?.provider?.dateEnd ?? "",
        },
      },
      pathname: `/turnos-traslado/${this.props.params.id}`,
    });
  };
  
  
  formatTransferTypeSend = (type) => {
    if (type === "Traslado estándar") {
      return "STANDAR";
    } else if (type === "Traslado con rampa") {
      return "RAMPA";
    } else if (type === "Traslado con ambulancia") {
      return "AMBULANCIA";
    }
  };

  formatTransferTypeRecive = (type) => {
    if (type === "STANDAR") {
      return "Traslado estándar";
    } else if (type === "RAMPA") {
      return "Traslado con rampa";
    } else if (type === "AMBULANCIA") {
      return "Traslado con ambulancia";
    }
  };

  saveAppointment = () => {
    if (!this.state.provider) {
      return;
    }
    // MLGSAVE
    if(this.props.location.state.transferObject){
      console.log(this.state)
      const newItems = [...this.props.appointments.selectedAppointment.items, ...this.props.location.state.transferObject.items];

      console.log(newItems)
      AppointmentApiInvoker.postEditTransferAppointment(this.props.params.id,this.props.location.state.transferObject.items[0], (response) => {
        browserHistory.push("/solicitudes-traslado");
    
      }, (error) => {
        
        console.error('** error', error);
      });
    }
    else{
      const body = {
        customerId: this.state.customerId.customer.id,
        addressId: this.state.addressId,
        financierId: this.state.financierId,
        createEmployee: localStorage.getItem("user_id"),
        status: "CREATED",
        startDate: this.state.provider.dateStart,
        endDate: this.state.provider.dateEnd,
        items: [
          {
            recurrenceType: this.state.services[0].recurrenceType,
            endType: this.state.services[0].endType,
            companyIdPartnership: {
              companyId: this.state.services[0].companyIdPartnership,
            },
            processingScheduleType: this.state.services[0].processingScheduleType,
            startDate: this.state.startDateSend,
            endDate: this.state.services[0].endDate,
            startTime: this.state.services[0].startTime,
            hospital: { hospitalId: this.state.services[0].hospitalId },
            endTime: this.state.services[0].endTime,
            transferType: this.formatTransferTypeSend(
              this.state.services[0].serviceName
            ),
            driver: {
              personId: this.state.services[0].driver,
            },
          },
        ],
      };
  
      AppointmentApiInvoker.createAppointment(body, (response) => {
        if (response) {
          window.location.href = "/solicitudes-traslado";
        }
      });
    }
    

 
  };

  sendSMS = (idv, day, nosocomio, time, code_inicio, code_fin) => {
    let msg = "Su viaje para el dia ".concat(day);
    msg = msg.concat(" con destino a ");
    msg = msg.concat(nosocomio);
    msg = msg.concat(" inicia ");
    msg = msg.concat(time);
    msg = msg.concat(" hs., codigo de inicio: ");
    msg = msg.concat(code_inicio);
    msg = msg.concat(", codigo de finalizacion: ");
    msg = msg.concat(code_fin);
    msg = msg.concat(".");

    const details = {
      pUser: "Hmmglobal",
      pPassword: "JxTGX1uU",
      pToNum: 3512120314,
      pToCompany: 0,
      pFromNum: "11011",
      pMessage: msg,
      pmsgId: idv,
    };

    const formData = new URLSearchParams();

    for (const property in details) {
      formData.append(property, details[property]);

    }

    fetch("/api/send_sms/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        body: formData.toString(),
      }),
    })
      .then((response) => response.json())

      .then((responseData) => {});
    browserHistory.push("/solicitudes-traslado");
  };

  makeid = (length) => {
    const result = [];
    const characters = "0123456789";
    const charactersLength = characters.length;
    const array = new Uint8Array(length);
  
    window.crypto.getRandomValues(array);
  
    for (let i = 0; i < length; i++) {
      const randomIndex = array[i] % charactersLength;
      result.push(characters.charAt(randomIndex));
    }
    
    return result.join("");
  };

  selectedEvent(event, type) {
    this.setState({ event: event, openService: true, type:type });
  }

  eventColors(event, start, end, isSelected) {
    let backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor,
    };
  }

  isInProgress = () => {
    if (this.props.params.id == 101) return true;
    return false;
  };

  handleChangeValue = () => {};

  setTranferType = (type) => {
    if (type === "STANDAR") {
      return "Traslado estándar";
    } else if (type === "RAMPA") {
      return "Traslado con rampa";
    } else if (type === "AMBULANCIA") {
      return "Traslado con ambulancia";
    }
  };

  getColor(group) {
    //TODO: HMM-749
    const colors = {
      0: "E157CA",
      1: "F06292",
      2: "5AB4FF",
      3: "79009B",
      4: "D81B60",
      5: "0A85FF",
      6: "BF00B0",
      7: "880E4F",
      8: "1F3FB9",
    };

    return colors[group % 9];
  }

  formatBookings(data) {
    let bookings = data;
    // eslint-disable-next-line no-undef
    bookings = _.orderBy(bookings, ["scheduleDateTime"], ["asc"]);
    let result = [];
    bookings.map((b) => {
      if (b) {
        result.push({
          id: b.transferScheduleId,
          title: `${this.setTranferType(b.transferItemRequestId.transferType)}`,
          time_display: "",
          time_start: moment(b.startDateTime).format("HH:mm"),
          allDay: false,
          view: this.state.calendarViewMode,
          start: b.startDateTime,
          end: b.endDateTime,
          color: this.getColor(1),
          employeeId: b.employeeId,
          status: b.transferScheduleStatus,
          type: b.type,
          errorDetail: "",
          origin: b,
        });
      }
    });
    this.setState({ events: result });
  }

  setStatusAppointment = (status) => {
    if (status === "TRANSFER_SCHEDULES_ASSIGNED") {
      return "Asignada";
    } else if (status === "CREATED") {
      return "Creada";
    } else if (status === "CANCELLED") {
      return "Cancelada";
    }
    
  };

  cancelAppointmentAction = () => {
    
    AppointmentApiInvoker.deleteTransferItem(this.state.selectedCancelAppointment,  () => {
      this.setState({ showConfirmCancelAppointment: false , showConfirmationCancelAppointment: true })
    }, () => {
      this.setState({ showConfirmationCancelAppointment: true, showConfirmCancelAppointment: false })
    });
  }

  cancellationsTableData = (data, t) => data.map(cancelation => {
    return {
      'order': cancelation.order,
      'type': t(cancelation.type),
      'speciality': cancelation.speciality,
      'count': cancelation.count,
      'startDate': moment(cancelation.startDate).format(dateFormat),
      'cancelDate': moment(cancelation.cancelDate).format(dateFormat),
      'reason': cancelation.cancelType === 'Total' ? '' : cancelation.reason,
    }
  });
  getColorIcon(isEvents, isCancellations) {
    switch (true) {
      case (isEvents) && (isCancellations):
      case (!(isEvents) && !(isCancellations)):
        return 'secondary';
    
      default:
        return 'primary';
    }
  }

  getVarsRender() {
    const { t, location, selectedAppointment, selectedSchedules } = this.props;
    const { cancellations, events, event_original, selected_item, saveChangesDisabled, isDateRangeValid, openAddItem } = this.state;

    const eventChange = (JSON.stringify(events) !== JSON.stringify(event_original));
    const viewMode = location.state?.mode;
    const company_partnership_id = localStorage.getItem('company_partnership_id');
    let authorityAgenda = location.state?.authority && location.state.authority === 'appointment_edit_agenda';
    if (company_partnership_id != null) {
      authorityAgenda = true;
    }
    const cancellationsCanceled = cancellations.filter(c => c.canceled);
    const cancellationsData = this.cancellationsTableData(cancellationsCanceled, t);
    const cancellationsAppoStatus = ['CANCELLED', 'TRANSFER_SCHEDULES_ASSIGNED', 'OVERDUE', 'ENDED'];
    const isCancellationsAppoStatusOk = cancellationsAppoStatus.find(f => f === selectedAppointment?.appointmentStatus) !== undefined;
    const viewPracticesModulesTable = viewMode === 'view' || viewMode === 'assign_agenda' || viewMode === 'copy' || viewMode === 'edit' || selectedAppointment?.appointmentStatus === 'CANCELLED';
    const isEvents = events.length > 0 && viewMode !== 'copy';
    const isCancellations = cancellationsCanceled.length > 0 && isCancellationsAppoStatusOk;
    const showCoreFrames = selectedAppointment?.applicationClient !== 'appmobilepatient';
    const agendaIconColor = showCoreFrames ? 'secondary' : 'primary';
    const cancelIconColor = showCoreFrames && isEvents ? 'primary' : 'secondary';
    const toolsIconColor = this.getColorIcon(isEvents, isCancellations);
    const hasItems = selected_item?.length === 0;
    const disabledScheduleDetail = !((viewMode === 'edit' || viewMode === 'copy') && (selectedAppointment?.appointmentStatus === 'CREATED' || selectedAppointment?.appointmentStatus === 'PARTIAL_ASSIGNED' || selectedAppointment?.status === 'TRANSFER_SCHEDULES_ASSIGNED'));
    const disabledForms = (viewMode !== 'edit' && viewMode !== 'copy') || (viewMode === 'edit' && authorityAgenda);
    const disabledSaveChanges = saveChangesDisabled || !_.get(this.state, 'metadata.diagnostic.principalDiagnostic.id', _.get(this.state, 'metadata.diagnostic.principalDiagnostic.recordId')) || !_.get(this.state, 'metadata.diagnostic.hospitalizationDiagnostic.id', _.get(this.state, 'metadata.diagnostic.hospitalizationDiagnostic.recordId'));
    const isEventsError = selectedSchedules?.some(schedule => schedule.scheduleStatus === 'REJECTED');

    return {
      agendaIconColor, authorityAgenda, cancellationsData, cancelIconColor, company_partnership_id, disabledScheduleDetail, disabledForms, disabledSaveChanges,
      eventChange, hasItems, isCancellations, isEvents, isEventsError, showCoreFrames, toolsIconColor, viewMode, viewPracticesModulesTable, isDateRangeValid
    }
  }

  getRenderItems(viewMode) {
    return viewMode == 'copy' ? this.state.itemsCopy : this.state.items;
  }

  getStartEndDates(viewMode, selectedAppointment) {
    const startDate = viewMode === 'copy' && this.state.provider ? this.state.provider?.dateStart : selectedAppointment?.startDate;
    const endDate = viewMode === 'copy' && this.state.provider ? this.state.provider?.dateEnd : selectedAppointment?.endDate;

    return {
      startDate,
      endDate,
    }
  }

  replacePractices = (itemCopy) => {
    const itemsCopyNew = [];
    this.state.itemsCopy.forEach((b, i) => {
      if (b.order === itemCopy.order) {
        itemCopy.order = i
        itemsCopyNew.push(itemCopy);
      } else {
        b.order = i;
        itemsCopyNew.push(b);
      }
    });
    this.setState({ itemsCopy: itemsCopyNew });
  }

  saveNewPractices(appointementScheduleItem, cb = () => { }) {
    appointementScheduleItem.itemRequestPackageDetails = this.formatPostModules(appointementScheduleItem.itemRequestPackageDetails);
    if (this.props.location.state?.mode !== 'copy') {
      const data = this.buildBodyNew(appointementScheduleItem);
      const { appointmentId } = this.props.selectedAppointment;
      AppointmentApiInvoker.postAppointmentScheduleItems(appointmentId, data, (res) => {
        this.getAppointment();
        return cb(res);
      }, (error) => {
        console.error('** error postAppointmentScheduleItems:', error);
      });
      return;
    }
    const itemsCopyNew = this.state.itemsCopy;
    itemsCopyNew.push(appointementScheduleItem);
    const nItems = [];
    this.state.itemsCopy.forEach((b, i) => {
      b.order = i;
      nItems.push(b);
    });
    this.setState({ itemsCopy: nItems });
  }

  deletePractices(practiceToDelete, appointmentItemRequestIdToDelete) {
    if (this.props.location.state?.mode === 'copy') {
      const itemsCopyNew = [];
      let index = 0;
      this.state.itemsCopy.forEach((b, i) => {
        if (b.order !== appointmentItemRequestIdToDelete) {
          b.order = index;
          itemsCopyNew.push(b);
          index++;
        }
      })
      this.setState({ itemsCopy: itemsCopyNew });
      return;
    }

    if (!appointmentItemRequestIdToDelete) {
      const appScheduleItem = this.state.items.find(i => {
        if (i.practiceTypeId) {
          return i.practiceTypeId === practiceToDelete.practiceTypeId;
        } else if (i.packageId) {
          return i.packageId === practiceToDelete.packageId;
        } else {
          return false;
        }
      })
      if (appScheduleItem) {
        AppointmentApiInvoker.deleteAppointmentScheduleItems(appScheduleItem.appointmentItemRequestId, (res) => {
        }, (res) => {
          console.log(res);
        });
      }
    } else {
      AppointmentApiInvoker.deleteAppointmentScheduleItems(appointmentItemRequestIdToDelete, (res) => {
      }, (res) => {
        console.log(res);
      })
    }
  }
  continueService = ()=>{
    
    browserHistory.push({
      pathname: "/detalle-servicio",
      state: { appointment: this.props.appointments.selectedAppointment,
        transfer: this.state.transfer,
        origin:this.props.params.id
      }
  });
      
  }

  getProviderValue(selectedAppointment) {
    return selectedAppointment ? this.getProvider() : null;
  }

  setProvider = (serviceProvider) => this.setState({ 'serviceProvider': serviceProvider });

  renderPracticesModulesTable() {
    const { location, params, selectedAppointment } = this.props;
    const { disabledScheduleDetail, viewMode, viewPracticesModulesTable } = this.getVarsRender();

    if (viewPracticesModulesTable) {
      return (
        <SchedulerService.ScheduleDetailTransferService
          onFilledSchedule={value => {
            this.setState({ schedule: value });
          }}
          onDeletePracticeModule={(deleted, appointmentItemRequestId) => {
            this.deletePractices(deleted, appointmentItemRequestId);
          }}
          onNewPracticeModuleAdded={(value, valueRaw) => {
            this.saveNewPractices(value, valueRaw);
          }}
          onUpdatePlanCopy={(value) => {
            this.replacePractices(value[0]);
          }}
          disabled={disabledScheduleDetail}
          hasschedules={this.state.events != null && this.state.events.length > 0}
          customer={this.state.customer}
          dates={{
            startDate: this.getStartEndDates(viewMode, selectedAppointment).startDate,
            endDate: this.getStartEndDates(viewMode, selectedAppointment).endDate,
          }}
          action={location.state?.mode}
          appointmentId={params.id}
          checkInvalid={this.state.checkInvalid}
          items={this.getRenderItems(viewMode)}
          value={this.state.events}
          viewMode={viewMode}
          onCalculatePractices={(value) => {
            this.setState({ myPractices: value });
          }}
          onSelectedItems={(value, names) => {
            this.setState({ selected_item: value, selected_names: names });
          }}
          appointmentStatus={selectedAppointment?.appointmentStatus}
          setProvider={this.setProvider}
          provider={this.getProviderValue(selectedAppointment)}
        />
      )
    }
  }

  openCancelAppointment = (appointmentId) => {
    this.setState({ selectedCancelAppointment: appointmentId },
      () => {
        this.setState({ showConfirmCancelAppointment: true });
      }
    );
  }

  perfomSuccessReload = () => {
    this.setState((prevState) => ({
      showConfirmationCancelAppointment: false,
      showConfirmationEditAppointment: false
    }), () => {
      this.formatAppointment(this.props.params.id)
    });
  }
 

  setData = (validation) => {
    if (validation) {
      this.setState({ disabledButton: false });
    }
  };
  handleChangeAdd = (value)=>{
    this.setState({
      transfer:{
      type:value,
      startDate:this.props?.appointments?.selectedAppointment?.startDate,
      endDate:this.props?.appointments?.selectedAppointment?.endDate,
    }
    
    }
    )
    this.setState(prevState => ({
      transferAppointment: {
        ...prevState.transferAppointment,
        transferWay: value,
      }
    }));
  }

  actionEdit = ()=>{
    this.setState({showConfirmationEditAppointment:true})
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {}

  render() {
    const { t } = this.props;
    
    const { appointment, customerId, event, openService,openAddItem,itemSelect, provider, services, showConfirmCancelAppointment,
       selectedCancelAppointment,showConfirmationCancelAppointment, showConfirmationEditAppointment } =
      this.state;
      if (!appointment && !appointment?.appointmentId) {
        return (<FullLoading />)
      }
        console.log("datos:", this.props , this.state.appointment)
    return (
      <GridContainer>
        {appointment && (
          <GridItem className="appointment-data" xs={12}>
            <Card>
              <CardHeader icon>
                <CardIcon color="secondary">
                  <Assignment />
                </CardIcon>
                <h3 className="card-icon-title">{t("appointment.title")}</h3>
              </CardHeader>
              <CardBody>
                <GridContainer className="appointment-detail-schedule">
                  <GridItem
                    className="fullWidth-input validation-input"
                    md={4}
                    xs={4}
                    sm={4}
                  >
                    <CustomInput
                      id="appointment_id"
                      disabled={true}
                      labelText={t("appointment.input.appointment_id")}
                      value={
                        appointment.appointmentId
                          ? appointment.appointmentId
                          : ""
                      }
                      formControlProps={{
                        disabled: true,
                      }}
                    />
                  </GridItem>
                  <GridItem
                    className="fullWidth-input validation-input center-align"
                    md={4}
                    xs={4}
                    sm={4}
                  >
                    <CustomInput
                      id="appointment_status"
                      labelText={t("appointment.input.status")}
                      value={
                        appointment.appointmentStatus
                          ? this.setStatusAppointment(
                              appointment.appointmentStatus
                            )
                          : ""
                      }
                      formControlProps={{
                        disabled: true,
                      }}
                    />
                  </GridItem>
                  <GridItem className="align-right" md={4} xs={4} sm={4}>
                    <div className="align-right">
                      <CustomInput
                        id="init_date"
                        disabled={true}
                        labelProps={{ style: { marginTop: "-10px" } }}
                        inputProps={{
                          style: {
                            textAlign: "right",
                            width: "100%",
                            marginLeft: "-20px",
                          },
                        }}
                        labelText={t("appointment.input.init_date")}
                        value={
                          appointment
                            ? moment(appointment.createdDateTime).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )
                            : null
                        }
                        formControlProps={{
                          disabled: true,
                        }}
                      />
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer className="appointment-detail-schedule">
                  <GridItem
                    className="fullWidth-input validation-input"
                    xs={4}
                    sm={4}
                  >
                    <ValidationInput
                      id="appointment_id"
                      classes={{
                        input: "fullWidth-input",
                      }}
                      fullWidth
                      text={t("appointment.input.created_user")}
                      disabled={true}
                      className="fullWidth-input"
                      value={this.state.userCreator}
                      formControlProps={{
                        disabled: true,
                      }}
                    />
                  </GridItem>
                  <GridItem
                    className="fullWidth-input validation-input center-align"
                    xs={4}
                    sm={4}
                  >
                    <CustomInput
                      id="clientId"
                      labelText={t("appointment.input.clientId")}
                      value={"HMM"}
                      formControlProps={{
                        disabled: true,
                      }}
                    />
                  </GridItem>
                  <GridItem
                    className="fullWidth-input align-right"
                    xs={4}
                    sm={4}
                  >
                    <CustomInput
                      id="foreignId"
                      labelText={t("appointment.input.foreignId")}
                      value={"-"}
                      labelProps={{
                        style: { textAlign: "right", width: "100%" },
                      }}
                      inputProps={{
                        style: { textAlign: "right", width: "75%" },
                      }}
                      formControlProps={{
                        disabled: true,
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        )}
        <GridItem xs={6} sm={6} md={6}>
          <SchedulerService.CustomerFormService
            setData={this.setData}
            onFilledCustomer={(value) => {
              this.setState({
                customerId: value,
                addressId: value.address,
                zoneId: value.zone,
              });
            }}
            value={this.constructCustomerFormValue()}

            onUpdateCustomers={this.onUpdateCustomers}
            disabled={appointment != null}
          />
        </GridItem>

        <GridItem xs={6} sm={6} md={6}>
          <SchedulerService.FinancialFormService
            serviceType={"service"}
            customerId={customerId}
            onFilledProvider={(value) => this.setProviderF(value)}
            onFilledFinancier={(value) => this.setFinancier(value)}
            value={
              this.state.appointment
                ? {
                    provider: appointment.provider,
                    financier: appointment.financier,
                    customerId: appointment.customer,
                    dateStart: appointment.startDate,
                    dateEnd: appointment.endDate
                  }
                : null
            }
            disabled={appointment != null}
          />
        </GridItem>

        <GridItem className="schedule-form" xs={12} sm={12} md={12}>
          <Card>
            <CardHeader icon className="filters-header">
              <CardIcon color="secondary">
                <ViewList />
              </CardIcon>
              <h3 className="card-icon-title">{t("appointment.services")}</h3>
            </CardHeader>
            <CardBody className="appointments-content-body filtrable-table">
              <Table
                filterable
                loading={false}
                tableHeaderColor={"primary"}
                defaultPageSize={services.length > 10 ? 20 : 10}
                sortable
                tableHead={[
                  {
                    Header: "",
                    accessor: "selector",
                    Cell: ({ original }) => {
                      console.log(original)
                      return (
                      this.props.location.state?.mode === "assign_agenda"  && original.status === "Aprobado" ? (
                        <input
                          type="checkbox"
                          checked={this.state.selectedItems.some(item => item.id === original.id)}
                          onChange={() => this.handleSelectItem(original)}
                        />
                      ) : null)
                  },
                    sortable: false,
                    filterable: false,
                  },
                  { Header: t("service.order"), accessor: "order" },
                  { Header: t("service.type"), accessor: "type" },
                  { Header: "Estado", accessor: "status" },
                  { Header: t("service.name"), accessor: "serviceName" },
                  { Header: t("service.driver"), accessor: "driver" },
                  {
                    Header: t("service.start_date"),
                    accessor: "startDate",
                    sortMethod: (a, b) => this.sortDate(a, b),
                  },
                  {
                    Header: t("service.quantity"),
                    accessor: "quantity",
                    sortMethod: (a, b) => this.sortDate(a, b),
                  },
                  {
                    Header: t("appointment.table.actions"),
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                tableData={services}
                colorsColls={["primary"]}
                className="-striped -highlight filtrable sticky"
                showPaginationTop={false}
                showPaginationBottom={true}
              />
            </CardBody>
            {/*this.renderPracticesModulesTable()*/}
            <CardFooter className="schedule-form-submit buttonFooter">
              {!memory.tempService && (
                <Button
                  className="buttonPlusFooter"
                  onClick={() => this.openAddItem()}
                  disabled={false
                    /*!customerId ||
                    !this.state.customerId.latitude ||
                    !provider ||
                    !this.state.disabledButton ||
                    !provider.dateStart ||
                    !provider.dateEnd*/
                  }
                  color="primary"
                >
                  <Add />
                </Button>
              )}
            </CardFooter>
          </Card>

          <CustomDialog
          maxWidth={"xs"}
          title="Seleccionar tipo de traslado"
          open={openAddItem}
          onClose={() => this.closeService()}
        >
            <div>
              <GridContainer
                className={"ps-child modules-form"}
              >
                <GridItem xs={12} sm={12} md={12} className="componentType">
                  <SelectInput
                    label="Tipo"
                    elements={this.state.types}
                    onSelectedValue={(value) =>
                      this.handleChangeAdd(value)
                    }
                    value={this.state.type}
                  />
                </GridItem>
              </GridContainer>
            </div>
            <CardFooter className="schedule-form-submit buttonFooter">
              <Button
                className="buttonPlusFooter"
                onClick={() => this.continueService()}
                color="primary"
                disabled={false}
              >
                Continuar
              </Button>
            </CardFooter>
        </CustomDialog>

          {/*this.isInProgress() && appointment && (
            <Card>
              <CardHeader icon className="filters-header">
                <CardIcon color="primary">
                  <CalendarToday />
                </CardIcon>
                <h3 className="card-icon-title">
                  {t("appointment.trans.inprogress")}
                </h3>
              </CardHeader>
              <CardBody calendar>
                <DialogTraslado
                  customer={appointment.customer}
                  appointment={appointment}
                  event={appointment?.schedules?.[0]}
                  viewMode={"apointment"}
                  provider={appointment.provider}
                  disabled={true}
                  saveService={this.saveService}
                  handleChangeValue={this.handleChangeValue}
                />
              </CardBody>
            </Card>
          )*/}

          {appointment &&
            appointment.schedules &&
            (appointment.appointmentStatus === "TRANSFER_SCHEDULES_ASSIGNED" ||  appointment.appointmentStatus === "ENDED") && (
              <Card>
                <CardHeader icon className="filters-header">
                  <CardIcon color="secondary">
                    <CalendarToday />
                  </CardIcon>
                  <h3 className="card-icon-title">{t("appointment.agenda")}</h3>
                </CardHeader>
                <CardBody className="calendar-booking" calendar>
                  <Calendar
                    selectable
                    showMultiDayTimes={true}
                    localizer={localizer}
                    events={this.state.events}
                    defaultView="month"
                    step={30}
                    views={["month"]}
                    scrollToTime={new Date(1970, 1, 1, 6)}
                    defaultDate={new Date()}
                    onSelectEvent={(event) => this.selectedEvent(event,"calendar")}
                    eventPropGetter={this.eventColors}
                    messages={{
                      next: t("common.next"),
                      previous: t("common.prev"),
                      today: t("common.today"),
                      month: t("common.month"),
                      week: t("common.week"),
                      day: t("common.day"),
                      event: t("common.event"),
                      date: t("common.date"),
                      time: t("common.hour"),
                    }}
                    components={{
                      event: EventWithIcon,
                    }}
                  />
                </CardBody>
              </Card>
            )}

          <GridContainer className="buttons-containers">
            <GridItem xs={10} sm={10} md={10}>
              <Button onClick={() => this.onBack()}>
                {t("appointment.cancel")}
              </Button>
            </GridItem>
            <GridItem xs={2} sm={2} md={2} style={{ textAlign: "right" }}>
              {appointment != null &&
                ((appointment.appointmentStatus === "CREATED" &&
                this.props.location?.state?.mode !=="view") || this.props.location?.state?.mode ==="edit_agenda")  && (
                  <Button
                    color="blue"
                    onClick={() => this.asignSchedules()}
                    className="align-right"
                    disabled={this.state.selectedItems.length > 0 ? false : true}
                  >
                    {t("appointment.asign")}
                  </Button>
                )}
              {//appointment === null appointment.appointmentStatus === "TRANSFER_SCHEDULES_ASSIGNED"&& (
              /*appointment.appointmentStatus !== "CANCELLED" && (
                <Button
                  className="align-right"
                  color="blue"
                  disabled={!services.length}
                  onClick={() => this.saveAppointment()}
                >
                  {t("appointment.save")}
                </Button>
              )*/
              }
            </GridItem>
          </GridContainer>
        </GridItem>
        <ModalDetailServiceTraslado
          open={openService}
          type={this.state.type}
          onCloseModal={this.closeService}
          disabled={appointment}
          event={event}
          viewMode={this.props.location.state?.mode}
          provider={provider}
          appointment={appointment}
          customer={appointment ? appointment.customer : customerId}
          onSaveService={this.addService}
          editData={services}
          itemSelect={itemSelect}
          actionEdit={this.actionEdit}
        />
        
        {showConfirmCancelAppointment &&
          <CustomSweetAlert
            type="warning"
            onConfirm={this.cancelAppointmentAction}
            onCancel={() => this.setState({ showConfirmCancelAppointment: false })}
            confirmBtnCssClass="primary"
            cancelBtnCssClass="danger"
            title={t('appointment.created.cancel.title')}
            cancelBtnText={t('appointment.created.cancel.cancel')}
            confirmBtnText={t('appointment.created.cancel.confirm')}
            showCancel={true}
            message={<p>{t('appointment.created.cancel.message')} {selectedCancelAppointment}</p>}
          />
        }
        {showConfirmationCancelAppointment &&
          <CustomSweetAlert
            type="success"
            onConfirm={this.perfomSuccessReload}
            confirmBtnCssClass="primary"
            title={t('appointment.created.cancel.confirmation.title')}
            confirmBtnText={t('appointment.created.cancel.confirmation.OK')}
            showCancel={false}
            message={<p>{selectedCancelAppointment} {t('appointment.created.cancel.confirmation.message')}</p>}
          />
        }
        {showConfirmationEditAppointment &&
          <CustomSweetAlert
            type="success"
            onConfirm={this.perfomSuccessReload}
            confirmBtnCssClass="primary"
            title={t('appointment.created.edit.confirmation.title')}
            confirmBtnText={t('appointment.created.edit.confirmation.OK')}
            showCancel={false}
            message={<p>{t('appointment.edit.confirmation.message')}</p>}
          />
        }
      </GridContainer>
    );
  }
}

AppointmentDetail.defaultProps = {
  selectedSchedules: [],
  onSaveSchedulesAppointment: () => {},
};

AppointmentDetail.propTypes = {
  selectedAppointment: PropTypes.object,
  providers: PropTypes.array,
  appointmentTools: PropTypes.array,
  selectedSchedules: PropTypes.array,
  onSelectAppointmentObject: PropTypes.func,
  params: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  onGetAppointmentsTools: PropTypes.func,
  onDeleteAppointmentTool: PropTypes.func,
  onGetSchedulesAppointment: PropTypes.func,
  onSaveSchedulesAppointment: PropTypes.func,
};

const styles = {
  cardHeader: {
    zIndex: "0 !important",
  },
};

export default withStyles(styles)(withTranslation()(AppointmentDetail));
