import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GridItem from '../Grid/GridItem';
import GridContainer from '../Grid/GridContainer';
import CustomInput from '../CustomInput/CustomInput';
import './ValidationInput.css';

class ValidationInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            requiredState: '',
            email: '',
            emailState: '',
            number: '',
            numberState: '',
            required: '',
            value: props.value || '',
        }
    }

    componentWillReceiveProps(next) {
        if (next.invalid) {
            this.setState({ [this.props.type || "requiredState"]: "error" });
        }
    }

    change(event, stateName, type, stateNameEqualTo, maxValue) {
        if (this.props.disabled) {
            return;
        }
        this.props.onChangeValue(event.target.value);
        switch (type) {
            case "email": {
                const stateTypeEmail = this.verifyEmail(event.target.value) ? "success" : "error";
                this.setState({ [this.props.type + "State"]: stateTypeEmail });
            }
            break;
            case "password":
            case "length": {
                const stateTypePassword = this.verifyLength(event.target.value, 1) ? "success" : "error";
                this.setState({ [stateName + "State"]: stateTypePassword });
            }
            break;
            case "max-length": {
                const stateTypeMaxLength = this.verifyLength(event.target.value, stateNameEqualTo + 1) ? "success" : "error";
                this.setState({ [stateName + "State"]: stateTypeMaxLength });
            }
            break;
            case "number": {
                const stateTypeNumber = this.verifyNumber(event.target.value) ? "success" : "error";
                this.setState({ [this.props.type + "State"]: stateTypeNumber });
            }
            break;
            case "min-value": {
                const stateTypeMinValue = this.verifyNumber(event.target.value) && event.target.value >= stateNameEqualTo ? "success" : "error";
                this.setState({ [stateName + "State"]: stateTypeMinValue });
            }
            break;
            case "max-value": {
                const stateTypeMaxValue = this.verifyNumber(event.target.value) && event.target.value <= stateNameEqualTo ? "success" : "error";
                this.setState({ [stateName + "State"]: stateTypeMaxValue });
            }
            break;
            case "range": {
                const stateTypeRange = this.verifyNumber(event.target.value) && event.target.value >= stateNameEqualTo && event.target.value <= maxValue ? "success" : "error";
                this.setState({ [stateName + "State"]: stateTypeRange });
            }
            break;
            case "equalTo":
                this.setState((prevState) => {
                    const isEqual = this.compare(event.target.value, prevState[stateNameEqualTo]);
                    const stateTypeEqualTo = isEqual ? "success" : "error";
                    return { [stateName + "State"]: stateTypeEqualTo }
                });
            break;
            case "checkbox": {
                const stateTypeCheckbox = event.target.checked ? "success" : "error";
                this.setState({ [stateName + "State"]: stateTypeCheckbox, [stateName]: event.target.checked });
            }
            break;
            case "url": {
                const stateTypeUrl = this.verifyUrl(event.target.value) ? "success" : "error";
                this.setState({ [stateName + "State"]: stateTypeUrl });
            }
            break;
            default:
                this.setState({ [stateName]: event.target.value });
            break;
        }
    }

    verifyEmail(value) {
        const emailRex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailRex.test(value);
    }

    verifyLength(value, length) {
        return value.length > length;
    }

    verifyNumber(value) {
        const numberRex = /^\d+$/;
        return numberRex.test(value);
    }

    render () {
        const { errorText, disabled, id, multiline, placeHolder, value, text, type } = this.props;
        return (
            <GridContainer>
                <GridItem xs={12} sm={7}>
                    <CustomInput
                        id={id}
                        labelText={text}
                        error={this.state[type || "requiredState"] === "error"}
                        helpText={errorText}
                        placeholder={placeHolder}
                        value={value}
                        disabled={disabled}
                        multiline={multiline}
                        formControlProps={{
                            fullWidth: true,
                            disabled,
                        }}
                        inputProps={{
                            ...this.props.inputProps,
                            onChange: (event) => this.change(event, type || "required", type || "length"),
                            type: type || "text",
                        }}
                    />
                </GridItem>
            </GridContainer>
        )
    }
}

ValidationInput.propTypes = {
    text: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.any,
    onChangeValue: PropTypes.func,
    disabled: PropTypes.any,
    errorText: PropTypes.string,
    inputProps: PropTypes.object,
    multiline: PropTypes.bool,
    placeHolder: PropTypes.string,
    id: PropTypes.string,
}

export default ValidationInput;
