/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import PersonApiInvoker from '../api/PersonApiInvoker';
import ApiInvoker from '../api/ApiInvoker';

export const useVehicleData = (t) => {

  const [documentTypes, setDocumentTypes] = useState([]);
  const [genderTypes, setGenderTypes] = useState([]);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [types, setTypes] = useState([]);
  const [wheelChairSuitable, setWheelChairSuitable] = useState([])

  

  

  const formatGenderTypes = (genderTypes) => {
    const genderTypesValue = {
      UNDEFINED: t('label.undefined'),
      MALE: t('label.gender.male'),
      FEMALE: t('label.gender.female'),
    }
    return genderTypes.map(genderType => ({
      id: genderType,
      value: genderTypesValue[genderType],
    }));
  };

  const formatWheelChairSuitable = () => {
    return [
      { id: true, value: true, name: t('label.WheelChairSuitable.TRUE') },
      { id: false, value: false, name: t('label.WheelChairSuitable.FALSE') }
    ];
  };

  const getGenderTypes = () => {
    PersonApiInvoker.getGenderTypes(data => {
      const d = formatGenderTypes(data);
      setGenderTypes(d);
    }, null);
  };

  const getWheelChairSuitable = ()=>{
    const d = formatWheelChairSuitable()
    console.log(d)
    setWheelChairSuitable(d)
  }


  const formatBrands = (data) => data
  .filter(brand => brand.name !== undefined && brand.name !== null)
  .map(brand => ({
    id: brand.vehicleBrandId,
    name: brand.name,
    vehicleBrandId:brand.vehicleBrandId,
    
  }));

  const getBrands = () => {
    ApiInvoker.getVehiclesBrand(data => {
      const brand = formatBrands(data)
      console.log(brand)
      setBrands(brand);
    }, null);
  }
  const formatModels = (data) => data.filter(model => model.name !== undefined && model.name !== null)
  .map(model => ({
    id: model.vehicleModelId,
    name: model.name,
    vehicleModelId:model.vehicleModelId,
    vehicleBrandId:model.vehicleBrand.vehicleBrandId
  }))

  const getModels = () => {
    ApiInvoker.getVehiclesModel(data => {
      const brand = formatModels(data)
      setModels(brand);
    }, null);
  }

  const getTypes = ()=>{
    setTypes([
        {id: 'VAN', value:'VAN', name: 'VAN'},
        {id: 'MINIVAN', value:'MINI VAN', name: 'MINI VAN'},
        {id: 'SUV', value:'SUV', name: 'SUV'},
        {id: 'AMBULANCE', value:'AMBULANCE', name: 'AMBULANCIA'}
    ])
  }

  useEffect(() => {
    getGenderTypes();
    getBrands();
    getModels();
    getTypes()
    getWheelChairSuitable()
   
    return () => {
      setDocumentTypes([]);
      setGenderTypes([]);
      setModels([]);
      setTypes([]);
      setWheelChairSuitable([]);
    }
  }, []);

  return {
    documentTypes,
    genderTypes,
    brands,
    models,
    types,
    wheelChairSuitable
  }
}