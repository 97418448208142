import React, { useEffect, useState } from 'react';

import { AlertWarning } from './useFees';
import CustomSweetAlert from '../../../CustomSweetAlert';
import PropTypes from 'prop-types';
import { initialValueAlert } from './data';
import { useTranslation } from 'react-i18next';

type ComponentProps = {
  alertWarning: AlertWarning;
  showCancel: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  disabledOnConfirmButton?: boolean;
}

const FeesWarningDialog = ({ alertWarning, showCancel, onConfirm, onCancel, disabledOnConfirmButton }: ComponentProps) => {
  const { t } = useTranslation();
  const [alert, setAlert] = useState<AlertWarning>(initialValueAlert);

  useEffect(() => {
    setAlert(alertWarning);
    return () => {
      setAlert(initialValueAlert);
    }
  }, [alertWarning])

  const isDisabledButton = disabledOnConfirmButton ? 'disabled' : '';

  return (
    alert.show &&
      <CustomSweetAlert
        type="warning"
        confirmBtnCssClass={`primary ${isDisabledButton}`}
        confirmBtnText={t('appointment.new.accept')}
        onConfirm={() => onConfirm()}
        showCancel={showCancel}
        cancelBtnText={t('appointment.new.cancel')}
        onCancel={() => onCancel()}
        message={<p>{alert.message}</p>}
      />
  )
}

FeesWarningDialog.defaultProps = {
  showCancel: true,
  disabledOnConfirmButton: false,
}

FeesWarningDialog.propTypes = {
  alertWarning: PropTypes.object.isRequired,
  showCancel: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  disabledOnConfirmButton: PropTypes.bool,
}

export default FeesWarningDialog;
