export class Term {
  id: number;
  companyId: number;
  content: string;
  version: string;

  constructor({ id, companyId, version, content }: TermDto) {
    this.id = id;
    this.companyId = companyId;
    this.content = content;
    this.version = version;
  }
}

export interface TermDto {
  id: number;
  companyId: number;
  content: string;
  version: string;
}
