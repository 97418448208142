import { ApiCustomerFinanciersFromMap } from "./api-customer-financiers-items.from-map";
import { CustomerFinancier } from "../domain";

export class ApiCustomerFinanciersFromMappper {
  fromMap(map: ApiCustomerFinanciersFromMap): CustomerFinancier {
    const financier = new CustomerFinancier({
      id: map.financierId,
      name: map.financier.businessName,
      affiliateId: map.affiliateId,
      isActive: map.financier.active,
      dueDate: map.validUntil,
      customerId: map.customerId,
    });
    return financier;
  }
}
