import { ApiErrorParser, CoreException, Either, VoidSuccess } from "../../../sdk";

import { ApiGetTermsAnConditionsFromMap } from "./api-get-terms-and-conditions.from-map";
import { ApiGetTermsAnConditionsFromMappper } from "./api-get-terms-and-conditions.from-mapper";
import PersonApiInvoker from "../../../api/PersonApiInvoker";
import { Term } from "../domain/term";
import { UsersRepository } from "../application/repositories";
import { injectable } from "inversify";

const mapperTerm = new ApiGetTermsAnConditionsFromMappper();
const errorParser = new ApiErrorParser();

@injectable()
export class ApiUsersRepository implements UsersRepository {

  async getTermsAndConditions(version: string): Promise<Either<CoreException, Term>> {
    return new Promise((resolve, _) => {
      let result: Either<CoreException, Term>;
      PersonApiInvoker.getTermsAndConditions(version, (res: ApiGetTermsAnConditionsFromMap) => {
        const data = mapperTerm.fromMap(res);
        result = Either.right(data);
        resolve(result);
      }, (error: CoreException) => {
        const err = errorParser.parse(error);
        result = Either.left(err);
        resolve(result);
      });
    });
  }

  async updateUserTerms(employeeId: number, version: string, isAccept: boolean): Promise<Either<CoreException, VoidSuccess>> {
    return new Promise((resolve, _) => {
      let result: Either<CoreException, Term>;
      PersonApiInvoker.updateUserTerms({ employeeId, version, isAccept }, (res: any) => {
        result = Either.right(res);
        resolve(result);
      }, (error: CoreException) => {
        const err = errorParser.parse(error);
        result = Either.left(err);
        resolve(result);
      });
    });
  }

}
