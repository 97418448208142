import { connect } from 'react-redux';
import { fetchBookings } from '../actions/bookings';
import { fetchPatologyDetails, fetchHospitalPatologyDetails, setComplexity, setBarthel } from '../actions/records';
import { saveSchedule, saveDates, resetSchedule, setAppointment } from '../actions/newSchedule';
import NewTransfer from '../components/NewTransfer';

const mapStateToProps = (state) => {
    return {
        appointment: state.appointment,
        bookings: state.bookings.bookings,
        schedule: state.newSchedule.schedule,
        patology: state.records.patology,
        hospitalPatology: state.records.hospitalPatology,
        complexity: state.records.complexity,
        barthel: state.records.barthel,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onAppointment: (appointment) => {
            dispatch(setAppointment(appointment));
        },
        onResetSchedule: () => {
            dispatch(resetSchedule());
        },
        onFilledComplexity: (complexity) => {
            dispatch(setComplexity(complexity));
        },
        onFilledPatology: (patology) => {
            dispatch(fetchPatologyDetails(patology));
        },
        onFilledHospitalPatology: (patology) => {
            dispatch(fetchHospitalPatologyDetails(patology));
        },
        onFilledBarthel: (barthel) => {
            dispatch(setBarthel(barthel));
        },
        onGetBookings: (bookings) => {
            dispatch(fetchBookings(bookings));
        },
        onSaveSchedule: (schedule) => {
            dispatch(saveSchedule(schedule));
        },
        onSaveDates: (dates) => {
            dispatch(saveDates(dates));
        }
    }
}

const NewTransferService = connect(
    mapStateToProps,
    mapDispatchToProps
)(NewTransfer);

export default NewTransferService;
