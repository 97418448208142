import {
  AuthorizationException,
  BadRequestException,
  CoreException,
  NotFoundException,
  OperationalException,
  PermissionException,
} from '../../domain/errors/core-exception';

export class ApiErrorParser {
  parse(error: any): CoreException {
    const t = this.parseData(error);
    switch (error.status) {
      case 401:
        return new AuthorizationException();

      case -3:
        return new OperationalException();

      case 404:
        return new NotFoundException();

      case 403:
        return new PermissionException();

      case 400:
      case 409: {
        const message = this.getBadRequestMessageError(t);
        return new BadRequestException(message);
      }

      default:
        return new OperationalException();
    }
  }

  private parseData(data: any) {
    try {
      return JSON.parse(data);
    } catch (error) {
      return data;
    }
  }

  private getBadRequestMessageError(error: any): string {
    if (typeof error === 'object') {
      const { message, error_description, detail } = error;
      return message || error_description || detail || '';
    }
    return `${error}`;
  }
}
