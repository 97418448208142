import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { Grid, Typography } from '@material-ui/core';
import Table from '../../Table/Table';
import { useStylesGralSteps } from '../../ReassignAppointmentsStepper/useStylesReassignAppStepper';
import config from '../../../config/config';

const dateFormatConf = config.getDateFormat();

const RequestChangesStep = ({
  isConfirmStep,
  values: {
    professionalsToReassign,
    schedulesSelectedData,
  },
  t,
}) => {
  const classes = useStylesGralSteps();

  const dataScheduleSelected = schedulesSelectedData.map(s => {
    const scheduleDateTimeUpdate = s.scheduleDateTimeUpdate
      && moment(s.scheduleDateTimeUpdate).format(dateFormatConf) !== moment(s.scheduleDateTime).format(dateFormatConf) 
        ? moment(s.scheduleDateTimeUpdate).format(dateFormatConf) : '-';
    const scheduleTimeUpdate = s.scheduleTimeUpdate && s.scheduleTimeUpdate !== s.scheduleTime ? s.scheduleTimeUpdate  : '-';
    return {
      ...s,
      scheduleDateTimeUpdate: <Typography style={{ fontWeight: 300 }} variant="body2" color="primary">{scheduleDateTimeUpdate}</Typography>,
      scheduleTimeUpdate: <Typography style={{ fontWeight: 300 }} variant="body2" color="primary">{scheduleTimeUpdate}</Typography>,
    }
  });
  const selectedProfessionalsData = professionalsToReassign.filter(f => f.checkedProfessional === true);

  return (
    <Grid container className={clsx(classes.formStepper, {[classes.readOnlyForm]: isConfirmStep})}>
      <Grid item xs={12}>
        <h4 className={classes.stepTitle}>{t('title.transfersToModify')}</h4>
        <Table
          tableHeaderColor="primary"
          sortable
          filterable
          tableHead={[
            { Header: t('reassignAppointments.table.transferId'), accessor: 'scheduleId' },
            { Header: t('reassignAppointments.table.appointmentId'), accessor: 'appointmentId' },
            { Header: t('reassignAppointments.table.practiceTypeName'), accessor: 'practiceTypeName' },
            { Header: t('reassignAppointments.table.customer'), accessor: 'customer' },
            { Header: t('label.originalDriver'), accessor: 'professional' },
            { Header: t('label.originalDate'), accessor: 'scheduleDate',
              sortMethod: (a, b) => {
                const x1 = moment(a, dateFormatConf).valueOf();
                const b1 = moment(b, dateFormatConf).valueOf();
                if (x1 > b1) {
                  return 1;
                }
                if (x1 < b1) {
                  return -1;
                }
                return 0;
              }
            },
            { Header: t('label.originalTime'), accessor: 'scheduleTime' },
            { Header: t('label.requestDate'), accessor: 'scheduleDateTimeUpdate' },
            { Header: t('label.requestTime'), accessor: 'scheduleTimeUpdate' },
          ]}
          tableData={dataScheduleSelected}
          defaultPageSize={dataScheduleSelected && dataScheduleSelected.length > 5 ? dataScheduleSelected.length : 5}
          showPaginationTop={false}
          showPaginationBottom={false}
        />
      </Grid>

      <Grid item xs={12}>
        <h4 className={clsx(classes.stepTitle, classes.spacingTop7)}>{t('title.suggestedDrivers')}</h4>
        <Table
          tableHeaderColor="primary"
          sortable={true}
          filterable
          tableHead={[
            { Header: t('reassignAppointments.table.profesional.lastName'), accessor: 'lastName' },
            { Header: t('reassignAppointments.table.profesional.firstName'), accessor: 'firstName' },
            { Header: t('reassignAppointments.table.profesional.employeeTypes'), accessor: 'employeeTypes' },
            { Header: t('reassignAppointments.table.profesional.geographicZone'), accessor: 'geographicZone' },
          ]}
          tableData={selectedProfessionalsData}
          showPaginationTop={false}
          showPaginationBottom={false}
          defaultPageSize={selectedProfessionalsData && selectedProfessionalsData.length > 5 ? selectedProfessionalsData.length : 5}
        />
      </Grid>
    </Grid>
  )
}

RequestChangesStep.propTypes = {
  t: PropTypes.func,
  isConfirmStep: PropTypes.bool,
  values: PropTypes.object,
}

export default withTranslation()(RequestChangesStep);
