import { ApiGetTermsAnConditionsFromMap } from "./api-get-terms-and-conditions.from-map";
import { Term } from "../domain";

export class ApiGetTermsAnConditionsFromMappper {
  fromMap(map: ApiGetTermsAnConditionsFromMap): Term {
    const term = new Term({
      id: map.termsAndConditionId,
      companyId: map.companyId.companyId,
      content: map.content,
      version: map.version,
    });
    return term;
  }
}
