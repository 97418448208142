import './Sidebar.css';

import Authority from '../../util/Authority';
import Button from '../CustomButtons/Button';
import CloseIcon from '@material-ui/icons/Close';
// @material-ui/core components
import Collapse from '@material-ui/core/Collapse';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import { Link } from 'react-router';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import React from 'react';
import SidebarWrapper from './SidebarWrapper';
import UserService from '../../containers/UserService';
import { connect } from 'react-redux';
import cx from 'classnames';
import hmmLogo from '../../assets/img/logo-hmm-white.png';
import sidebarStyle from '../../assets/components/sidebarStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import { withTranslation } from 'react-i18next';

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,
      miniActive: true,
      ...this.getCollapseStates(props.routes)
    };
  }
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    let initialState = {};
    routes.map((prop) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState
        };
      }
      return null;
    });
    return initialState;
  };

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    return routes.some(route => (
        (route.collapse && this.getCollapseInitialState(route.views)) ||
        window.location.href.indexOf(route.path) !== -1
    ));
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    if (this.props.location) {
      return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
    }
  };

  getIconMenu(prop, itemIcon, collapseItemMini, rtlActive) {
    if (prop.icon) {
      return prop.icon !== "string" ? React.createElement(prop.icon, { className: itemIcon }) : <Icon className={itemIcon}>{prop.icon}</Icon>;
    }
    return (
      <span className={collapseItemMini} style={{ paddingLeft: `${prop.inset}px` }}>
        {rtlActive ? this.props.t(prop.rtlMini) : this.props.t(prop.mini)}
      </span>
    )
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  getClasses(prop) {
    const { classes, color, rtlActive } = this.props;
    let navLinkClasses, itemText, collapseItemText, itemIcon, collapseItemMini, caret, innerNavLinkClasses;
    itemText = classes.itemText + " " + cx({
      [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
      [classes.itemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
      [classes.itemTextRTL]: rtlActive
    });
    collapseItemText = classes.collapseItemText + " " + cx({
        [classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive,
        [classes.collapseItemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.collapseItemTextRTL]: rtlActive
      });
    itemIcon = classes.itemIcon + " " + cx({ [classes.itemIconRTL]: rtlActive });
    if (prop.collapse) {
      const st = {};
      st[prop["state"]] = !this.state[prop.state];
      navLinkClasses = classes.itemLink + " " + cx({
          [" " + classes.collapseActive]: this.getCollapseInitialState(prop.views)
        });
      caret = classes.caret + " " + cx({ [classes.caretRTL]: rtlActive });
      collapseItemMini = classes.collapseItemMini + " " +cx({ [classes.collapseItemMiniRTL]: rtlActive });
      return { navLinkClasses, itemText, collapseItemText, itemIcon, collapseItemMini, caret, innerNavLinkClasses, st }
    }
    innerNavLinkClasses = classes.collapseItemLink + " " + cx({ [" " + classes[color]]: this.activeRoute(prop.path) });
    collapseItemMini = classes.collapseItemMini + " " + cx({ [classes.collapseItemMiniRTL]: rtlActive });
    navLinkClasses = classes.itemLink + " " + cx({ [" " + classes[color]]: this.activeRoute(prop.path) });
    return { innerNavLinkClasses, collapseItemMini, navLinkClasses, itemText, collapseItemText, itemIcon, st: null };
  }
  createLinks = (routes) => {
    routes = routes.filter(item => Authority.hasPermission(item.authority));
    const { classes, color, rtlActive } = this.props;
    return routes.map((prop) => {
      if (prop.hidden) {
        return null;
      }
      if (prop.redirect) {
        return null;
      }
      const { innerNavLinkClasses, navLinkClasses, itemText, collapseItemText, itemIcon, collapseItemMini, caret, st } = this.getClasses(prop);
      if (prop.collapse) {
        return (
          <ListItem
            key={prop.id}
            className={cx(
              { [classes.item]: prop.icon !== undefined },
              { [classes.collapseItem]: prop.icon === undefined }
            )}
          >
            <Link
              to={"#"}
              className={navLinkClasses}
              activeClassName={`${classes[color]} active`}
              onClick={e => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {this.getIconMenu(prop, itemIcon, collapseItemMini, rtlActive)}

              <ListItemText
                primary={rtlActive ? this.props.t(prop.rtlName) : this.props.t(prop.name)}
                style={{ paddingLeft: (prop.mini !== '' ? 0 : `${prop.inset}px`) }}
                secondary={
                  <b className={ caret + " " + (this.state[prop.state] ? classes.caretActive : "") } />
                }
                disableTypography={true}
                className={cx(
                  { [itemText]: prop.icon !== undefined },
                  { [collapseItemText]: prop.icon === undefined }
                )}
              />
            </Link>
            <Collapse in={this.state[prop.state]} unmountOnExit>
              <List className={classes.list + " " + classes.collapseList}>
                {this.createLinks(prop.views)}
              </List>
            </Collapse>
          </ListItem>
        );
      }

      return (
        <ListItem
          key={prop.id}
          className={cx(
            { [classes.item]: prop.icon !== undefined },
            { [classes.collapseItem]: prop.icon === undefined },
          )}
        >
          <Link
            to={prop.enabled ? prop.layout + prop.path : ''}
            className={cx(
              { [navLinkClasses]: prop.icon !== undefined },
              { [innerNavLinkClasses]: prop.icon === undefined },
              { [classes.disabled]: !prop.enabled }
            )}
            activeClassName={`${classes[color]} active`}
          >
            {this.getIconMenu(prop, itemIcon, collapseItemMini, rtlActive)}
            <ListItemText
              primary={rtlActive ? this.props.t(prop.rtlName) : this.props.t(prop.name)}
              disableTypography={true}
              style={{paddingLeft: (prop.mini !== '' ? 0 : `${prop.inset}px`)}}
              className={cx(
                { [itemText]: prop.icon !== undefined },
                { [collapseItemText]: prop.icon === undefined }
              )}
            />
          </Link>
        </ListItem>
      );
    });
  };

  render() {
    const {
      classes,
      logo,
      image,
      routes,
      bgColor,
      rtlActive
    } = this.props;
    const links = (
      <List className={classes.list}>{this.createLinks(routes)}</List>
    );

    const logoClasses =
      classes.logo +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white"
      });
    const brand = (
      <div className={`${logoClasses}logo-sidebar ${!logo ? 'companyName': ''}`}>
        <img src={hmmLogo} alt={this.props.logoText} className={classes.img} />
      </div>
    );

    const isWindows = /Win/i.test(navigator.userAgent);
    
    const drawerPaper =
      classes.drawerPaper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.drawerPaperRTL]: rtlActive
      });
    const sidebarWrapper =
      classes.sidebarWrapper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]: isWindows
      });
    return (
      <div ref="mainPanel">
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={rtlActive ? "left" : "right"}
            open={this.props.open}
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"]
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            <Button
                id="button-drag"
                className={`${classes.appResponsive} closeModalTopFixButton`}
                color="transparent"
                justIcon
                aria-label="open drawer"
                onClick={() => this.props.handleDrawerToggle()}
              >
              <CloseIcon />
            </Button>
            {brand}
            <SidebarWrapper
              className={`${this.props.miniActive && this.state.miniActive} ps`} 
              miniActive={this.state.miniActive}
              headerLinks={<UserService.NavbarService rtlActive={rtlActive} />}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={`${classes.background} backgroundTablet`}
                style={{ backgroundImage: `url('${image}')`}}
              />
            ) : null}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            onMouseOver={() => this.setState({ miniActive: false })}
            onMouseOut={() => this.setState({ miniActive: true })}
            anchor={rtlActive ? "right" : "left"}
            variant="permanent"
            open
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"]
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              miniActive={this.props.miniActive && this.state.miniActive}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: `url('${image}')` }}
              />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  bgColor: "black"
};

Sidebar.propTypes = {
  t: PropTypes.func,
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(["primary", "white", "black", "blue"]),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "rose",
    "transparent",
  ]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  location: PropTypes.object,
  miniActive: PropTypes.bool,
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    selectedCompany: state.user.selectedCompany,
  };
}

export default connect(mapStateToProps, null)(withStyles(sidebarStyle)(withTranslation()(Sidebar)));
