import { Grid, Tooltip } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useStylesDateTime, useStylesGralSteps } from '../../../ReassignAppointmentsStepper/useStylesReassignAppStepper';
import Button from '../../../CustomButtons/Button';
import DateInput from '../../../DateInput';
import { InfoOutlined } from '@material-ui/icons';
import PropTypes from 'prop-types';
import Table from '../../../Table/Table';
import clsx from 'clsx';
import config from '../../../../config/config';
import moment from 'moment';
import useDateStep from './useDateStep';
import { withTranslation } from 'react-i18next';

const dateToServer = config.getDateTimeFormatServer();

const DateStep = ({
  handleChange,
  handleIsValidStep,
  setRefSchedules,
  values,
  t,
}) => {
  const classes = useStylesGralSteps();
  const classesDate = useStylesDateTime();

  const {
    dateTimeSchedules,
    dateSchedulesHasChanged,
  } = values;

  const {
    handleValueDates,
    handleClearDates,
    isValid,
    schedulesData,
    today,
  } = useDateStep(handleChange, values);

  useEffect(() => {
    handleIsValidStep(isValid);
  }, [handleIsValidStep, isValid]);

  return (
    <Grid
      container
      className={classes.formStepper}
      alignItems="flex-start"
    >
      <Grid item xs={12}>
        <h4 className={clsx(classes.stepTitle, classes.titleWidthIconEnd)}>
          <span>{t('title.desiredStartDate')}</span>
          <Tooltip placement="right" title={t('tooltipInfo.desiredStartDate')}>
            <InfoOutlined />
          </Tooltip>
        </h4>
      </Grid>
      <Grid item xs={12} sm={6} md={4} className={classesDate.contentDateTimeInput}>
        <DateInput
          className="date-appointments"
          text={t('label.startDate')}
          minDate
          min={today}
          value={dateTimeSchedules}
          onChangeValue={(value) => handleValueDates(value)}
        />
      </Grid>
       
      <Grid item xs={12}>
        <h4 className={clsx(classes.stepTitle, classes.spacingTop7)}>{t('title.transfersToModify')}</h4>
        <Table
          tableHeaderColor="primary"
          sortable
          setRef={setRefSchedules}
          filterable
          tableHead={[
            { Header: t('reassignAppointments.table.scheduleId'), accessor: 'scheduleId' },
            { Header: t('reassignAppointments.table.appointmentId'), accessor: 'appointmentId' },
            { Header: t('reassignAppointments.table.practiceTypeName'), accessor: 'practiceTypeName' },
            { Header: t('reassignAppointments.table.customer'), accessor: 'customer' },
            { Header: t('label.driver'), accessor: 'professional' },
            { Header: t('common.date'), accessor: 'scheduleDateTimeInputUpdate', className: classesDate.columnDateTime,
              sortMethod: (a, b) => {
                let a1 = moment(a.props.value, dateToServer).valueOf();
                let b1 = moment(b.props.value, dateToServer).valueOf();
                if(a1 > b1) {
                  return 1;
                } else if (a1 < b1) {
                  return -1;
                } else {
                  return 0;
                }
              }
            },
            { Header: t('label.hour'), accessor: 'scheduleTime' },
          ]}
          tableData={schedulesData}
          defaultPageSize={schedulesData && schedulesData.length > 5 ? schedulesData.length : 5}
          showPaginationTop={false}
          showPaginationBottom={false}
        />
      </Grid>
      <Grid item xs={12} className={classes.actionButton}>
        <Button
          id="button-undo"
          onClick={() => handleClearDates()}
          className={classes.button}
          disabled={!dateSchedulesHasChanged}
        >
          {t('label.undo')}
        </Button>
      </Grid>
    </Grid>
  )
}

DateStep.propTypes = {
  t: PropTypes.func,
  handleChange: PropTypes.func,
  handleIsValidStep: PropTypes.func,
  setRefSchedules: PropTypes.any,
  values: PropTypes.object,
}

export default withTranslation()(DateStep);
