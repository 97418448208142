import { Grid, Tooltip } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useStylesDateTime, useStylesGralSteps } from '../../../ReassignAppointmentsStepper/useStylesReassignAppStepper';
import Button from '../../../CustomButtons/Button';
import { InfoOutlined } from '@material-ui/icons';
import PropTypes from 'prop-types';
import Table from '../../../Table/Table';
import TimePickerGCalendar from '../../../TimePickerGCalendar';
import clsx from 'clsx';
import config from '../../../../config/config';
import moment from 'moment';
import useTimeStep from './useTimeStep';
import { withTranslation } from 'react-i18next';

const format = config.getDateFormat();
const TimeStep = ({
  handleChange,
  handleIsValidStep,
  setRefSchedules,
  values,
  t,
}) => {
  const classes = useStylesGralSteps();
  const classesTime = useStylesDateTime();

  const {
    timeSchedules,
    timeSchedulesHasChanged,
  } = values;

  const {
    handleClearTimes,
    handleValueTimes,
    isValid,
    schedulesData,
  } = useTimeStep(handleChange, values);

  useEffect(() => {
    handleIsValidStep(isValid);
  }, [handleIsValidStep, isValid]);

  return (
    <Grid
      container
      className={classes.formStepper}
      alignItems="flex-start"
    >
      <Grid item xs={12}>
        <h4 className={clsx(classes.stepTitle, classes.titleWidthIconEnd)}>
          <span>{t('title.desiredStartHour')}</span>
          <Tooltip placement="right" title={t('tooltipInfo.desiredStartHour')}>
            <InfoOutlined />
          </Tooltip>
        </h4>
      </Grid>
      <Grid item xs={12} sm={6} md={4} className={classesTime.contentDateTimeInput}>
        <TimePickerGCalendar
          text={`${t('common.startTime')} *`}
          value={timeSchedules}
          onSelectedValue={(value) => handleValueTimes(value)}
          name="startTimeAll"
        />
      </Grid>
       
      <Grid item xs={12}>
      <h4 className={clsx(classes.stepTitle, classes.spacingTop7)}>{t('title.transfersToModify')}</h4>
        <Table
          tableHeaderColor="primary"
          sortable
          setRef={setRefSchedules}
          filterable
          tableHead={[
            { Header: t('reassignAppointments.table.transferId'), accessor: 'scheduleId' },
            { Header: t('reassignAppointments.table.appointmentId'), accessor: 'appointmentId' },
            { Header: t('reassignAppointments.table.practiceTypeName'), accessor: 'practiceTypeName' },
            { Header: t('reassignAppointments.table.customer'), accessor: 'customer' },
            { Header: t('label.driver'), accessor: 'professional' },
            { Header: t('common.date'), accessor: 'scheduleDate', 
            
            sortMethod: (a, b) => {
              let a1 = moment(a, format).valueOf();
              let b1 = moment(b, format).valueOf();
              if(a1 > b1) {
                return 1;
              } else if (a1 < b1) {
                return -1;
              } else {
                return 0;
              }

            }
          
          
          },
            { Header: t('label.hour'), accessor: 'scheduleTimeInputUpdate', className: classesTime.columnDateTime },
          ]}
          tableData={schedulesData}
          defaultPageSize={schedulesData && schedulesData.length > 5 ? schedulesData.length : 5}
          showPaginationTop={false}
          showPaginationBottom={false}
        />
      </Grid>
      <Grid item xs={12} className={classes.actionButton}>
        <Button
          id="button-undo"
          onClick={() => handleClearTimes()}
          className={classes.button}
          disabled={!timeSchedulesHasChanged}
        >
          {t('label.undo')}
        </Button>
      </Grid>
    </Grid>
  )
}

TimeStep.propTypes = {
  t: PropTypes.func,
  handleChange: PropTypes.func,
  handleIsValidStep: PropTypes.func,
  setRefSchedules: PropTypes.any,
  values: PropTypes.object,
}

export default withTranslation()(TimeStep);
