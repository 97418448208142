import { ApiGetByEmployeeAndPracticeTypeFromMap } from "./api-get-by-employee-and-practice-type.from-map";
import { PracticeFee } from "../domain";

export class ApiGetByEmployeeAndPracticeTypeFromMappper {
  fromMap(map: ApiGetByEmployeeAndPracticeTypeFromMap): PracticeFee {
    const practicefee = new PracticeFee({
      id: map.practiceFeesId,
      companyId: map.companyId,
      practiceTypeId: map.practiceTypeId,
      employeeId: map.employeeId,
      companyCurrencyId: map.companyCurrencyId,
      amount: map.totalAmount,
      fromDate: map.fromDate,
      toDate: map.toDate,
      scheduleAmountsChanged: map.scheduleAmountsChanged,
      isSchedulesFeeUpdated: map.isSchedulesFeeUpdated,
    });
    return practicefee;
  }
}
