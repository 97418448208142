import './NewEmployee.css';

import React, { Component } from 'react';

import Add from '@material-ui/icons/Add';
import Button from '../CustomButtons/Button';
import ButtonSpinner from '../ButtonSpinner';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardFooter from '../Card/CardFooter';
import CardHeader from '../Card/CardHeader';
import CardIcon from '../Card/CardIcon';
import DateInput from '../DateInput';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import PersonApiInvoker from '../../api/PersonApiInvoker';
import PropTypes from 'prop-types';
import SelectInput from '../SelectInput';
import Snackbar from '../Snackbar/Snackbar';
import ValidationInput from '../ValidationInput';
import _ from 'lodash';
import { browserHistory } from 'react-router';
import config from '../../config/config';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

const dateToServer = config.getDateToServer();

class NewEmployee extends Component {
    constructor(props) {
        super(props);

        this.state = {
            documentTypes: [],
            documents: [],
            genderTypes: [],
            nationalities: [],
            name: '',
            lastName: '',
            document: '',
            gender: '',
            nationality: '',
            documentType: '',
            licenseNumber: '',
            email: '',
            birthDate: '',
            alertColor: 'info',
            alertMessage: '',
            alertOpen: false,
            save: false,
            dataChanged: false,
            loading: false,
        }
    }

    componentWillMount() {
        this.getNationalities();
        this.getGenderTypes();
        this.getDocumentTypes();
    }

    componentWillReceiveProps(next) {
        if (next.documentTypes) {
            const documentTypes = this.formatDocumentTypes(next.documentTypes)
            this.setState({
                documentTypes,
                documents: next.documentTypes,
            });
        }
    }

    formatDocumentTypes(documentTypes) {
        let result = documentTypes.map(d => {
            return {
                id: d.idTypeId,
                value: d.name + ' - ' + d.type
            }
        });
        return result;
    }

    getNationalities () {
        PersonApiInvoker.getNationalities(jsonResponse => {
            this.setState({
                nationalities: jsonResponse.map(nationalityItem => {
                    return {
                        id: nationalityItem.nationalityId,
                        name: nationalityItem.name
                    }
                })
            });
        });
    }

    getGenderTypes () {
        PersonApiInvoker.getGenderTypes(data => {
            this.setGenderTypesState(data);
        }, null);
    }

    getDocumentTypes() {
        PersonApiInvoker.getIdTypes(data => {
            this.props.onGetDocumentTypes(data);
        }, null);
    }

    findDocumentType(id) {
        const type = this.state.documents.find(d => d.idTypeId === id);
        return type || {};
    }

    openAlert(color, message, data) {
        this.setState({
            alertColor: color,
            alertMessage: message,
            alertOpen: true,
        });

        setTimeout(() => {
            this.setState({ alertOpen: false });
            if (color === 'success') {
                browserHistory.push(`/editar-profesional/${data.personId}`)
                this.cleanFields();
            }
        }, 5000);
    }

    findNationality () {
        let nationalityObj = _.find(this.state.nationalities, nationalityItem => {
            return nationalityItem.id === this.state.nationality;
        });

        return {
            name: nationalityObj.name,
            nationalityId: nationalityObj.id
        };
    }

    setGenderTypesState (genderTypes) {
        const { t } = this.props;
        const genderTypesMap = {
            UNDEFINED: t('customers.new.gender.undefined'),
            MALE: t('customers.new.gender.male'),
            FEMALE: t('customers.new.gender.female'),
        }
        const result = genderTypes.map(genderType => {
            return {
                id: genderType,
                value: genderTypesMap[genderType],
            };
        });
        this.setState({ genderTypes: result });
    }

    CancelSave(){
        browserHistory.push(`/profesionales`);
    }

    validEmail(value) {
        if(value.length === 0) return true;

        const emailRex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailRex.test(value);
    }

    postEmployee() {
        const { t } = this.props;
        const employee = {
            id: this.state.document,
            avatarUrl: '',
            birthdate: this.state.birthDate,
            nationality: this.findNationality(),
            sex: this.state.gender,
            email: this.state.email,
            firstName: this.state.name,
            lastName: this.state.lastName,
            idType: this.findDocumentType(this.state.documentType),
            metadata: { licenseNumber: this.state.licenseNumber },
            qr: '',                
        }
        PersonApiInvoker.postEmployee(employee, (data) => {
            this.props.onAddEmployee(data);
            this.setState({ dataChanged: false, loading: false });
            this.openAlert('success', t('employee.new.success'), data);
        }, (error) => {
            this.setState({ loading: false });
            const messageError = error?.message ? error.message : error;
            this.openAlert('danger', messageError+'');
        });
    }

    handleSaveClick() {
        this.setState({ save: true, loading: true });
        const { t } = this.props;

        const requiredFields = this.state.name && this.state.lastName
        && this.state.document && this.state.documentType !== '0'
        && this.state.email && this.state.birthDate
        && this.state.nationality && this.state.gender
        && this.state.licenseNumber;

        if (requiredFields && this.validEmail(this.state.email)) {
            this.postEmployee();
            return;
        }
        const messageEmail = !this.validEmail(this.state.email) ? t('message.invalidEmail') : '';
        const messageRequired = !requiredFields ? t('appointment.new.requiredFields') : '';
        this.openAlert('warning', `${ messageRequired } ${ messageEmail }`);
        this.setState({ loading: false });
    }

    cleanFields() {
        this.setState({
            name: '',
            lastName: '',
            document: '',
            documentType: '0',
            email: '',
            birthDate: null,
            licenseNumber: '',
            save: false
        })
    }

    handleValue(value, state) {
        this.setState({ [state]: value, dataChanged: true });
    }

    render() {
        const { t } = this.props;
        const { dataChanged, loading } = this.state;
        const maxDate = moment().format(dateToServer);
        const minDate = moment().subtract(120, 'years').format(dateToServer);
        return (
            <div className="new-customer employee">
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader icon>
                                <CardIcon color="primary">
                                    <Add />
                                </CardIcon>
                                <h3 className="card-icon-title">{t('employee.new.title')}</h3>
                            </CardHeader>
                            <CardBody className="new-customer-form">
                                <form>
                                    <GridContainer>
                                        <GridItem xs={12} sm={6}>
                                            <ValidationInput text={t('employee.new.first_name')} onChangeValue={(value) => this.handleValue(value, 'name')} value={this.state.name} invalid={this.state.name === '' && this.state.save} />
                                        </GridItem>
                                        <GridItem xs={12} sm={6}>
                                            <ValidationInput text={t('employee.new.last_name')} onChangeValue={(value) => this.handleValue(value, 'lastName')} value={this.state.lastName} invalid={this.state.lastName === '' && this.state.save} />
                                        </GridItem>
                                        <GridItem xs={12} sm={6}>
                                            <SelectInput 
                                            label={t('employee.new.nationality')} 
                                            elements={this.state.nationalities} 
                                            value={this.state.nationality} 
                                            onSelectedValue={(value) => this.handleValue(value, 'nationality')} 
                                            className="select-input" invalid={(!this.state.nationality || this.state.nationality === '') && this.state.save} errorText={t('error.required')} />
                                        </GridItem>
                                        <GridItem xs={12} sm={6}>
                                            <SelectInput label={t('employee.new.gender')} elements={this.state.genderTypes} value={this.state.gender} onSelectedValue={(value) => this.handleValue(value, 'gender')} invalid={(!this.state.gender || this.state.gender === '') && this.state.save} errorText={t('error.required')} />
                                        </GridItem>
                                        <GridItem xs={12} sm={6}>
                                            <SelectInput label={t('employee.new.id_type')} elements={this.state.documentTypes} value={this.state.documentType} onSelectedValue={(value) => this.handleValue(value, 'documentType')} invalid={(!this.state.documentType || this.state.documentType === '') && this.state.save} errorText={t('error.required')} />
                                        </GridItem>
                                        <GridItem xs={12} sm={6}>
                                            <ValidationInput text={t('employee.new.id')} type="text" onChangeValue={(value) => this.handleValue(value, 'document')} value={this.state.document} invalid={this.state.document === '' && this.state.save} />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <ValidationInput text={t('employee.new.licenseNumber')} type="text" onChangeValue={(value) => this.handleValue(value, 'licenseNumber')} value={this.state.licenseNumber} invalid={this.state.licenseNumber === '' && this.state.save} />
                                        </GridItem>
                                        <GridItem xs={12} sm={6} className="calendar-up">
                                            <DateInput
                                                minDate
                                                min={minDate}
                                                max={maxDate}
                                                text={t('employee.new.birth_date')}
                                                onChangeValue={(value) => this.handleValue(value, 'birthDate')}
                                                value={this.state.birthDate}
                                                invalid={this.state.birthdate ? this.state.birthdate <= maxDate : !this.state.birthDate && this.state.save}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={6}>
                                            <ValidationInput
                                                text={t('employee.new.email')}
                                                type="email"
                                                onChangeValue={(value) => this.handleValue(value, 'email')}
                                                value={this.state.email}
                                                invalid={(this.state.email === '' && this.state.save) || !this.validEmail(this.state.email)}
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <p className="required-text">
                                                <small>*</small> {t('common.fields.required')}
                                            </p>
                                        </GridItem>
                                    </GridContainer>
                                </form>
                            </CardBody>
                            <CardFooter className="new-customer-submit padding-horizontal">
                                <Button id="button-cancel" color="default" onClick={this.CancelSave.bind(this)}>
                                    {t('employee.new.cancel')}
                                </Button>
                                <ButtonSpinner
                                    id="button-save"
                                    onClick={this.handleSaveClick.bind(this)}
                                    disabled={!dataChanged || loading}
                                    label={t('employee.new.save')}
                                    labelLoading={t('employee.new.saving')}
                                    loading={loading}
                                    typeButton="submit"
                                    color="primary"
                                />
                            </CardFooter>
                        </Card>
                        <Snackbar
                            place="tr"
                            color={this.state.alertColor}
                            message={this.state.alertMessage}
                            open={this.state.alertOpen}
                        />
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

NewEmployee.propTypes = {
    t: PropTypes.func,
    onGetDocumentTypes: PropTypes.func.isRequired,
    onAddEmployee: PropTypes.func.isRequired,
}

export default withTranslation()(NewEmployee);
