import { AuthorizationException, BadRequestException, ConnectionException, CoreException, Either, NotFoundException, OperationalException } from "../../../../sdk";
import { inject, injectable } from "inversify";

import { Term } from "../../domain/term";
import { UsersRepository } from "../repositories";

@injectable()
export class GetTermsAndConditionsUsecase {

  @inject(UsersRepository) protected repository!: UsersRepository;

  async execute(version: string): Promise<Either<GetTermsAndConditionsError, Term>> {
    if (!version) {
      return Either.left(new GetTermsAndConditionsInvalidDataError());
    }
    const result = await this.repository.getTermsAndConditions(version);
    if (result.isLeft()) {
      const error = result.getLeft();
      return Either.left(this._getErrroFromException(error));
    }
    return result;
  }

  private _getErrroFromException(exception: CoreException): GetTermsAndConditionsError {
    if (exception instanceof NotFoundException) {
      return new GetTermsAndConditionsNotFoundError();
    }
    if (exception instanceof ConnectionException) {
      return new GetTermsAndConditionsConnectionError();
    }
    if (exception instanceof OperationalException) {
      return new GetTermsAndConditionsOperationalError();
    }
    if (exception instanceof AuthorizationException) {
      return new GetTermsAndConditionsAuthorizationError();
    }
    if (exception instanceof BadRequestException) {
      return new GetTermsAndConditionsBadRequestError(exception.error);
    }

    throw new Error("Unexpected error in GetTermsAndConditionsUsecase");
  }
}

export abstract class GetTermsAndConditionsError {
  error?: string;
}

export class GetTermsAndConditionsInvalidDataError extends GetTermsAndConditionsError { }
export class GetTermsAndConditionsNotFoundError extends GetTermsAndConditionsError { }
export class GetTermsAndConditionsConnectionError extends GetTermsAndConditionsError { }
export class GetTermsAndConditionsOperationalError extends GetTermsAndConditionsError { }
export class GetTermsAndConditionsAuthorizationError extends GetTermsAndConditionsError { }
export class GetTermsAndConditionsBadRequestError extends GetTermsAndConditionsError {
  constructor(public readonly error: string) {
    super();
  }
}
