export class CustomerFinancier {
  id: number;
  name: string;
  affiliateId: string;
  isActive: boolean;
  dueDate?: string;
  customerId: number;

  constructor({ id, name, affiliateId, isActive, dueDate, customerId }: CustomerFinancierDto) {
    this.id = id;
    this.name = name;
    this.affiliateId = affiliateId;
    this.isActive = isActive;
    this.dueDate = dueDate;
    this.customerId = customerId;
  }
}

export interface CustomerFinancierDto {
  id: number;
  name: string;
  affiliateId: string;
  isActive: boolean;
  dueDate?: string;
  customerId: number;
}

export interface CustomerFinancierServer {
  affiliateId: string;
  customerId: number;
  financierId: number;
  dueDate?: string;
}
