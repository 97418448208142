import { Action, AlertWarning, DataReturnAlert, PracticeFeeItem, SnackbarColor, SnackbarProps, TableHead, useFees } from './useFees';
import { Add, Delete, Edit, InfoOutlined } from '@material-ui/icons';
import { GetByEmployeeAndPracticeTypeConnectionError, GetByEmployeeAndPracticeTypeError, GetByEmployeeAndPracticeTypeNotFoundError, GetByEmployeeAndPracticeTypeUsecase, PracticeFee } from '../../../../core';
import React, { useEffect, useState } from 'react';
import { Tooltip, makeStyles } from '@material-ui/core';
import { initialValueAlert, initialValueSnackbar } from './data';

import Button from '../../../CustomButtons/Button';
import CustomDialog from '../../../CustomDialog';
import { DIContainer } from '../../../../Root';
import DeleteFeeAlert from './DeleteFeeAlert';
import FeesForm from './FeeForm';
import GridContainer from '../../../Grid/GridContainer';
import GridItem from '../../../Grid/GridItem';
import PropTypes from 'prop-types';
import SchedulesFees from './SchedulesFees';
import Snackbar from '../../../Snackbar/Snackbar';
import Table from '../../../Table/Table';
import VisibilityIcon from '@material-ui/icons/Visibility';
import config from '../../../../config/config';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { warningColor } from '../../../../assets/components/material-dashboard-pro-react';
import FeesWarningDialog from './FeesWarningDialog';

const dateFormat = config.getDateFormat();

type ComponentProps = {
  employeeId: number;
  employeeTypeId: number;
  practiceTypeName: string;
}

const useStyles = makeStyles({
  tableFeesList: {
    paddingBottom: '0.9375rem !important',
  },
  labelWithInfo: {
    color: 'rgba(0, 0, 0, 0.87) !important',
    marginBottom: 0,
    textWrap: 'nowrap',
    '& span': {
      display: 'inline-block',
      verticalAlign: 'middle',
      '& svg': {
        padding: '7px 3px 3px',
        color: warningColor[0],
        fontSize: '2rem',
      }
    },
  },
  submitGrid: {
    textAlign: 'right',
  },
});

const Fees = (props: ComponentProps) => {
  const { employeeId, employeeTypeId, practiceTypeName } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [ sortDate ] = useFees();

  const getUsecase = DIContainer.get<GetByEmployeeAndPracticeTypeUsecase>(GetByEmployeeAndPracticeTypeUsecase);

  const [loading, setLoading] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<SnackbarProps>(initialValueSnackbar);
  const [fees, setFees] = useState<PracticeFeeItem[] | []>([]);
  const [openFeeDetail, setOpenFeeDetail] = useState<boolean>(false);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [selectedFeeId, setSelectedFeeId] = useState<number | null>(null);
  const [selectedDetailData, setSelectedDetailData] = useState<PracticeFee | undefined>(undefined);
  const [alertWarning, setAlertWarning] = useState<AlertWarning>(initialValueAlert);
  const [alertMessageWarning, setAlertMessageWarning] = useState<AlertWarning>(initialValueAlert);

  const openSnackbar = (color: SnackbarColor, message: string): void => {
    setSnackbar({ color, message, open: true });
    setTimeout(() => {
      setSnackbar(initialValueSnackbar);
    }, 5000);
  }

  const sortMethod = (a: string, b: string) => sortDate(a, b);

  const renderTableHead = (): TableHead[] => {
    const tableHead =  [
      { Header: t('practices.id'), accessor: 'id', width: 50 },
      { Header: t('label.feesAmount'), accessor: 'amountValue' },
      { Header: t('scheduleDetail.dateFrom'), accessor: 'fromDateValue', sortMethod },
      { Header: t('scheduleDetail.dateTo'), accessor: 'toDateValue', sortMethod },
      { Header: t('actions'), accessor: 'actions' },
    ];
    return tableHead;
  }

  const buildButtonFee = (item: PracticeFee): JSX.Element => {
    const titleView = t('label.viewAppointmentsDetail');
    const titleEdit = t('fees.edit');
    const titleDelete = t('fees.delete');
    const actions = <>
      <Tooltip title={titleView}>
        <span>
          <Button simple justIcon id={`button-view-fee-${item.id}`} className="button-view-fee" color="violet" onClick={() => onOpenFeeDetail(item.id)}>
            <VisibilityIcon />
          </Button>
        </span>
      </Tooltip>
      <Tooltip title={titleEdit}>
        <span>
          <Button simple justIcon id={`button-edit-fee-${item.id}`} className="button-edit-fee" color="success" onClick={() => onEditFee(item)}>
            <Edit />
          </Button>
        </span>
      </Tooltip>
      <Tooltip title={titleDelete}>
        <span>
          <Button simple justIcon id={`button-delete-fee-${item.id}`} className="button-delete-fee" color="danger" onClick={() => onDeleteFee(item.id)}>
            <Delete />
          </Button>
        </span>
      </Tooltip>
    </>
    return actions;
  }

  const orderFeesData = (feesData: PracticeFeeItem []): PracticeFeeItem [] => {
    return feesData.sort((a, b) => Date.parse(b.fromDate) - Date.parse(a.fromDate));
  }

  const formatFees = (data: PracticeFee[]): PracticeFeeItem [] =>{
    if (!data.length) {
      return [];
    }
    const feesData = data.map((item: PracticeFee) => {
      const actions = buildButtonFee(item);
      
      const titleTooltip = t('fees.amountsChanged');
      const icon = item.scheduleAmountsChanged ?
        <Tooltip title={titleTooltip}>
          <span><InfoOutlined /></span>
        </Tooltip>
      : '';
      const amount = item.amount ? `$ ${item.amount.toFixed(2)}` : '';
      const amountValue = <p className={classes.labelWithInfo}><span>{amount}</span> {icon}</p>

      return {
        ...item,
        amountValue,
        fromDateValue: moment(item.fromDate).format(dateFormat),
        toDateValue: item.toDate ? moment(item.toDate).format(dateFormat) : undefined,
        actions,
      }
    });
    return orderFeesData(feesData);
  }

  const getDataErrors = (dataError: GetByEmployeeAndPracticeTypeError): void => {
    let message = t('common.errorService');
    if (dataError instanceof GetByEmployeeAndPracticeTypeConnectionError) {
      message = t('confirm.error');
    }
    if (dataError instanceof GetByEmployeeAndPracticeTypeNotFoundError) {
      message = t('fees.notFoundMessage');
    }
    openSnackbar(SnackbarColor.danger, message);
  }

  const getFeesById = async (): Promise<void> => {
    setLoading(true);
    const result = await getUsecase.execute(employeeId, employeeTypeId);

    if (result.isLeft()) {
      const dataError = result.getLeft();
      getDataErrors(dataError);
      setLoading(false);
      return;
    }

    const resultData = result.getRight();
    const feesData = formatFees(resultData);
    setFees(feesData);
    setLoading(false);
  }

  useEffect(() => {
    getFeesById();
  }, [])

  const onOpenFeeDetail = (id: number): void => {
    setOpenFeeDetail(true);
    setSelectedFeeId(id);
  }

  const onCloseFee = (): void => {
    setOpenFeeDetail(false);
    setSelectedFeeId(null);
    getFeesById();
  }

  const onEditFee = (item: PracticeFee): void => {
    setOpenForm(true);
    setSelectedFeeId(item.id);
    setSelectedDetailData(item);
  }

  const onDeleteFee = (feeId: number): void => {
    setAlertWarning({
      message: t('fees.delete.message', { feeId }),
      show: true,
    });
    setSelectedFeeId(feeId);
  }

  const onCloseForm = (): void => {
    setOpenForm(false);
    setSelectedFeeId(null);
    setSelectedDetailData(undefined);
  }

  const titleTooltip = t('label.feedsCreate');
  const titleFeeForm = !selectedFeeId ?
    t('fees.formTitleCreate', { name: practiceTypeName }) :
    t('fees.formTitleEdit', { number: selectedFeeId, name: practiceTypeName });

  const tableData = {
    loading,
    pageSizeOptions: [5, 10, 25, 50, 100],
    defaultPageSize: fees.length > 5 ? 20 : 5,
    tableHead: renderTableHead(),
    tableData: fees,
    colorsColls: ['primary'],
    showPaginationTop: false,
    showPaginationBottom: true,
  }

  const buildFee = (data: PracticeFee[]): PracticeFeeItem[] => {
    const newDataFee = data.map(f => {
      const fee = fees.find((item: PracticeFeeItem) => item.id === f.id);
      if (!fee) {
        return f;
      }
      return {
        ...f,
        amount: f.amount,
        fromDate: f.fromDate,
        toDate: f.toDate,
      }
    });
    return formatFees(newDataFee);
  }

  const updateTableData = (data: PracticeFee[]): PracticeFeeItem[] => {
    const newDataFee = buildFee(data);
    if (fees.length === 0) {
      return orderFeesData(newDataFee);
    }
    const filterOnExistingFees = fees.filter(fee => !data.some(d => fee.id === d.id));
    return orderFeesData([...newDataFee, ...filterOnExistingFees]);
  }

  const handleUpdateTable = (data: PracticeFee[], action: Action, isEditOther: boolean): void => {
    if (isEditOther) {
      setAlertMessageWarning({
        message: t('fees.editDatetoOnOtherFee'),
        show: true,
      });
    }
    const message = action === Action.create ? t('fees.saveMessage') : t('fees.updateMessage');
    const newDataFees = updateTableData(data);
    setFees(newDataFees);
    openSnackbar(SnackbarColor.success, message);
    onCloseForm();
  }

  const getDataOnSelectedSchedule = (selectedFeeId: number): { fromDate: string, toDate: string, amount: number } => {
    const selectedFee = fees.find((item: PracticeFeeItem) => item.id === selectedFeeId);
    if (!selectedFee) {
      return { fromDate: '', toDate: '', amount: 0 };
    }
    return { fromDate: selectedFee.fromDateValue, toDate: selectedFee.toDateValue ?? '', amount: selectedFee.amount };
  }

  const onCloseAlert = (data?: DataReturnAlert): void => {
    if (data?.snackbar) {
      openSnackbar(data.snackbar.color, data.snackbar.message);
    }
    if (data?.fees) {
      setFees(data.fees);
    }
    setAlertWarning(initialValueAlert);
    setSelectedFeeId(null);
  }

  return (
    <>
      <Snackbar
        place="tr"
        color={snackbar.color}
        message={snackbar.message}
        open={snackbar.open}
      />
      <GridContainer>
        <GridItem xs={12} className={classes.tableFeesList}> 
          <Table { ...tableData } />
        </GridItem>
        <GridItem xs={12} className={classes.submitGrid}>
          <Tooltip title={titleTooltip}>
            <span>
              <Button id="button-add-fee" color="primary" onClick={() => setOpenForm(true)}>
                <Add />
              </Button>
            </span>
          </Tooltip>
        </GridItem>
      </GridContainer>
      {selectedFeeId &&
        <CustomDialog
          title={t('fees.title.appointmentsDetailNumber', { feeId: selectedFeeId })}
          maxWidth="md"
          open={openFeeDetail}
          onClose={() => onCloseFee()}
          className="fees-dialog"
        >
          <SchedulesFees practiceFeesId={selectedFeeId} data={getDataOnSelectedSchedule(selectedFeeId)} />
        </CustomDialog>
      }

      <CustomDialog
        title={titleFeeForm}
        maxWidth="sm"
        open={openForm}
        onClose={() => onCloseForm()}
        className="fees-dialog"
      >
        <FeesForm
          data={{ employeeId, employeeTypeId }}
          dataFee={selectedDetailData}
          onSaveDetail={(data: PracticeFee[], action: Action, isEditOther: boolean) => handleUpdateTable(data, action, isEditOther)}
        />
      </CustomDialog>

      <FeesWarningDialog
        onConfirm={() => setAlertMessageWarning(initialValueAlert)}
        showCancel={false}
        onCancel={() => {}}
        alertWarning={alertMessageWarning}
      />

      <DeleteFeeAlert
        alertWarning={alertWarning}
        selectedFeeId={selectedFeeId}
        dataFees={fees}
        onCloseAlert={(data?: DataReturnAlert) => onCloseAlert(data)}
      />
    </>
  )
}

Fees.propTypes = {
  employeeId: PropTypes.number,
  employeeTypeId: PropTypes.number,
  practiceTypeName: PropTypes.string.isRequired,
}

export default Fees;
