import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import i18next from 'i18next';
import Sidebar from '../Sidebar/Sidebar';
import routes from '../../routes';
import image from "../../assets/img/sidebar-2.jpg";
import './Navbar.css';

class Navbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            routes: [],
            user: {}
        }
    }

    UNSAFE_componentWillMount() {
        const cookies = new Cookies();
        if (!cookies.get("sessionActive")) {            
            browserHistory.push('/login');
        }

        this.setState({ user: localStorage.getItem('username') });
    }

    componentDidMount() {
        this.setState({ routes });
        const cookies = new Cookies();
        let lang = cookies.get("lang");
        if (lang != null) {
            i18next.changeLanguage(lang)
        }
    }

    render() {
        return (
            <Sidebar 
                routes={this.state.routes}
                logoText={"HMM"}
                color={"transparent"}
                bgColor={"primary"}
                user={this.state.user}
                image={image}
                miniActive={this.props.miniActive}
                handleDrawerToggle={this.props.handleDrawerToggle}
                open={this.props.open}
                companies={this.props.companies}
            />
        )
    }
}

Navbar.propTypes = {
    companies: PropTypes.array,
    handleDrawerToggle: PropTypes.func,
    miniActive: PropTypes.bool,
    open: PropTypes.bool,
}

const mapStateToProps = (state) => {
    return {
        companies: state.user.companies,
    }
};

export default connect(mapStateToProps, null)(Navbar);
