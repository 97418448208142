import { Action, AlertWarning, SnackbarColor } from './useFees';
import { CreateFeeBadRequestError, CreateFeeConnectionError, CreateFeeError, CreateFeeUsecase, PracticeFee, PracticeFeeRequest, UpdateFeeBadRequestError, UpdateFeeConnectionError, UpdateFeeError, UpdateFeeUsecase } from '../../../../core';
import React, { useEffect, useState } from 'react';
import { SnackbarProps, makeStyles } from '@material-ui/core';
import { initialValueAlert, initialValueSnackbar } from './data';

import ButtonSpinner from '../../../ButtonSpinner';
import CustomInput from '../../../CustomInput/CustomInput';
import { DIContainer } from '../../../../Root';
import DateInput from '../../../DateInput';
import FeesWarningDialog from './FeesWarningDialog';
import GridContainer from '../../../Grid/GridContainer';
import GridItem from '../../../Grid/GridItem';
import PropTypes from 'prop-types';
import Snackbar from '../../../Snackbar/Snackbar';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export interface FeeForm {
  employeeId: number;
  employeeTypeId: number;
}

type ComponentProps = {
  data: FeeForm;
  dataFee: PracticeFee | undefined;
  onSaveDetail: (data: PracticeFee[], action: Action, isEditOther: boolean) => void;
}

const useStyles = makeStyles({
  formFee: {
    minHeight: 350,
    justifyContent: 'space-between',
  },
});

const FeesForm = (props: ComponentProps) => {
  const { dataFee } = props;
  const { employeeTypeId, employeeId } = props.data;
  const { t } = useTranslation();

  const createUsecase = DIContainer.get<CreateFeeUsecase>(CreateFeeUsecase);
  const updateUsecase = DIContainer.get<UpdateFeeUsecase>(UpdateFeeUsecase);

  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState<SnackbarProps>(initialValueSnackbar);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [feeValue, setFeeValue] = useState<number | undefined>(undefined);
  const [isEditFee, setIsEditFee] = useState<boolean>(false);
  const [isSave, setIsSave] = useState(false);
  const [alertWarning, setAlertWarning] = useState<AlertWarning>(initialValueAlert);

  const classes = useStyles();
  const companyIdStorage = localStorage.getItem('itlg_default_company_id');
  const companyCurrencyIdStorage = localStorage.getItem('company_currency_id');

  const onEditState = () => {
    setIsEditFee(true);
    setFromDate(dataFee?.fromDate ?? '');
    setToDate(dataFee?.toDate ?? '');
    setFeeValue(dataFee?.amount ?? undefined);
  }

  useEffect(() => {
    if (dataFee) {
      setLoading(true);
      onEditState();
      setLoading(false);
    }
    return () => {
      setLoading(false);
      setSnackbar(initialValueSnackbar);
      setFromDate('');
      setToDate('');
      setFeeValue(undefined);
      setIsEditFee(false);
      setIsSave(false);
    }
  }, [])

  const openSnackbar = (color: SnackbarColor, message: string): void => {
    setSnackbar({ color, message, open: true });
    setTimeout(() => {
      setSnackbar(initialValueSnackbar);
    }, 7000);
  }

  const handleValueDate = (value: string, state: string): void => {
    const date = value ? moment(value).format('YYYY-MM-DD') : '';
    if (state === 'fromDate') {
      setFromDate(date);
      return;
    }
    setToDate(date);
  }

  const getCreateDataErrors = (dataError: CreateFeeError): void => {
    let message = t('common.errorService');
    if (dataError instanceof CreateFeeConnectionError) {
      message = t('confirm.error');
    }
    if (dataError instanceof CreateFeeBadRequestError) {
      message = dataError.error;
    }
    openSnackbar(SnackbarColor.danger, message);
  }
  
  const getUpdateDataErrors = (dataError: UpdateFeeError): void => {
    let message = t('common.errorService');
    if (dataError instanceof UpdateFeeConnectionError) {
      message = t('confirm.error');
    }
    if (dataError instanceof UpdateFeeBadRequestError) {
      message = dataError.error;
    }
    openSnackbar(SnackbarColor.danger, message);
    setIsSave(false);
  }

  const onCreateFee = async (data: PracticeFeeRequest): Promise<void> => {
    setLoading(true);
    const result = await createUsecase.execute(data);

    if (result.isLeft()) {
      const dataError = result.getLeft();
      getCreateDataErrors(dataError);
      setLoading(false);
      setIsSave(false);
      return;
    }
    const createData = result.getRight();
    props.onSaveDetail(createData, Action.create, createData.length > 1);
    setLoading(false);
  }

  const onUpdateFee = async (data: PracticeFeeRequest, id: number = 0): Promise<void> => {
    setLoading(true);
    const result = await updateUsecase.execute(id, data);

    if (result.isLeft()) {
      const dataError = result.getLeft();
      getUpdateDataErrors(dataError);
      setLoading(false);
      return;
    }
    const updateData = result.getRight();
    props.onSaveDetail(updateData, Action.update, updateData.length > 1);
    setLoading(false);
  }

  const saveFee = async (): Promise<void> => {
    if (!feeValue) {
      return;
    }
    const data: PracticeFeeRequest = {
      employeeTypeId,
      employeeId,
      companyCurrencyId: Number(companyCurrencyIdStorage),
      companyId: Number(companyIdStorage),
      fromDate,
      toDate: toDate ?? '',
      totalAmount: feeValue,
    };
    if (isEditFee) {
      const practiceFeesId = dataFee?.id;
      setAlertWarning(initialValueAlert);
      data.toDate = toDate || '1900-01-01';
      await onUpdateFee(data, practiceFeesId);
      return;
    }
    await onCreateFee(data);
  }

  const areValidDates = () => {
    if (!toDate) {
      return true;
    }
    return new Date (toDate) >= new Date (fromDate);
  }

  const onSubmit = async (): Promise<void> => {
    const isRequiredFields = fromDate && feeValue && toDate; // remove 'toDate' on optional field
    if (!isRequiredFields) {
      return openSnackbar(SnackbarColor.warning, t('common.messageWarning.fieldsComplete'));
    }
    if (!areValidDates()) {
      return openSnackbar(SnackbarColor.danger, t('fees.validDates'));
    }
    setIsSave(true);
    if (feeValue < 0) {
      return openSnackbar(SnackbarColor.danger, t('fees.validAmount'));
    }
    if (isEditFee) {
      return setAlertWarning({ show: true, message: t('fees.edit.warning')})
    }
    await saveFee();
  }

  return (
    <>
      <Snackbar
        place="tr"
        color={snackbar.color}
        message={snackbar.message}
        open={snackbar.open}
      />
      <GridContainer className={classes.formFee} direction="column">
        <GridItem container xs={12}>
          <GridItem xs={12} sm={6} className="no-padding">
              <DateInput
                id="date_from"
                text={`${t('scheduleDetail.dateFrom')} *`}
                value={fromDate}
                onChangeValue={(value: any) => handleValueDate(value, 'fromDate')}
                error={!fromDate && isSave}
              />
          </GridItem>
          <GridItem xs={12} sm={6} className="no-padding">
              <DateInput
                id="date_to"
                text={`${t('scheduleDetail.dateTo')} *`}
                minDate
                min={fromDate}
                value={toDate}
                onChangeValue={(value: any) => handleValueDate(value, 'toDate')}
                disabled={!fromDate}
                error={!toDate && isSave} // remove on 'toDate' optional
              />
          </GridItem>

          <GridItem xs={12} sm={6} className="input-fee-item">
            <CustomInput
              labelText={`${t('fees.label')} $ *`}
              formControlProps={{
                fullWidth: true
              }}
              value={feeValue}
              onChange={(value: any) => setFeeValue(Number(value.target.value))}
              error={((!feeValue || feeValue < 0) && isSave)}
              errorText={t('error.required')}
              id="input-fee"
              type="number"
            />
          </GridItem>
        </GridItem>
        
        <GridItem xs={12}>
          <ButtonSpinner
              className="create-financier-submit"
              onClick={() => onSubmit()}
              disabled={loading}
              label={t('fees.save')}
              labelLoading={t('fees.saving')}
              loading={loading}
              typeButton="submit"
              color="primary"
              id="button-save"
          />
        </GridItem>
      </GridContainer>
      <FeesWarningDialog
        onConfirm={() => saveFee()}
        onCancel={() => setAlertWarning(initialValueAlert)}
        alertWarning={alertWarning}
      />
    </>
  )
}

const dataFee = {
  id: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  companyCurrencyId: PropTypes.number.isRequired,
  fromDate: PropTypes.string.isRequired,
  toDate: PropTypes.string,
  amount: PropTypes.number.isRequired,
}

FeesForm.propTypes = {
  data: PropTypes.object.isRequired,
  dataFee: PropTypes.shape(dataFee),
  onSaveDetail: PropTypes.func.isRequired,
}

export default FeesForm;
