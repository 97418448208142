import { ApiErrorParser, CoreException, Either, VoidSuccess } from "../../../sdk";
import { PracticeFeeRequest, PracticeFee, ScheduleFee, ScheduleFeeRequest } from "../domain";

import { ApiGetByEmployeeAndPracticeTypeFromMap } from "./api-get-by-employee-and-practice-type.from-map";
import { ApiGetByEmployeeAndPracticeTypeFromMappper } from "./api-get-by-employee-and-practice-type.from-mapper";
import { ApiGetSchedulesFeesFromMap } from "./api-get-schedules-fees.from-map";
import { ApiGetSchedulesFeesFromMapper } from "./api-get-schedules-fees.from-mapper";
import ApiInvoker from "../../../api/ApiInvoker";
import { PracticeFeesRepository } from "../application";
import { injectable } from "inversify";

const mapperFees = new ApiGetByEmployeeAndPracticeTypeFromMappper();
const mapperSchedules = new ApiGetSchedulesFeesFromMapper();
const errorParser = new ApiErrorParser();

@injectable()
export class ApiPracticeFeesRepository implements PracticeFeesRepository {

  async getByEmployeeAndPracticeType(employeeId: number, employeeTypeId: number): Promise<Either<CoreException, PracticeFee[]>> {
    return new Promise((resolve, _) => {
      let result: Either<CoreException, PracticeFee[]>;
      const params = { employeeId, employeeTypeId };
      ApiInvoker.getFeesByEmployeeIdAndEmployeeTypeId(params, (res: ApiGetByEmployeeAndPracticeTypeFromMap[]) => {
        const fees = res.map(fee => mapperFees.fromMap(fee));
        result = Either.right(fees);
        resolve(result);
      }, (error: CoreException) => {
        const err = errorParser.parse(error);
        result = Either.left(err);
        resolve(result);
      });
    });
  }

  async createFee(data: PracticeFeeRequest): Promise<Either<CoreException, PracticeFee[]>> {
    return new Promise((resolve, _) => {
      let result: Either<CoreException, PracticeFee[]>;
      ApiInvoker.createFee(data, (res: ApiGetByEmployeeAndPracticeTypeFromMap[]) => {
        const fees = res.map((f) => mapperFees.fromMap(f));
        result = Either.right(fees);
        resolve(result);
      }, (error: CoreException) => {
        const err = errorParser.parse(error);
        result = Either.left(err);
        resolve(result);
      });
    });
  }

  async deleteFee(id: number): Promise<Either<CoreException, VoidSuccess>> {
    return new Promise((resolve, _) => {
      let result: Either<CoreException, VoidSuccess>;
      ApiInvoker.deleteFee(id, (res: any) => {
        result = Either.right(res);
        resolve(result);
      }, (error: CoreException) => {
        const err = errorParser.parse(error);
        result = Either.left(err);
        resolve(result);
      });
    });
  }

  async updateFee(practiceFeeId: number, data: PracticeFeeRequest): Promise<Either<CoreException, PracticeFee[]>> {
    return new Promise((resolve, _) => {
      let result: Either<CoreException, PracticeFee[]>;
      ApiInvoker.updateFee(practiceFeeId, data, (res: ApiGetByEmployeeAndPracticeTypeFromMap[]) => {
        const fees = res.map((f) => mapperFees.fromMap(f));
        result = Either.right(fees);
        resolve(result);
      }, (error: CoreException) => {
        const err = errorParser.parse(error);
        result = Either.left(err);
        resolve(result);
      });
    });
  }

  async getSchedulesFeesByPracticeType(practiceTypeId: number): Promise<Either<CoreException, ScheduleFee[]>> {
    return new Promise((resolve, _) => {
      let result: Either<CoreException, ScheduleFee[]>;
      ApiInvoker.getSchedulesFeesByPracticeType(practiceTypeId, (res: ApiGetSchedulesFeesFromMap[]) => {
        const schedules = res.map(sch => mapperSchedules.fromMap(sch));
        result = Either.right(schedules);
        resolve(result);
      }, (error: CoreException) => {
        const err = errorParser.parse(error);
        result = Either.left(err);
        resolve(result);
      });
    });
  }

  async updateScheduleFee(data: ScheduleFeeRequest[]): Promise<Either<CoreException, VoidSuccess>> {
    return new Promise((resolve, _) => {
      let result: Either<CoreException, VoidSuccess>;
      ApiInvoker.updateScheduleFee(data, (res: ScheduleFeeRequest[]) => {
        result = Either.right(res);
        resolve(result);
      }, (error: CoreException) => {
        const err = errorParser.parse(error);
        result = Either.left(err);
        resolve(result);
      });
    });
  }

}
