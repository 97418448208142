import { ApiErrorParser, CoreException, Either, VoidSuccess } from "../../../sdk";
import { CustomerFinancier, CustomerFinancierServer } from "../domain";

import { ApiCustomerFinanciersFromMap } from "./api-customer-financiers-items.from-map";
import { ApiCustomerFinanciersFromMappper } from "./api-customer-financiers.from-mapper";
import { ApiFinanciersFromMap } from "./api-financiers-items.from-map";
import { ApiFinanciersFromMappper } from "./api-financiers.from-mapper";
import BusinessApiInvoker from "../../../api/BusinessApiInvoker";
import { Financier } from "../domain/financier";
import { FinanciersRepository } from "../application/repositories";
import { injectable } from "inversify";

const mapper = new ApiFinanciersFromMappper();
const mapperCustomer = new ApiCustomerFinanciersFromMappper();
const errorParser = new ApiErrorParser();

@injectable()
export class ApiFinanciersRepository implements FinanciersRepository {

  async deleteCustomerFinancier(personId: number, financierId: number): Promise<Either<CoreException, VoidSuccess>> {
    return new Promise((resolve, _) => {
      let result: Either<CoreException, VoidSuccess>;
      BusinessApiInvoker.deleteCustomersFinanciers(personId, financierId, (res: any) => {
        result = Either.right(res);
        resolve(result);
      }, (error: CoreException) => {
        const err = errorParser.parse(error);
        result = Either.left(err);
        resolve(result);
      });
    });
  }

  async createCustomerFinancier(body: CustomerFinancierServer[]): Promise<Either<CoreException, CustomerFinancier[]>> {
    return new Promise((resolve, _) => {
      let result: Either<CoreException, CustomerFinancier[]>;
      BusinessApiInvoker.postCustomersFinanciers(body, (res: ApiCustomerFinanciersFromMap[]) => {
        const financier = res.map((r: ApiCustomerFinanciersFromMap) => mapperCustomer.fromMap(r));
        result = Either.right(financier);
        resolve(result);
      }, (error: CoreException) => {
        const err = errorParser.parse(error);
        result = Either.left(err);
        resolve(result);
      });
    });
  }

  async getCustomerFinanciers(personId: number): Promise<Either<CoreException, CustomerFinancier[]>> {
    return new Promise((resolve, _) => {
      let result: Either<CoreException, CustomerFinancier[]>;
      BusinessApiInvoker.getCustomersFinanciers(personId, (res: ApiCustomerFinanciersFromMap[]) => {
        const financier = res.map((financier: ApiCustomerFinanciersFromMap) => mapperCustomer.fromMap(financier));
        result = Either.right(financier);
        resolve(result);
      }, (error: CoreException) => {
        const err = errorParser.parse(error);
        result = Either.left(err);
        resolve(result);
      });
    });
  }

  getFinanciers(): Promise<Either<CoreException, Financier[]>> {
    return new Promise((resolve, _) => {
      let result: Either<CoreException, Financier[]>;
      BusinessApiInvoker.getFinanciers((res: ApiFinanciersFromMap[]) => {
        const financiersActive = res.filter((f: ApiFinanciersFromMap) => (f.active === true));
        const financiers = financiersActive.map((financier: ApiFinanciersFromMap) => mapper.fromMap(financier));
        result = Either.right(financiers);
        resolve(result);
      }, (error: CoreException) => {
        const err = errorParser.parse(error);
        result = Either.left(err);
        resolve(result);
      });
    });
  }

}
