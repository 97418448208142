import { ApiFinanciersFromMap } from "./api-financiers-items.from-map";
import { Financier } from "../domain";

export class ApiFinanciersFromMappper {
  fromMap(map: ApiFinanciersFromMap): Financier {
    const financier = new Financier({
      id: map.financierId,
      value: map.businessName,
    });
    return financier;
  }
}
