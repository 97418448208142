import './ConfirmUser.css';

import { FileCopyOutlined, RemoveRedEye, VisibilityOff } from '@material-ui/icons';
import React, { Component } from 'react';

import Button from '../CustomButtons/Button';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import PropTypes from 'prop-types';
import apple from '../../assets/img/apple.png';
import google from '../../assets/img/google.png';
import hmmLogo from '../../assets/img/login/logo-hmm.png';
import { withTranslation } from 'react-i18next';

class ConfirmUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMessage: '',
            hash: '',
            hidden: false,
            response: {},
        }
    }

    componentWillMount() {
        const { hash } = this.props.params;
        const indexOfCompany = hash.lastIndexOf('-');
        this.setState({ hash });
        
        const companyId = hash.substring(indexOfCompany+1);
        fetch(`/created/user/${hash}`, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'x-itlg-companyId': companyId,
            })
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            if (data.error) {
                this.setState({ errorMessage: data.error} );
                return;
            }
            this.setState({ response: data });
        })
        .catch((error) => {
            this.setState({ errorMessage: error.message });
        });

    }

    handleEye = () => {
        this.setState((prev) => ({ hidden: !prev.hidden }));
    } 

    handleCopy = async () => {
        const { response } = this.state;
        if (response) {
            const passowrdText = response?.password;
            if ('clipboard' in navigator) {
                return await navigator.clipboard.writeText(passowrdText);
            }
        }
    }

    getPassword = () => {
        const { hidden, response } = this.state;
        if (response) {
            if (hidden) {
                const hiddenPass = response.password.replace(/./g, '*')
                return hiddenPass;
            }
            return response.password;
        }
        return '';
    }

    render() {
        const { t } = this.props;
        const { hidden, errorMessage } = this.state;
        const password = this.getPassword();

        return (
            <GridContainer className="appointment-detail container-confirm">
                <GridItem className="appointment-data" xs={12}>
                    <div className="hmm-logo">
                        <img src={hmmLogo} alt="HMM Logo" />
                    </div>
                </GridItem>
                <GridItem className="appointment-data" xs={12}>
                    <hr className="line" />
                </GridItem>
                <GridItem className="appointment-data header-confirm" xs={12}>
                    <p className="label title">{t('user.confirm.hello')}</p>
                    <p className="label title">{t('user.confirm.subtitle1')}</p>
                </GridItem>

                <GridItem className="appointment-data" xs={12}>
                    <p className="label account">{t('user.confirm.subtitle2')}</p>
                </GridItem>

                <GridItem className="appointment-data" xs={12}>
                    <p className="label account">{t('user.confirm.subtitle3')}</p>
                </GridItem>

                <GridItem className="appointment-data" xs={12} sm={12}>
                    <p className="label account">
                        {t('user.confirm.subtitle4')}
                        <span className="password-icons">
                            <span className="password">{password}</span>
                            <Button simple className="icon" onClick={this.handleEye} disabled={!password}>
                                {hidden ?
                                    <VisibilityOff style={{ color: 'gray', marginTop: 0 }} />
                                    :
                                    <RemoveRedEye style={{ color: 'gray', marginTop: 0 }} />
                                }
                            </Button>
                            <Button simple className="icon" onClick={this.handleCopy} disabled={!password}>
                                <FileCopyOutlined style={{ color: 'gray', marginTop: 0 }} />
                            </Button>
                        </span>
                    </p>
                </GridItem>
                
                {errorMessage &&
                    <GridItem className="appointment-data" xs={12}>
                        <p className="label error">{errorMessage}</p>
                    </GridItem>
                }

                <GridItem className="appointment-data" xs={12}>
                    <div className="footer">
                        <span className="footer-text">
                            <span className="labelDownload">{t('user.confirm.stores')}</span>
                            <a href="https://apps.apple.com/ar/app/home-medical-management/id1522565212" target="_blank" rel="noopener noreferrer">
                                <img src={apple} className="stores" alt="Apple" />
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.itlg.hmm&hl=es_AR&gl=US" target="_blank" rel="noopener noreferrer">
                                <img src={google} className="stores" alt="Google" />
                            </a>
                        </span>
                    </div>
                </GridItem>
            </GridContainer>
        );
    }
}

ConfirmUser.propTypes = {
    t: PropTypes.func,
    params: PropTypes.object,
}

export default withTranslation()(ConfirmUser);
