import BaseInvoker from './BaseInvoker';
import { Component } from 'react';

const urlInvoke = '/api';
const urlInvokeMobility = `/api/mobility`;
const baseInvoker = new BaseInvoker();

export default class PersonApiInvoker extends Component {

	static getCustomers(show, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/customers/?show=${show}`, callback, callerror);
	}
	static getCustomersList(show, actives, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/customers/?show=${show}&actives=${actives}`, callback, callerror);
	}
	static getCustomersActive(show,callback, callerror) {
		baseInvoker.get(`${urlInvoke}/customers/?show=${show}&actives=${true}`, callback, callerror);
	}

	static getCustomersMobility(show, callback, callerror) {
		baseInvoker.get(`${urlInvokeMobility}/customers/?show=${show}`, callback, callerror);
	}

	static getPatient(customerId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/patient/user/${customerId}`,callback,callerror
		);
	  }

	static getCustomersOptions(value, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/customer/get?name=${value}`, callback, callerror);
	}

	static getCustomer(customerId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/customer/${customerId}`, callback, callerror);
	}
	
	static getCustomerSignature(scheduleId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/schedule/${scheduleId}/scheduleinformation`, callback, callerror);
	}

	static postCustomer(body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/customer`, body, callback, callerror);
	}

	static getCustomerMedicalPlan(username, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/plan/patiente/${username}`, callback, callerror);
	}

	static patchCustomer(body, callback, callerror) {
		baseInvoker.patch(`${urlInvoke}/customer`, body, callback, callerror);
	}

	static getAddress(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/address`, callback, callerror);
	}

	static getPersonsAddress(personId, verified, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/persons/${personId}/address/?verified=${verified}`, callback, callerror);
	}
	
	static getPersonsAddressMobility(personId, callback, callerror) {
		baseInvoker.get(`${urlInvokeMobility}/persons/${personId}/address`,callback,callerror);
	}

	static deletePersonsAddressCustomer(addressId, callback, callerror) {
		baseInvoker.delete(`${urlInvoke}/customer/address/${addressId}`, {}, callback, callerror);
	}

	static deletePersonsAddress(personId, addressId, callback, callerror) {
		baseInvoker.delete(`${urlInvoke}/persons/${personId}/address/${addressId}`, {}, callback, callerror);
	}

	static postPersonAddress({ personId, addressId }, body, callback, callerror) {
		const baseUrl =  `${urlInvoke}/persons/${personId}/address`;
		const url = addressId ? `${baseUrl}/${addressId}` : baseUrl;
		baseInvoker.post(url, body, callback, callerror);
	}

	static getPhoneTypes(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/phoneTypes`, callback, callerror);
	}

	static getGenderTypes(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/sex-type`, callback, callerror);
	}

	static postPersonsPhone(personId, phoneId, body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/persons/${personId}/phone/${phoneId}`, body, callback, callerror);
	}

	static postPersonsNewPhone(personId, body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/persons/${personId}/phone`, body, callback, callerror);
	}

	static getPersonPhone(personId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/persons/${personId}/phone`, callback, callerror);
	}

	static deletePersonsPhone(personId, phoneId, callback, callerror) {
		baseInvoker.delete(`${urlInvoke}/persons/${personId}/phone/${phoneId}`, {}, callback, callerror);
	}

	static getIdTypes(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/idTypes`, callback, callerror);
	}

	static getEmployee(employeeId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/employee/${employeeId}`, callback, callerror);
	}

	static getEmployeeGeographics(employeeId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/employees/${employeeId}/geographic-zone`, callback, callerror);
	}

	static getNationalities(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/nationalities`, callback, callerror);
	}

	static getEmployees(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/employees?employee-category=PROFESSIONAL,NULL`, callback, callerror);
	}

	static getEmployeesByName(value, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/employees/get?name=${value}`, callback, callerror);
	}

	static getEmployeesWithGeoZone(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/employees-geographic-zone?employee-category=PROFESSIONAL,NULL`, callback, callerror)
	}

	static getEmployeesWithGeoZoneFilter(params, callback, callerror) {
		const filter = `employee-category=PROFESSIONAL,NULL&${params}`;
		baseInvoker.get(`${urlInvoke}/employees-geographic-zone-filter?${filter}`, callback, callerror)
	}

	static getGeographics(countryId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/countries/${countryId}/geographic-zones`, callback, callerror);
	}

	static getEmployeeEmployeeType(employeeId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/employees/${employeeId}/employee-type`, callback, callerror);
	}

	static getGeographicsCompany(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/geographic-zones`, callback, callerror);
	}

	static getEmployeesType(type, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/employee-type?employee-category=${type}`, callback, callerror);
	}

	static postEmployeeType(employeeId, body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/employees/${employeeId}/employees-type`, body, callback, callerror);
	}

	static postEmployeeGeographic(employeeId, body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/employees/${employeeId}/geographic-zone`, body, callback, callerror);
	}

	static deleteEmployeeType(personId, employeeTypeId, callback, callerror) {
		baseInvoker.delete(`${urlInvoke}/employee/employee-type?employeeId=${personId}&employeeTypeId=${employeeTypeId}`, {}, callback, callerror);
	}

	static deleteEmployeeGeographic(personId, employeeGeographicId, callback, callerror) {
		baseInvoker.delete(`${urlInvoke}/employee/geographic-zone?employeeId=${personId}&geographicZoneId=${employeeGeographicId}`, {}, callback, callerror);
	}

	static getEmployeesAddress(employeeId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/employees/${employeeId}/geographic-zone`, callback, callerror);
	}

	static getEmployeesByPracticetype(lender, practiceTypeId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/employee-by-practicetype/${practiceTypeId}/?lender=${lender}`, callback, callerror);
	}

	static getEmployeesByPracticetypeAndGeographics(lenderId, practiceTypeId, geographicZoneId, callback, callerror) {
		let url = `${urlInvoke}/employee-by-practicetype/${practiceTypeId}`;
		if (geographicZoneId) {
			url = `${urlInvoke}/employee-by-practicetype/${practiceTypeId}/?geographicZone=${geographicZoneId}`;
		}
		if (lenderId) {
			if (geographicZoneId) {
				url = `${url}&lender=${lenderId}`;
			} else {
				url = `${url}/?lender=${lenderId}`;
			}
			
		}
		return baseInvoker.get(url, callback, callerror);
	}

	static postEmployeeRoadmap(body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/employee/hojaRuta`, body, callback, callerror);
	}

	static getAllEmployeesType(type, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/employee-type?employee-category=${type}`, callback, callerror);
	}

	static postEmployee(body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/employee`, body, callback, callerror);
	}

	static patchEmployee(body, callback, callerror) {
		baseInvoker.patch(`${urlInvoke}/employee`, body, callback, callerror);
	}

	static getEmployeesCoordinators(callback, callerror){
		baseInvoker.get(`${urlInvoke}/employees/employee-type-name?employee-type-name=COM`, callback, callerror)
	}

	static putDriver(body, callback, callerror) {
		baseInvoker.put(`${urlInvokeMobility}/drivervehicle/update`, body, callback, callerror);
	}

	static getUsers(params, callback, callerror){
		const url = `${urlInvoke}/invitation/users/?${params}`;
		baseInvoker.get(url, callback, callerror)
	}

	static createUser(hash, callback, callerror){
		const url = `/created/user/${hash}`;
		baseInvoker.post(url,{}, callback, callerror)
	}

	static forgotPassKey(username, callback, callerror){
		const url = `/keychange/${username}`;
		baseInvoker.post(url,{}, callback, callerror)
	}

	static postInvitation(body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/invitation`, body, callback, callerror);
	}

	static getNotification(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/notification/?page=0&pageSize=1000`, callback, callerror);
	}

	static setNotificationRead(notificationId, callback, callerror) {
		baseInvoker.put(`${urlInvoke}/notification/${notificationId}`, callback, callerror);
	}

	static activate(personId, callback, callerror) {
		baseInvoker.put(`${urlInvoke}/person/${personId}/activate`, {}, callback, callerror);
	}

	static deactivate(personId, callback, callerror) {
		baseInvoker.put(`${urlInvoke}/person/prof/${personId}/deactivate`,{}, callback, callerror);
	}


	static customerActivate(personId, callback, callerror) {
		baseInvoker.put(`${urlInvoke}/person/${personId}/activate`, {}, callback, callerror);
	}

	static customerDeactivate(personId, callback, callerror) {
		baseInvoker.put(`${urlInvoke}/person/customer/${personId}/deactivate`,{}, callback, callerror);
	}

	// TERMS
	static getTermsAndConditions(version, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/employee/terms/${version}`, callback, callerror);
	}
	static updateUserTerms({ employeeId, version, isAccept }, callback, callerror){
		const url = `${urlInvoke}/employee/terms/${employeeId}/${version}/${isAccept}`;
		baseInvoker.post(url, {}, callback, callerror);
	}

}
