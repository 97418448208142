import './Login.css';

import { Card, CardBody, CardFooter } from '../Card';
import {
    Checkbox,
    CircularProgress,
    FilledInput,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    withStyles
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import BusinessIcon from '@material-ui/icons/Business';
import Button from '../CustomButtons/Button';
import { Check } from '@material-ui/icons';
import Cookies from 'universal-cookie';
import { Key } from '../../icons';
import LetterAvatar from '../Avatar/LetterAvatar';
import Person from '@material-ui/icons/Person';
import PropTypes from 'prop-types';
import ReCAPTCHA from "react-google-recaptcha";
import SelectInput from '../SelectInput';
import Snackbar from '../Snackbar/Snackbar';
import TermsAndConditionsModal from '../TermsAndConditionsModal';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { browserHistory } from 'react-router';
import hmmLogo from '../../assets/img/login/logo-hmm.png';
import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';
import pwdBy from '../../assets/img/glin-white.png';
import styles from '../../assets/components/customCheckboxRadioSwitch';
import { withTranslation } from 'react-i18next';

const langMap = { "es": "es-ES", "en": "en-US", "pt": "pt-BR", "it": "it-IT", "fr": "fr-FR" };

const Login = ({ t, classes }) => {
    const [state, setState] = useState({
        loading: false,
        disableInteractions: false,
        checking: false,
        rememberMe: false,
        showPassword: false,
        realms: [],
        disabledRealm: false,
        realm: '',
        username: '',
        password: '',
        profile: {},
        isProfessional: false,
        isSubmitLogin: false,
        lang: 'es',
        isSubmitForgotPass: false,
    });
    const [token, setToken] = useState(null);
    const [keyCaptcha , setKeyCaptcha] = useState(null);
    const [termsUse, setTermsUse] = useState({
        requestTermsVersion: '',
        requiereTermsAccept: false,
        showTerms: false,
    });

    useEffect(() => {
        // code to run on component mount
        const fetchData = async () => {
            const response = await fetch('/getkeys/');
            let data = await response.json();
            setKeyCaptcha(data.key);
        }
        fetchData();
        const cookies = new Cookies();
        let lang = cookies.get("lang");
        if (lang != null) {
            setState((prev) => ({ ...prev,  lang }));
            i18next.changeLanguage(lang);
        }
    }, []);

    const [alert, setAlert] = useState({
        color: 'success',
        message: '',
        open: false,
    });

    const openAlert = (color, message) => {
        setAlert((prev) => ({
            ...prev,
            color,
            message,
            open: true,
        }));

        setTimeout(() => {
            setAlert((prev) => ({ ...prev, open: false }));
        }, 5000);
    }

    const formatRealms = (data) => {
        return data.map((d, idx) => ({
            id: idx + 1, name: d.realmName, value: d.realm, companyId: d.companyId,
        }));
    }

    const getRealms = () => {
        const requestOptions = {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            body: JSON.stringify({ username: state.username.trim().toLowerCase() }),
        };

        fetch('/api/realms', requestOptions)
            .then(response => response.json())
            .then(data => {
                setState((prev) => ({
                    ...prev,
                    disableInteractions: false,
                    loading: false,
                }));

                if (!data.error) {
                    const realms = formatRealms(data);
                    const realm = realms.length == 0 ? '' : realms[0]['id'];
                    const disabledRealm = realms.length <= 1;
                    setState((prev) => ({ ...prev, realms, realm, disabledRealm }));
                } else {
                    openAlert('danger', t('login.bad_credentials'))
                    setState((prev) => ({
                        ...prev,
                        disableInteractions: false,
                        loading: false,
                    }));
                }
            })
            .catch(() => {
                setState((prev) => ({
                    ...prev,
                    disableInteractions: false,
                    loading: false,
                }));
            });
    }

    const handleRealms = () => {
        if (state.isProfessional || !state.username) {
            return;
        }
        setState((prev) => ({
            ...prev,
            disableInteractions: true,
            loading: true,
        }));
        getRealms();
    }

    const setLocalStorage = (profile) => {
        const { companies, company_partnership_id, profesional, userId, firstName, lastName, email, companyCurrencyId, requiereTermsAccept, requestTermsVersion } = profile;
        if (companies && companies.length === 1) {
            localStorage.setItem('company', JSON.stringify(companies[0]));
            localStorage.setItem('itlg_default_company_id', companies[0].company_id);
            localStorage.setItem('itlg_default_company_name', companies[0].name);
        } else {
            localStorage.setItem('itlg_default_company_id', -1);
            localStorage.setItem('itlg_default_company_name', "");
        }
        
        if (company_partnership_id != null) {
            localStorage.setItem('company_partnership_id', company_partnership_id == null || company_partnership_id == 'null' ? null : company_partnership_id);
        } else {
            localStorage.removeItem("company_partnership_id");
        }
        localStorage.setItem('profesional', profesional);
        localStorage.setItem('user_id', userId);
        localStorage.setItem('username', `${firstName} ${lastName}`);
        localStorage.setItem('email', email);
        localStorage.setItem('APP_VERSION', profile.APP_VERSION);
        localStorage.setItem('RELEASE_DATE', profile.RELEASE_DATE);
        localStorage.setItem('companies', JSON.stringify(companies));
        localStorage.setItem('company_currency_id', companyCurrencyId);
        const terms = { requiereTermsAccept, requestTermsVersion };
        localStorage.setItem('terms_and_conditions', JSON.stringify(terms));
        return localStorage;
    }

    const getProfile = () => {
        const cookies = new Cookies();
        let lang = cookies.get("lang");
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': lang,
            },
        };

        fetch('/api/profile', requestOptions)
            .then(response => {
                let cookiename = "sessionActive";
                let cookiestring = RegExp(cookiename+"=[^;]+").exec(document.cookie);
                if (cookiestring!= null) {
                    let key = decodeURIComponent(cookiestring.toString().replace(/^[^=]+./,""));
                    localStorage.setItem('sessionId', key);
                    window.sessionStorage.setItem("sessionId", key);
                }
                if (response.status !== 200) {
                    setState((prev) => ({
                        ...prev,
                        disableInteractions: false,
                        loading: false,
                    }));
                    openAlert('danger', t('error.getProfile'));
                }
                return response.json();
            })
            .then(async profile => {
                if (!profile) {
                    return openAlert('danger', t('error.getProfile'));
                }
                setState((prev) => ({ ...prev, profile }));
                setTimeout(() => {
                    if (profile.requiereTermsAccept) {
                        setTermsUse({
                            requiereTermsAccept: profile.requiereTermsAccept,
                            requestTermsVersion: profile.requestTermsVersion,
                            showTerms: profile.requiereTermsAccept,
                        });
                        setState((prev) => ({ ...prev, disableInteractions: false }));
                        return;
                    }
                    setState((prev) => ({
                        ...prev,
                        disableInteractions: false,
                        loading: false,
                    }));
                    if (!profile.active) {
                        setState((prev) => ({ ...prev, profile: {} }));
                        return openAlert('danger', t('error.login.desactivado'));
                    }
                    setLocalStorage(profile);
                    browserHistory.push('/solicitudes');
                }, 3000);
            });
    }

    const getSelectedRealm = () => {
        if (!state.realms) {
            return null;
        }
        const realm = state.realms.find(r => r.id === state.realm);
        if (!realm) {
            return null;
        }
        return realm;
    }

    const bytesToBase64 = (bytes) => {
        const binString = String.fromCodePoint(...bytes);
        return btoa(binString);
    }

    const econdePassword = (password) => {
        const passwordEncoded = bytesToBase64(new TextEncoder().encode(password));
        return passwordEncoded;
    }

    const postToken = () => {
        let re = getSelectedRealm();
        let realm = '';
        if (re != null) {
            realm = getSelectedRealm().value;
        }

        const username = state.username.trim().toLowerCase();
        const password = econdePassword(state.password);

        const requestOptions = {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            body: JSON.stringify({
                username,
                password,
                realm,
                profesional: state.isProfessional,
            }),
        };

        fetch('/api/auth/token', requestOptions)
            .then(response => (response.json()))
            .then(data => {
                if (data.error) {
                    openAlert('danger', t('login.bad_credentials'));
                    setState((prev) => ({
                        ...prev,
                        disableInteractions: false,
                        loading: false,
                    }));
                    return;
                }
                getProfile();
            })
            .catch(() => {
                openAlert('danger', t('login.bad_credentials'));
                setState((prev) => ({
                    ...prev,
                    disableInteractions: false,
                    loading: false,
                }));
            });
    }

    const handleLogin = () => {
        setState((prev) => ({ ...prev, isSubmitLogin: true }));
        setState((prev) => ({
            ...prev,
            disableInteractions: true,
            loading: true,
        }));
        postToken();
    }

    const handleValue = (value, type) => {
        if (type === 'isProfessional') {
            const cookies = new Cookies();
            cookies.set("profesionals", value);
        }
        setState((prev) => ({ ...prev, [type]: value }));
    }

    const changeLang = (event) => {
        setState((prev) => ({ ...prev, lang: event.target.value }));
        const { username, password, realm } = state;

        const cookies = new Cookies();
        cookies.set("lang", event.target.value);
        i18next.changeLanguage(event.target.value);
        setState({ username, password, realm })
        handleRealms();
        window.location.reload();
        
    }

    const renderLoginForm = () => (
        <React.Fragment>
            <div className="right">
                <select id="select-language" onChange={(item) => changeLang(item)} value={state.lang}>
                    <option value="es">Español</option>
                    <option value="en">Inglés</option>
                    <option value="pt">Portugués</option>
                    <option value="it">Italiano</option>
                    <option value="fr">Francés</option>
                </select>
            </div>
            <FormControl variant="filled">
                <FilledInput
                    id="username"
                    disabled={state.disableInteractions}
                    onBlur={() => handleRealms()}
                    inputProps={{
                        disabled: state.disableInteractions,
                        placeholder: t('login.user'),
                    }}
                    type="text"
                    startAdornment={<InputAdornment position="start"><Person /></InputAdornment>}
                    onChange={(event) => handleValue(event.target.value, 'username')}
                    error={(!state.username && state.isSubmitLogin) || (!state.username && state.isSubmitForgotPass)}
                    value={state.username}
                />
            </FormControl>

            {!state.isProfessional &&
                <FormControl>
                    <SelectInput
                        id="realm"
                        className="company"
                        placeholder={t('login.realm')}
                        disabled={state.disabledRealm}
                        elements={state.realms}
                        value={state.realm}
                        onSelectedValue={(value) => handleValue(value, 'realm')}
                        invalid={(!state.realm && state.isSubmitLogin) || (!state.realm && state.isSubmitForgotPass)}
                        error={!state.realm && state.isSubmitLogin}
                        isAdornedStart={true}
                        iconAdornedStart={BusinessIcon}
                        onGetOptionLabel={(option) => option.name || ''}
                        onGetOptionSelected={(option) => (option?.id) ? option?.id : null}
                    />
                </FormControl>
            }

            <FormControl variant="filled">
                <FilledInput
                    id="password"
                    disabled={state.disableInteractions}
                    inputProps={{
                        disabled: state.disableInteractions,
                        placeholder: t('login.password'),
                    }}
                    type={!state.showPassword ? 'password' : 'text'}
                    startAdornment={<InputAdornment position="start"><Key /></InputAdornment>}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setState((prev) => ({ ...prev, showPassword: !state.showPassword }))}
                                edge="end"
                            >
                                {state.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                    onChange={(event) => handleValue(event.target.value, 'password')}
                    value={state.password}
                    error={!state.password && state.isSubmitLogin}
                />
            </FormControl>
            <div>
                <FormControlLabel
                    control={
                        <Checkbox
                            tabIndex={-1}
                            className="login-remember-me"
                            checked={state.isProfessional}
                            onChange={(event) => handleValue(event.target.checked, 'isProfessional')}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                            }}
                        />
                    }
                    classes={{ label: classes.label, root: 'check-label' }}
                    label={t('login.profesional')}
                />
            </div>
        </React.Fragment>
    );

    const renderLoadingProfile = () => (
        <div className="loading-profile">
            <LetterAvatar user={`${state.profile.firstName} ${state.profile.lastName}`} />
            <span className="loading-profile-welcome">{t('login.welcome')}</span>
            <span className="loading-profile-username">
                {`${state.profile.firstName} ${state.profile.lastName}`}
            </span>
            <CircularProgress className="circular-progress" />
        </div>
    );

    const getLangByCookies = () => {
        const cookies = new Cookies();
        let lang = cookies.get("lang");
        if (lang == null) {
            return langMap['es']
        } else {
            return langMap[lang] == null ? langMap['es'] : langMap[lang];
        }
    }

    const goToForgotPass = async () => {
        setState((prev) => ({ ...prev, isSubmitLogin: false, isSubmitForgotPass: true }));
        if (!state.realm || !state.username) {
            openAlert('warning', t('common.messageWarning.fieldsComplete'));
            return;
        }

        const username = state.username;
        const realmSelect = getSelectedRealm();
        const realmName = realmSelect.code;
        const companyId = `${realmSelect.companyId}`;
        try {
            const params = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-itlg-companyId': companyId,
                    'Accept-Language': getLangByCookies() 
                },
                body: JSON.stringify({}),
            };
            const response = await fetch(`/keychange/${username}`, params);
            if (response.status === 200) {
                await browserHistory.push({
                    state: { username, realm: state.realm, realmName, companyId },
                    pathname: '/forgot-password',
                });
                return;
            }
        } catch (_) {
            console.error('** error goToForgotPass');
            openAlert('danger', t('common.errorService'));
        }
    }

    const validateLogin = () => {
       
        const options = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'g-recaptcha-token': token
            })
        }
        fetch(`/recaptcha`, options)
            .then(response => {
                return response.json();
            })
            .then(data => {
                if (!data.success) {
                    return openAlert('danger', t('login.is.robot'));
                }
                handleLogin();
            })
            .catch((_) => {
                openAlert('danger', t('login.is.robot'));
            });
    }

    const onVerify = (t) => {
        setToken(t);
    }

    const handleCloseTerms = ({ acceptTerms, error = {} }) => {
        if (error.isError) {
            setTermsUse((prev) => ({ ...prev, showTerms: false }));
            setState((prev) => ({ ...prev, loading: false, disableInteractions: false, profile: {} }));
            openAlert('danger', error.message);
            return;
        }
        if (!acceptTerms) {
            setTermsUse((prev) => ({ ...prev, showTerms: false }));
            setState((prev) => ({ ...prev, loading: false, disableInteractions: false, profile: {} }));
            openAlert('danger', t('termsUse.rejectedConfirm.message'));
            return;
        }

        const profile = { ...state.profile, requiereTermsAccept: false };
        setState((prev) => ({ ...prev, profile, disableInteractions: false }));
        setTermsUse((prev) => ({ ...prev, showTerms: false }));
        setLocalStorage(profile);
        openAlert('success', t('termsUse.acceptSuccess'));
        setTimeout(() => {
            browserHistory.push('/solicitudes');
        }, 1500);
    }

    return (
        <>
            {!keyCaptcha ? <div/> :
                <div className="wrapper login-wrapper">
                    <div className="fullpage">
                        <Snackbar
                            place="tr"
                            color={alert.color}
                            message={alert.message}
                            open={alert.open}
                        />
                        <div className="auth-user-head"></div>
                        <Grid className="auth-user-page login" container>
                            <Grid item className="hmm-logo" xs={12} sm={8}>
                                <img src={hmmLogo} alt="HMM Logo" />
                            </Grid>
                            <Grid item className="login-content" xs={12} sm={8}>
                                <form
                                    className="auth-user-form"
                                    id="login-form"
                                    onSubmit={validateLogin}
                                    onKeyUp={({ key }) => {
                                        if (key === 'Enter') {
                                            handleLogin();
                                        }
                                    }}
                                    autoComplete="off"
                                >
                                    <Card login className="auth-user-card login-card">
                                        <CardBody>
                                            {
                                                state.loading && !isEmpty(state.profile) ?
                                                    renderLoadingProfile() :
                                                    renderLoginForm()
                                            }
                                        </CardBody>
                                        {!state.loading ? (
                                            <CardFooter className="login-sign-in">
                                                <ReCAPTCHA sitekey={keyCaptcha} hl={state.lang} onChange={onVerify} />
                                                <Button
                                                    id="button-signin"
                                                    onClick={validateLogin}
                                                    className="submit-button"
                                                    variant="contained"
                                                    disabled={state.disableInteractions}
                                                >{t('login.signin')}</Button>

                                                <div>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                tabIndex={-1}
                                                                className="login-remember-me"
                                                                checked={state.rememberMe}
                                                                onChange={(event) => handleValue(event.target.checked, 'rememberMe')}
                                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                                icon={<Check className={classes.uncheckedIcon} />}
                                                                classes={{
                                                                    checked: classes.checked,
                                                                    root: classes.checkRoot
                                                                }}
                                                            />
                                                        }
                                                        classes={{ label: classes.label, root: 'check-label' }}
                                                        label={t('login.remember_me')}
                                                    />
                                                </div>
                                                <div className="link-content">
                                                    <Button
                                                        id="button-pass"
                                                        simple
                                                        className="link-button"
                                                        onClick={goToForgotPass}
                                                    >{t('login.forgotPass')}</Button>
                                                </div>
                                            </CardFooter>
                                        ) : null}
                                    </Card>
                                </form>
                            </Grid>
                        </Grid>
                    </div>
                    <a className="pwd-by-link" href="https://glin-technologies.com/" rel="noopener noreferrer" target="_blank" title="Glin">
                        <img src={pwdBy} alt="Glin" />
                    </a>
                    {termsUse.showTerms && <TermsAndConditionsModal
                        userId={state.profile.userId}
                        open={termsUse.showTerms}
                        version={state.profile.requestTermsVersion}
                        requiereTermsAccept={termsUse.requiereTermsAccept}
                        onCloseTerms={(data) => handleCloseTerms(data)}
                    />}
                </div>
            }
        </>
    )
}

Login.propTypes = {
    classes: PropTypes.object.isRequired,
    t: PropTypes.func,
}

export default withStyles(styles)(withTranslation()(Login));
