import { AlertWarning, SnackbarColor, SnackbarProps } from '../EditEmployee/EmployeeType/Fees/useFees';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { GetTermsAndConditionsConnectionError, GetTermsAndConditionsError, GetTermsAndConditionsNotFoundError, GetTermsAndConditionsUsecase, Term, UpdateUserTermsBadRequestError, UpdateUserTermsConnectionError, UpdateUserTermsError, UpdateUserTermsNotFoundError, UpdateUserTermsOperationalError, UpdateUserTermsUsecase } from '../../core';
import React, { useEffect, useState } from 'react';
import { initialValueAlert, initialValueSnackbar } from '../EditEmployee/EmployeeType/Fees/data';

import Button from '../CustomButtons/Button';
import ButtonSpinner from '../ButtonSpinner';
import CustomDialog from '../CustomDialog';
import CustomSweetAlert from '../CustomSweetAlert';
import { DIContainer } from '../../Root';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import PropTypes from 'prop-types';
import Snackbar from '../Snackbar/Snackbar';
import { useTranslation } from 'react-i18next';

type onCloseTermsParams = {
  acceptTerms?: boolean;
  error?: {
    message: string;
    isError: boolean;
  };
}

type ComponentsProps = {
  userId: number;
  open: boolean;
  version: string;
  requiereTermsAccept: boolean;
  onCloseTerms: ({ acceptTerms, error }: onCloseTermsParams) => void;
  onCloseModal?: () => void;
}

const useStyles = makeStyles({
  loading: {
    textAlign: 'center',
    padding: 100,
  },
  textTerms: {
    minHeight: 100,
  },
  contentTerms: {
    maxHeight: 'calc(100vh - 250px)',
    minHeight: 50,
    overflow: 'auto',
    '& h5': {
      fontWeight: 500,
      fontSize: '1.12rem',
      textAlign: 'center',
    },
    '& h6': { marginTop: 15 },
    '& a': {
      color: '#3C4858',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    '& ul': {
      margin: 0,
      '& li': {
        marginTop: 3,
      }
    },
  },
  submitTerms: {
    padding: '30px 15px 15px !important',
    textAlign: 'right',
    '& .disabled': {
      opacity: 0.5,
      cursor: 'default',
    }
  },
  alertWarning: { marginTop: '-200px !important' },
});

const initialTerms = {
  id: 0,
  companyId: 0,
  content: '',
  version: '0',
}

const TermsAndConditionsModal = ({ userId, open, version, requiereTermsAccept, onCloseTerms, onCloseModal }: ComponentsProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<SnackbarProps>(initialValueSnackbar);
  const [alertWarning, setAlertWarning] = useState<AlertWarning>(initialValueAlert);
  const [terms, setTerms] = useState<Term>(initialTerms);
  const [busy, setBusy] = useState<boolean>(false);

  const getUsecase = DIContainer.get<GetTermsAndConditionsUsecase>(GetTermsAndConditionsUsecase);
  const updateUsecase = DIContainer.get<UpdateUserTermsUsecase>(UpdateUserTermsUsecase);

  const openSnackbar = (color: SnackbarColor, message: string): void => {
    setSnackbar({ color, message, open: true });
    setTimeout(() => {
      setSnackbar(initialValueSnackbar);
    }, 5000);
  }

  const getDataErrors = (dataError: GetTermsAndConditionsError): void => {
    let message = t('termsUse.errorServer');
    if (dataError instanceof GetTermsAndConditionsNotFoundError) {
      message = t('termsUse.notFoundMessage');
    }
    if (dataError instanceof GetTermsAndConditionsConnectionError) {
      message = t('confirm.error');
    }
    onCloseTerms({ error: { isError: true, message } });
  }

  const getTermsAndConditionsUsecase = async (): Promise<void> => {
    setLoading(true);
    const result = await getUsecase.execute(version);
    if (result.isLeft()) {
      const dataError = result.getLeft();
      getDataErrors(dataError);
      setLoading(false);
      return;
    }
    const data = result.getRight();
    setTerms(data);
    setLoading(false);
  }

  useEffect(() => {
    getTermsAndConditionsUsecase();
  }, []);

  const getUpdateDataErrors = (dataError: UpdateUserTermsError): void => {
    let message = t('termsUse.errorServer');
    if (dataError instanceof UpdateUserTermsNotFoundError) {
      message = t('termsUse.notFoundMessage');
    }
    if (dataError instanceof UpdateUserTermsConnectionError) {
      message = t('confirm.error');
    }
    if (dataError instanceof UpdateUserTermsBadRequestError) {
      message = t('termsUse.badRequestMessage');
    }
    if (dataError instanceof UpdateUserTermsOperationalError) {
      message = t('termsUse.badRequestMessage');
    }
    openSnackbar(SnackbarColor.danger, message);
  }

  const updateTerms = async (isAccept: boolean): Promise<void> => {
    if (isAccept && !requiereTermsAccept) {
      return;
    }
    setBusy(true);
    const result = await updateUsecase.execute(userId, version, isAccept);
    if (result.isLeft()) {
      const dataError = result.getLeft();
      getUpdateDataErrors(dataError);
      setBusy(false);
      return;
    }
    result.getRight();
    setBusy(false);
    onCloseTerms({ acceptTerms: isAccept });
  }

  const onRejectTerms = (): void => {
    const message =  t('termsUse.rejectedWarning.message');
    const title = t('termsUse.rejectedWarning.title');
    setAlertWarning({ show: true, message, title });
  }

  const onConfirmWarning = async (): Promise<void> => {
    setAlertWarning((prev) => ({ ...prev, show: false }));
    await updateTerms(false);
    openSnackbar(SnackbarColor.danger, t('termsUse.rejectedSuccess'));
    setTimeout(() => {
      onCloseTerms({ acceptTerms: false });
    }, 1000)
  }

  const onCancelWarning = (): void => {
    setAlertWarning((prev) => ({ ...prev, show: false }));
  }

  const onClose = () => {
    if (onCloseModal) {
      onCloseModal();
      return;
    }
    openSnackbar(SnackbarColor.warning, t('termsUse.onClose'));
  }

  const titleModal = t('termsUse.title', { number: version });
  const buttonAccept = !requiereTermsAccept ? 'buttonSpinner disabled' : 'buttonSpinner';
  
  const renderTerms = (): JSX.Element => <GridContainer>
    <GridItem xs={12} className={classes.textTerms}>
      <div className={classes.contentTerms} dangerouslySetInnerHTML={{ __html: terms?.content ?? t('table.empty') }} />
    </GridItem>
    {terms?.content && <GridItem xs={12} className={classes.submitTerms}>
      <Button
        color="danger"
        onClick={() => onRejectTerms()}
        id="button-reject"
      >{t('common.reject')}</Button>
      <ButtonSpinner
        className={buttonAccept}
        loading={busy}
        color="primary"
        onClick={() => updateTerms(true)}
        disabled={busy}
        label={t('common.accept')}
        labelLoading={t('common.saving')}
        id="button-save-book"
      />
    </GridItem>}
  </GridContainer>;

  return (
    <CustomDialog
      title={titleModal}
      maxWidth="md"
      open={open}
      onClose={() => onClose()}
    >
      <>
        <Snackbar
          place="tr"
          color={snackbar.color}
          message={snackbar.message}
          open={snackbar.open}
        />
        {loading ?
          <div className={classes.loading}>
            <CircularProgress className="circular-progress" />
          </div> : renderTerms()
        }
        {alertWarning.show &&
          <CustomSweetAlert
            className={classes.alertWarning}
            type="warning"
            confirmBtnCssClass="danger"
            confirmBtnText={t('common.reject')}
            onConfirm={() => onConfirmWarning()}
            showCancel={true}
            cancelBtnText={t('appointment.new.cancel')}
            onCancel={() => onCancelWarning()}
            message={
              <>
                {alertWarning?.title && <h5>{alertWarning?.title}</h5>}
                <p>{alertWarning.message}</p>
              </>
            }
          />
        }
      </>
    </CustomDialog>
  )
}

TermsAndConditionsModal.propTypes = {
  userId: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  version: PropTypes.string,
  requiereTermsAccept: PropTypes.bool.isRequired,
  onCloseTerms: PropTypes.func,
};

export default TermsAndConditionsModal;
