import { Calendar, momentLocalizer } from 'react-big-calendar';
import BookingDetail from '../../../BookingsTransfer/BookingDetail';
import Button from '../../../CustomButtons/Button';
import CustomDialog from '../../../CustomDialog';
import EventWithIcon from '../../../EventWithIcon/EventWithIcon';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import TableDataComponent from './TableDataComponent';
import config from '../../../../config/config';
import moment from 'moment';
import useCalendarStep from './useCalendarStep';
import { useStylesGralSteps } from '../../../ReassignAppointmentsStepper/useStylesReassignAppStepper';
import { withTranslation } from 'react-i18next';

const localizer = momentLocalizer(moment);
config.updateMoment();

const CalendarStep = ({ handleChange, showSnackbarNotification, values, t, }) => {
  const classes = useStylesGralSteps();

  const {
    handleOnSelectedEvent,
    handleUndoChanges,
    eventColors,
    openDetail,
    setOpenDetail,
    updateAppointment,
    updateErrors,
    status,
  } = useCalendarStep(() => {}, handleChange, () => {}, showSnackbarNotification, values, t);

  const { bookings, bookingsError, currentEvent, events, hasChangedSchedules, loadingevents, schedulesNotAssigned, schedulesAssigned, } = values;
  console.log("asignados",schedulesAssigned)
  console.log(bookings)
  console.log(events)
  console.log(currentEvent)
  return (
    <Grid
      container
      className={classes.formStepper}
      alignItems="flex-start"
      id="calendar-view"
    >
      <Grid item xs={12}>
        <h4 className={classes.stepTitle}>{t('title.calendar')}</h4>
        <Calendar
          showMultiDayTimes={true}
          selectable
          localizer={localizer}
          events={events}
          defaultView="month"
          step={30}
          scrollToTime={new Date(1970, 1, 1, 6)}
          defaultDate={new Date()}
          onSelectEvent={event => handleOnSelectedEvent(event)}
          eventPropGetter={event => eventColors(event)}
          messages={{
            next: t('common.next'),
            previous: t('common.prev'),
            today: t('common.today'),
            month: t('common.month'),
            week: t('common.week'),
            day: t('common.day'),
            date: t('common.date'),
            time: t('common.hour'),
            noEventsInRange: t('appointment.notPractices'),
          }}
          components={{
            event: EventWithIcon,
          }}
        />

        <CustomDialog
          title={t('appointment.calendar.transfer.scheduleDialogTitle')}
          maxWidth={'sm'}
          open={openDetail}
          onClose={() => setOpenDetail(false)}
          className="reassingn-component"
        >
          <BookingDetail
            event={currentEvent}
            onCancel={() => setOpenDetail(false)}
            onUpdate={(appointment) => updateAppointment(appointment)}
            disabled={false}
            errors={updateErrors}
            status={status}
          />
        </CustomDialog>
      </Grid>
      <Grid item xs={12} className={classes.actionButton}>
        <Button
          id="button-undo"
          onClick={() => handleUndoChanges([...bookings, ...bookingsError])}
          className={classes.button}
          disabled={!hasChangedSchedules}
        >
          {t('label.undo')}
        </Button>
      </Grid>

      <Grid item xs={12}>
        <TableDataComponent
          classes={classes}
          loadingevents={loadingevents}
          data={schedulesAssigned}
          showTooltip={false}
          title={t('reassignAppointments.table.transfer_assigned')}
        />
      </Grid>

      {schedulesNotAssigned?.length ?
        <Grid item xs={12}>
          <TableDataComponent
            classes={classes}
            loadingevents={loadingevents}
            data={schedulesNotAssigned}
            showTooltip={true}
            title={t('reassignAppointments.table.schedules_not_assigned')}
          />
        </Grid>
      : <div />
    }
    </Grid>
  )
}


CalendarStep.propTypes = {
  handleChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  showSnackbarNotification: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
}

export default withTranslation()(CalendarStep);
