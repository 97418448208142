import React, { useState } from 'react';
import DateInput from '../../../DateInput';
import config from '../../../../config/config';
import moment from 'moment';

const dateServer = config.getDateToServer();
const useDateStep = (handleChange, values) => {
  const { schedulesSelectedData, } = values;

  const today = moment(new Date()).format(dateServer);

  const renderDateInput = (id, val) => (
    <DateInput
      key={id}
      className="date-schedule"
      minDate
      min={today}
      value={val}
      name={`startDate-${id}`}
      onChangeValue={(value) => handleValueDate(id, value)}
    />
  );

  const formatData = (value, d) => ({
    ...d,
    scheduleDateTimeUpdate: value,
    scheduleDateTimeInputUpdate: renderDateInput(d.scheduleId, value),
  });

  const initialSchedulesData = schedulesSelectedData.map((d) => formatData((d.scheduleDateTime), d));
  const initialSchedulesDataStep = schedulesSelectedData.map((d) => formatData((d.scheduleDateTimeUpdate ? d.scheduleDateTimeUpdate : d.scheduleDateTime), d));

  const [schedulesData, setSchedulesData] = useState(initialSchedulesDataStep);

  const handleValueDate = (id, value, bool = true) => {
    setSchedulesData((prev) => {
      const dataUpdate = [...prev];
      const idx = dataUpdate.findIndex((d) => d.scheduleId === id);
      if (idx !== -1) {
        dataUpdate[idx] = {
          ...dataUpdate[idx],
          scheduleDateTimeUpdate: value,
          scheduleDateTimeInputUpdate: renderDateInput(id, value),
        };
      }
      handleChange('schedulesSelectedData', dataUpdate);
      return dataUpdate;
    });
    handleChange('dateSchedulesHasChanged', bool);
  }

  const handleValueDates = (value, bool = true) => {
    setSchedulesData((prev) => {
      let dataUpdate = [...prev];
      dataUpdate = dataUpdate.map((d) => formatData(value, d));
      handleChange('schedulesSelectedData', dataUpdate);
      return dataUpdate;
    });
    handleChange('dateTimeSchedules', value);
    handleChange('dateSchedulesHasChanged', bool);
  };

  const handleClearDates = () => {
    setSchedulesData(initialSchedulesData);
    handleChange('schedulesSelectedData', initialSchedulesData);
    handleChange('dateTimeSchedules', '');
    handleChange('dateSchedulesHasChanged', false);
  }

  const isValid = schedulesData.filter((d) => d.scheduleDateTimeUpdate === '').length === 0;

  return {
    handleClearDates,
    handleValueDates,
    isValid,
    schedulesData,
    today,
  }
}

export default useDateStep;
