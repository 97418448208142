import { Checkbox, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';

import { Check } from '@material-ui/icons';
import Filters from './Filters';
import PropTypes from 'prop-types';
import Table from '../../../Table/Table.jsx';
import clsx from 'clsx';
import config from '../../../../config/config';
import moment from 'moment';
import styles from '../../../../assets/components/customCheckboxRadioSwitch';
import { useStylesGralSteps } from '../../../ReassignAppointmentsStepper/useStylesReassignAppStepper';
import { withTranslation } from 'react-i18next';

const format = config.getDateFormat();
const useStylesChecks = makeStyles(styles);

const AppointmentsStep = ({
  clearInformation,
  handleIsValidStep,
  isConfirmStep,
  softFilter,
  setFilters,
  onSelectAllSchedules,
  showGraphicsInfo,
  values: { chips, loadingSchedule, schedules, schedulesSelected, checkAllSchedules, startDate, endDate, practicesType },
  t,
}) => {
  const classesStyle = useStylesGralSteps();
  const classesChecks = useStylesChecks();
  const isValid = schedulesSelected.length;

  useEffect(() => {
    handleIsValidStep(isValid);
  }, [handleIsValidStep, isValid]);

  const sort = (a, b) => {
    const a1 = moment(a, format).valueOf();
    const b1 = moment(b, format).valueOf();
    if(a1 > b1) {
      return 1;
    } 
    if (a1 < b1) {
      return -1;
    }
    return 0;
  }

  return (
    <div className={clsx(classesStyle.formStepper, {[classesStyle.readOnlyForm]: isConfirmStep})}>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Filters
            loading={loadingSchedule}
            chips={chips}
            onChangeChips={(filters) => softFilter(filters)}
            onChange={(filters) => setFilters(filters)}
            onClearFilters={clearInformation}
            filterData={{ startDate, endDate, practicesType }}
          />
        </Grid>
        <Grid item xs={12} className={classesStyle.spacingTop4}>
          <Table
            loading={loadingSchedule}
            tableHeaderColor="primary"
            sortable={true}
            filterable
            tableHead={[
              { Header:
                <div className="tooltypes-actions">
                  <Checkbox
                    tabIndex={-1}
                    id="all_schedule"
                    checked={checkAllSchedules}
                    onClick={(e) => onSelectAllSchedules(e)}
                    checkedIcon={<Check className={classesChecks.checkedIcon} />}
                    icon={<Check className={classesChecks.uncheckedIcon} />}
                    classes={{
                        checked: classesChecks.checked,
                        root: classesChecks.checkRoot,
                    }}
                  />
                </div>, sortable: false, filterable: false, accessor: 'selector', width: 60,
              },
              { Header: t('reassignAppointments.table.transferId'), accessor: 'scheduleId' },
              { Header: t('status'), accessor: 'scheduleStatus' },
              { Header: t('reassignAppointments.table.appointmentId'), accessor: 'appointmentId' },
              { Header: t('reassignAppointments.table.practiceTypeName'), accessor: 'practiceTypeName' },
              { Header: t('reassignAppointments.table.customer'), accessor: 'customer' },
              { Header: t('label.driver'), accessor: 'professional' },
              { Header: t('common.date'), accessor: 'scheduleDate', sortMethod: (a, b) => sort(a, b)},
              { Header: t('label.hour'), accessor: 'scheduleTime' },
              { Header: t('reassignAppointments.table.province'), accessor: 'province' },
              { Header: t('reassignAppointments.table.location'), accessor: 'location' },
              { Header: t('reassignAppointments.table.geographicZone'), accessor: 'geographicZone' },
            ]}
            tableData={schedules}
            colorsColls={['primary']}
            onRowClick={(_, __, rowInfo) => showGraphicsInfo(rowInfo)}
            defaultPageSize={schedules && schedules.length > 5 ? schedules.length : 5}
            showPaginationTop={false}
            showPaginationBottom={false}
          />
        </Grid>
      </Grid>
    </div>
  )
}

AppointmentsStep.propTypes = {
  t: PropTypes.func,
  clearInformation: PropTypes.func,
  handleIsValidStep: PropTypes.func,
  isConfirmStep: PropTypes.bool,
  softFilter: PropTypes.func,
  setFilters: PropTypes.func,
  onSelectAllSchedules: PropTypes.func,
  showGraphicsInfo: PropTypes.func,
  values: PropTypes.object,
}

export default withTranslation()(AppointmentsStep);
