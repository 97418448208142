import { ApiErrorParser, CoreException, Either } from "../../../sdk";

import { ApiAppointmentsFromMappper } from "./api-appointments.from-mapper";
import { ApiAppointmentsItemsFromMap } from "./api-appointments-items.from-map";
import AppointmentApiInvoker from "../../../api/AppointmentApiInvoker";
import { AppointmentItem } from "../domain";
import { AppointmentsRepository } from "../application";
import { injectable } from "inversify";

const mapper = new ApiAppointmentsFromMappper();
const errorParser = new ApiErrorParser();

@injectable()
export class ApiAppointmentsRepository implements AppointmentsRepository {
  async getAppointmentItems(id: number): Promise<Either<CoreException, AppointmentItem[]>> {
    return new Promise((resolve, _) => {
      let result: Either<CoreException, AppointmentItem[]>;
      AppointmentApiInvoker.getAppointmentItemsPracticeModule(id, (res: ApiAppointmentsItemsFromMap[]) => {
        const data = res.map((appointment: ApiAppointmentsItemsFromMap) => mapper.fromMap(appointment));
        result = Either.right(data);
        resolve(result);
      }, (error: CoreException) => {
        const err = errorParser.parse(error);
        result = Either.left(err);
        resolve(result);
      });
    })
  }

}
