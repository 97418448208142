import { AlertWarning, SnackbarColor } from './useFees';
import React, { useEffect, useState } from 'react';
import { ScheduleFeeRequest, UpdateScheduleFeeConnectionError, UpdateScheduleFeeError, UpdateScheduleFeeUsecase } from '../../../../core';
import { SnackbarProps, makeStyles } from '@material-ui/core';
import { initialValueAlert, initialValueSnackbar } from './data';

import ButtonSpinner from '../../../ButtonSpinner';
import CustomInput from '../../../CustomInput/CustomInput';
import { DIContainer } from '../../../../Root';
import FeesWarningDialog from './FeesWarningDialog';
import GridContainer from '../../../Grid/GridContainer';
import GridItem from '../../../Grid/GridItem';
import PropTypes from 'prop-types';
import Snackbar from '../../../Snackbar/Snackbar';
import { useTranslation } from 'react-i18next';

type ComponentProps = {
  selectedSchedules: number[];
  feeAmount?: number;
  scheduleAmount?: number;
  onUpdateAmount: (data: ScheduleFeeRequest[]) => void;
}

const useStyles = makeStyles({
  formFee: {
    minHeight: 200,
    justifyContent: 'space-between',
  },
});

const FeeAmountForm = (props: ComponentProps) => {
  const { t } = useTranslation();
  const { feeAmount, scheduleAmount, selectedSchedules } = props;

  const updateUsecase = DIContainer.get<UpdateScheduleFeeUsecase>(UpdateScheduleFeeUsecase);

  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState<SnackbarProps>(initialValueSnackbar);
  const [feeValue, setFeeValue] = useState<number | undefined>(undefined);
  const [isSave, setIsSave] = useState(false);
  const [alertWarning, setAlertWarning] = useState<AlertWarning>(initialValueAlert);

  const classes = useStyles();

  const onEditState = () => {
    setFeeValue((scheduleAmount ?? feeAmount) ?? undefined);
  }
  
  useEffect(() => {
    if (feeAmount || scheduleAmount) {
      setLoading(true);
      onEditState();
      setLoading(false);
    }
    return () => {
      setLoading(false);
      setIsSave(false);
    }
  }, [])

  const openSnackbar = (color: SnackbarColor, message: string): void => {
    setSnackbar({ color, message, open: true });
    setTimeout(() => {
      setSnackbar(initialValueSnackbar);
    }, 5000);
  }
  
  const getUpdateDataErrors = (dataError: UpdateScheduleFeeError): void => {
    let message = t('common.errorService');
    if (dataError instanceof UpdateScheduleFeeConnectionError) {
      message = t('confirm.error');
    }
    openSnackbar(SnackbarColor.danger, message);
  }

  const onUpdateAmount = async (data: ScheduleFeeRequest[]): Promise<void> => {
    setLoading(true);
    const result = await updateUsecase.execute(data);

    if (result.isLeft()) {
      const dataError = result.getLeft();
      getUpdateDataErrors(dataError);
      setLoading(false);
      setIsSave(false);
      return;
    }
    result.getRight();
    props.onUpdateAmount(data);
    setLoading(false);
  }

  const saveFee = async (): Promise<void> => {
    onCloseAlert();
    const data = selectedSchedules.map(sch => ({ scheduleId: sch, totalAmount: feeValue }));
    await onUpdateAmount(data);
  }

  const onSaveEdit = () => {
    if (!feeValue) {
      return openSnackbar(SnackbarColor.warning, t('common.messageWarning.fieldsComplete'));
    }
    setIsSave(true);
    if (feeValue < 0) {
      return openSnackbar(SnackbarColor.danger, t('fees.validAmount'));
    }
    let message = t('fees.edit.message', { scheduleId: selectedSchedules[0] });
    if (selectedSchedules.length > 1) {
      message = t('fees.edit.inBulk.message');
    }
    setAlertWarning({ message, show: true });
  }

  const onCloseAlert = () => {
    setAlertWarning(initialValueAlert);
    setIsSave(false);
  }
  const getCurrentLabel = (): string => {
    const value = selectedSchedules.length > 1 ? feeAmount?.toFixed(2) : scheduleAmount?.toFixed(2);
    return `${t('fees.currentLabel')} $ ${value}`;
  }

  return (
    <>
      <Snackbar
        place="tr"
        color={snackbar.color}
        message={snackbar.message}
        open={snackbar.open}
      />
      <GridContainer className={classes.formFee} direction="column">
        <GridItem xs={12}>
          <p>{getCurrentLabel()}</p>
        </GridItem>

          <GridItem xs={12} sm={6} className="input-fee-item">
            <CustomInput
              labelText={`${t('fees.newLabel')} $ *`}
              formControlProps={{
                fullWidth: true
              }}
              value={feeValue}
              onChange={(value: any) => setFeeValue(Number(value.target.value))}
              errorText={t('error.required')}
              id="input-fee"
              type="number"
              error={(!feeValue || feeValue < 0) && isSave}
            />
          </GridItem>
        
        <GridItem xs={12}>
          <ButtonSpinner
              className="create-financier-submit"
              onClick={() => onSaveEdit()}
              disabled={loading}
              label={t('fees.save')}
              labelLoading={t('fees.saving')}
              loading={loading}
              typeButton="submit"
              color="primary"
              id="button-save"
          />
        </GridItem>
      </GridContainer>
      <FeesWarningDialog
        onConfirm={() => saveFee()}
        onCancel={() => onCloseAlert()}
        alertWarning={alertWarning}
      />
    </>
  )
}

FeeAmountForm.propTypes = {
  selectedSchedules: PropTypes.arrayOf(PropTypes.number),
  feeAmount: PropTypes.number,
  scheduleAmount: PropTypes.number,
  onUpdateAmount: PropTypes.func,
}

export default FeeAmountForm;
