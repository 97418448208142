import { AuthorizationException, BadRequestException, ConnectionException, CoreException, Either, NotFoundException, OperationalException } from "../../../../sdk";
import { CustomerFinancier, CustomerFinancierServer } from "../../domain";
import { inject, injectable } from "inversify";

import { FinanciersRepository } from "../repositories";

@injectable()
export class CreateCustomerFinancierUsecase {
  @inject(FinanciersRepository) protected repository!: FinanciersRepository;

  async execute(body: CustomerFinancierServer[]): Promise<Either<CreateCustomerFinancierError, CustomerFinancier[]>> {
    const result = await this.repository.createCustomerFinancier(body);
    if (!body) {
      return Either.left(new CreateCustomerFinancierInvalidDataError());
    }
    if (result.isLeft()) {
      const error = result.getLeft();
      return Either.left(this._getErrroFromException(error));
    }
    return result;
  }

  private _getErrroFromException(exception: CoreException): CreateCustomerFinancierError {
    if (exception instanceof NotFoundException) {
      return new CreateCustomerFinancierNotFoundError();
    }
    if (exception instanceof ConnectionException) {
      return new CreateCustomerFinancierConnectionError();
    }
    if (exception instanceof OperationalException) {
      return new CreateCustomerFinancierOperationalError();
    }
    if (exception instanceof AuthorizationException) {
      return new CreateCustomerFinancierAuthorizationError();
    }
    if (exception instanceof BadRequestException) {
      return new CreateCustomerFinancierBadRequestError(exception.error);
    }

    throw new Error("Unexpected error in CreateCustomerFinancierUsecase");
  }
}

export abstract class CreateCustomerFinancierError {
  error?: string;
}

export class CreateCustomerFinancierInvalidDataError extends CreateCustomerFinancierError { }
export class CreateCustomerFinancierNotFoundError extends CreateCustomerFinancierError { }
export class CreateCustomerFinancierConnectionError extends CreateCustomerFinancierError { }
export class CreateCustomerFinancierOperationalError extends CreateCustomerFinancierError { }
export class CreateCustomerFinancierAuthorizationError extends CreateCustomerFinancierError { }
export class CreateCustomerFinancierBadRequestError extends CreateCustomerFinancierError {
  constructor(public readonly error: string) {
    super();
  }
}
