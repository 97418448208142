import { SnackbarColor } from "./useFees"

export const initialValueAlert = {
  message: '',
  show: false,
}

export const initialValueSnackbar = {
  color: SnackbarColor.inherit,
  message: '',
  open: false,
}
