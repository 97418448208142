import React, { useState } from 'react';
import TimePickerGCalendar from '../../../TimePickerGCalendar';
import PropTypes from 'prop-types';

const useTimeStepTransfer = (handleChange, values) => {
  const { schedulesSelectedData, } = values;

  const renderTimeInput = (id, value) => (
    <TimePickerGCalendar
      key={id}
      text={''}
      value={value}
      name={`startTime-${id}`}
      onSelectedValue={(v) => handleValueTime(id, v)}
    />
  );

  const formatData = (value, d) => ({
    ...d,
    scheduleTimeUpdate: value,
    scheduleTimeInputUpdate: renderTimeInput(d.scheduleId, value),
  });

  const initialSchedulesData = schedulesSelectedData.map((d) => formatData(d.scheduleTime, d));
  const initialSchedulesDataState = schedulesSelectedData.map((d) => formatData(d.scheduleTimeUpdate ? d.scheduleTimeUpdate : d.scheduleTime, d));
  
  const [schedulesData, setSchedulesData] = useState(initialSchedulesDataState);

  const handleValueTimes = (value, bool = true) => {
    handleChange('timeSchedulesHasChanged', bool);
    const valueFormat = value === '0' ? '00:00' : value;
    setSchedulesData((prev) => {
      let dataUpdate = [...prev];
      dataUpdate = dataUpdate.map((d) => formatData(valueFormat, d));
      handleChange('schedulesSelectedData', dataUpdate);
      return dataUpdate;
    });
    handleChange('timeSchedules', valueFormat);
  };

  const handleValueTime = (id, value, bool = true) => {
    handleChange('timeSchedulesHasChanged', bool);
    const valueFormat = value === '0' ? '00:00' : value;
    setSchedulesData((prev) => {
      const dataUpdate = [...prev];
      const idx = dataUpdate.findIndex((d) => d.scheduleId === id);
      if (idx !== -1) {
        dataUpdate[idx] = {
          ...dataUpdate[idx],
          scheduleTimeUpdate: valueFormat,
          scheduleTimeInputUpdate: renderTimeInput(id, valueFormat),
        };
      }
      handleChange('schedulesSelectedData', dataUpdate);
      return dataUpdate;
    });
  }

  const handleClearTimes = () => {
    setSchedulesData(initialSchedulesData);
    handleChange('schedulesSelectedData', initialSchedulesData);
    handleChange('timeSchedules', '');
    handleChange('timeSchedulesHasChanged', false);
  }

  const isValid = schedulesData.filter((d) => d.scheduleTimeUpdate === '').length === 0;

  return {
    handleClearTimes,
    handleValueTimes,
    isValid,
    schedulesData,
  }
}

useTimeStepTransfer.propTypes = {
  handleChange: PropTypes.func,
  values: PropTypes.object,
}

export default useTimeStepTransfer;
