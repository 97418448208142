import React from 'react'
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& > *': {
      width: '100%',
      fontSize: 30,
      fontFamily: 'Roboto',
    },
  },
  letterAvatar: {
    width: 80,
    height: 80,
    background: 'linear-gradient(109.06deg, #5CC4B9 -4.55%, #FFC44C 102.69%)',
  },
}))

const LetterAvatar = ({ user }) => {
  const classes = useStyles();

  const generateInitials = name => {
    let result = '';
    if (name) {
      const initials = name.trim().split(' ');
      if (initials.length > 1) {
        result = `${initials[0][0].toUpperCase()}${initials[1][0].toUpperCase()}`;
      } else {
        result = initials[0][0].toUpperCase();
      }
    }

    return result;
  }

  return (
    <div className={classes.root}>
      <Avatar className={classes.letterAvatar}>
        {generateInitials(user)}
      </Avatar>
    </div>
  )
}

LetterAvatar.propTypes = {
  user: PropTypes.string.isRequired,
}

export default LetterAvatar;
