import { Check, ViewList } from '@material-ui/icons';
import {
  Checkbox,
  Step,
  StepLabel,
  Stepper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { filtersChips, initialNotification, initialValues, steps as stepsData } from '../ReassignAppointmentsStepper/data';

import AppointmentApiInvoker from '../../api/AppointmentApiInvoker';
import AppointmentsStep from './components/AppointmentsStep';
import ButtonsSteps from './components/ButtonsSteps';
import CalendarStep from './components/CalendarStep';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardHeader from '../Card/CardHeader';
import CardIcon from '../Card/CardIcon';
import DateStep from './components/DateStep';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import NotificationState from '../NotificationState';
import PersonApiInvoker from '../../api/PersonApiInvoker';
import ProfessionalsStep from './components/ProfessionalsStep';
import PropTypes from 'prop-types';
import RequestChangesStep from './components/RequestChangesStep';
import TimeStep from './components/TimeStep';
import config from '../../config/config';
import customCheckboxRadioSwitch from '../../assets/components/customCheckboxRadioSwitch';
import moment from 'moment';
import useStateRef from 'react-usestateref';
import { useStylesReassignAppStepper } from '../ReassignAppointmentsStepper/useStylesReassignAppStepper';
import { withTranslation } from 'react-i18next';


const dateToServerConf = config.getDateToServer();

const useStylesChecks = makeStyles(customCheckboxRadioSwitch);

const ReassignAppointmentsStepper = (props) => {
  const { t } = props;
  const classes = useStylesReassignAppStepper();
  const classesChecks = useStylesChecks();

  const [activeStep, setActiveStep] = useState(0);
  const [itemsSteps, setItemsSteps] = useState(stepsData);
  const [stepsValues, setStepsValues, refStepsValues] = useStateRef(initialValues);
  const [skipped, setSkipped] = useState(new Set());
  const [notification, setNotification] = useState(initialNotification);
  const [isNextStep, setIsNextStep] = useState(false);
  const [checkAllSchedules, setCheckAllSchedules] = useState(false);
  const [isValidStep, setIsValidStep] = useState(false);

  const showSnackbarNotification = (message, color = 'warning') => { // steps
    setNotification((prev) => ({
      ...prev,
      color,
      message,
      open: true,
    }));

    setTimeout(() => {
      setNotification((prev) => ({ ...prev, open: false }));
      setTimeout(() => {
        setNotification(initialNotification);
      }, 50);
    }, 6000);
  }

  const handleSkipStep = () => setActiveStep((prev) => prev + 1); // buttons
  
  const handleBackSelect = () => setActiveStep((prev) => prev - 1); // buttons
  
  const isStepSkipped = (step) => skipped.has(step);

  const handleNext = () => { // buttons
    setIsNextStep(true);
    // error
    if (!isValidStep) {
      showSnackbarNotification(t('common.messageWarning.fieldsComplete'));
      return;
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (isValidStep) setItemsSteps((prevSteps) => {
      const updatedSteps = [...prevSteps];
      let completed = true;
      updatedSteps[activeStep] = {...updatedSteps[activeStep], completed };
      return updatedSteps;
    });
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
    window.scrollTo(0, 0);
  };

  const handleChange = (name, value, isValid = true) => { // steps
    setStepsValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    setIsValidStep(isValid);
  };

  const handleIsValidStep = (value) => setIsValidStep(value); // steps

  const isConfirmStep = activeStep === itemsSteps.length - 1; // steps

  const softFilter = (filters) => { // step 0
    setStepsValues((prev) => ({
      ...prev,
      filters: filtersChips,
    }));

    if (filters.chips.length) {
      filters.chips.forEach(e => {
        setStepsValues((prev) => ({
          ...prev,
          filters: {
            ...prev.filters,
            [e.code]: e.value,
          }
        }));
      });
    }
  }

  const onSelectAllSchedules = (value) => { // step 0
    setCheckAllSchedules(value.target.checked);
    let schedSelected = [];
    if (value.target.checked) {
      schedSelected = stepsValues.schedules.map(schedule => schedule.scheduleId);
    }
    formatTableData(stepsValues.originalschedule, schedSelected);  
  }

  const onSelectSchedule = (id) => { // step 0
    const isProfessionals = refStepsValues.current.professionalsToReassign.length;
    isProfessionals && handleChange('professionalsToReassign', []);
    setCheckAllSchedules(false);
    const selected = refStepsValues.current.schedulesSelected;
    if (selected.includes(id)) {
      const index = selected.indexOf(id);
      if (index !== -1) selected.splice(index, 1);
    } else {
      selected.push(id);
    }
    formatTableData(refStepsValues.current.originalschedule, selected);
  }

  const formatTableData = (data, selectedSched) => { // step 0
    const result = data
      .filter(f => f.schedule.transferScheduleStatus === 'PENDING_APPROVAL' || f.schedule.transferScheduleStatus === 'REJECTED' || f.schedule.transferScheduleStatus === 'APPROVED')
      .map(d => {
        const isChecked = selectedSched.includes(d.schedule.transferScheduleId);
        const action = (
          <Checkbox
            id={`checkbox-${d.schedule.transferScheduleId}`}
            tabIndex={-1}
            checked={isChecked}
            indeterminate={false}
            onClick={() => onSelectSchedule(d.schedule.transferScheduleId)}
            checkedIcon={<Check className={classesChecks.checkedIcon} />}
            icon={<Check className={classesChecks.uncheckedIcon} />}
            classes={{
              checked: classesChecks.checked,
              root: classesChecks.checkRoot,
            }}
          />
        );
        return {
          scheduleId:d.schedule.transferScheduleId ,
          appointmentId:d.schedule.transferItemRequestId.transferItemRequestId ,
          scheduleDateTime: d.schedule.scheduleDateTime,
          scheduleDate: moment(d.schedule.startDateTime).format('DD/MM/YYYY'),
          scheduleTime:  moment(d.schedule.startDateTime).format('HH:mm'),
          scheduleStatus: t(`status.${d.schedule.transferScheduleStatus}.transfer`),
          practiceTypeName: "Traslado",
          professional: `${d.schedule.driver.lastName} ${d.schedule.driver.firstName}`,
          customer: `${d.customerLastName} ${d.customerFirstName}`,
          province: d.address.province || d.address.provinceId,
          location: d.address.location.name,
          geographicZone: d.address?.geographicZone?.detail ?? '',
          selector: action,
          checkedSchedule: isChecked,
        };
    });

    setStepsValues((prevState) => ({
      ...prevState,
      schedulesSelected: selectedSched,
      schedules: result,
      originalquery: result,
      originalschedule: data,
      loadingTable: false,
    }), () => softFilter(stepsValues));
  }

  const getSchedulesReasign = (steps, startDate, endDate) => {
    const { appointmentId, customerId, dayOfWeek, employeeId, practiceTypeId, startTime, evenDay } = steps.filters;
    let evenDayValue;
    if (evenDay === 1) {
      evenDayValue = true;
    }
    if (evenDay === 2) {
      evenDayValue = false;
    }

    const employeeIdQuery = employeeId ? `&employeeId=${employeeId}` : '';
    const appointmentIdQuery = appointmentId ? `&appointmentId=${appointmentId}` : '';
    const practiceTypeIdQuery = practiceTypeId ? `&practiceTypeId=${practiceTypeId}` : '';
    const customerIdQuery = customerId ? `&customerId=${customerId}` : '';
    const startTimeQuery = startTime ? `&start-time=${startTime}` : '';
    const evenDayQuery = evenDay !== null ? `&even-day=${evenDayValue}` : '';
    const dayOfWeekQuery = dayOfWeek ? `&day-of-week=${dayOfWeek}` : '';
    let params = {
      startDate,
      endDate,
      employeeId: employeeIdQuery.split('=')[1],
      appointmentId: appointmentIdQuery.split('=')[1],
      practiceTypeId: practiceTypeIdQuery.split('=')[1],
      customerId: customerIdQuery.split('=')[1],
      startTime: startTimeQuery.split('=')[1] ? startTimeQuery.split('=')[1]+':00' : '' ,
      evenDay: evenDayQuery.split('=')[1],
      dayOfWeek: dayOfWeekQuery.split('=')[1],
    }
    
    AppointmentApiInvoker.getSchedulesToReasing(params, (data) => {
      data=data.filter(item => item.schedule.transferScheduleStatus !== 'REJECTED');

      const filteredAppointments = filterAppointments(data, params);

      formatTableData(filteredAppointments, []);
      setStepsValues((prev) => ({ ...prev, loadingSchedule: false }));
      }, (error) => {
        const { message, statusText } = error;
        const notificationMessage = typeof message === "string" ? message : statusText;
        showSnackbarNotification(notificationMessage);
        setStepsValues((prev) => ({ ...prev, loadingSchedule: false }));
      }
    );
  }
  const startOfDay = (date)=> {
    return new Date(date.setHours(0, 0, 0, 0));
  }

  const filterAppointments = (appointments, filterParams) => {
    console.log("filtros", filterParams)
    
    return appointments.filter(appointment => {
      const {
        startDate,
        endDate,
        employeeId,
        appointmentId,
        practiceTypeId,
        customerId,
        startTime,
        evenDay,
        dayOfWeek
      } = filterParams;

      if (startDate && new Date(appointment.schedule.startDateTime) <= new Date(startDate + "T00:00:00")) {
        return false;
      }
      if (endDate && new Date(appointment.schedule.startDateTime) >= new Date(endDate + "T24:00:00")) {
        return false;
      }
      if (employeeId && appointment.schedule.driver.personId !== parseInt(employeeId)) {
        return false;
      }
      if (appointmentId && appointment.schedule.transferItemRequestId.transferItemRequestId !== parseInt(appointmentId)) {
        return false;
      }
      if (practiceTypeId && appointment.schedule.transferItemRequestId.transferType !== practiceTypeId) {
        return false;
      }
      if (customerId && appointment.schedule.address.personId !== parseInt(customerId)) {
        return false;
      }
      if (startTime && appointment.schedule.transferItemRequestId.startTime !== startTime) {
        return false;
      }
      if (evenDay && (new Date(appointment.schedule.startDateTime).getDate() % 2 === 0) !== Boolean(evenDay)) {
        return false;
      }
      if (dayOfWeek && new Date(appointment.schedule.startDateTime).getDay() !== parseInt(dayOfWeek)) {
        return false;
      }
      return true;
    });
  };

  const setFilters = (filters) => { // step 0
    setCheckAllSchedules(false);
    const startReassign = moment(filters.startDate).format(dateToServerConf);
    const endReassign = moment(filters.endDate).format(dateToServerConf);
    if (filters.startDate.length > 0 && filters.endDate.length > 0) {
      setStepsValues((prev) =>({
        ...prev,
        startDate: startReassign,
        endDate: endReassign,
        dateTimeSchedules: '',
        timeSchedules: '',
        loadingSchedule: true,
        geographicZone: filters.geographicZone,
        professionalsToReassign: [],
        originalProfessionalsToReassign: [],
        events: [],
        schedules: [],
        schedulesSelected: [],
        filters: {
          ...prev.filters,
        }
      }));

      if (filters.geographicZone && !filters.geographicZone.geographicZoneId) {
        PersonApiInvoker.getEmployeeGeographics(
          stepsValues.employeeId,
          data => {
            if (data.length > 0) {
              setStepsValues((prev) => ({ ...prev, geographicZone: data[0] }));
            }
          }, (error) => console.error('** error getEmployeeGeographics:', error)
        )
      }
      getSchedulesReasign(stepsValues, startReassign, endReassign);
    }
  }

  const showGraphicsInfo = (rowInfo) => { // step 0
    if (rowInfo?.original?.employeeId) {
      setStepsValues((prev) => ({
        ...prev,
        graphics: true,
        employeeId: rowInfo.original.employeeId,
      }), () => {
        setStepsValues((prev) => ({ ...prev, graphics: false }));
      });
    }
  }

  const clearInformation = () => { // All Steps
    setStepsValues({
      ...initialValues,
      practicesType: stepsValues.practicesType,
      chips: [],
      dateTimeSchedules: '',
      timeSchedules: '',
      filters: filtersChips
    });
    setCheckAllSchedules(false);
    setActiveStep(0);
    setItemsSteps(stepsData);
    window.scrollTo(0, 0);
  }

  const removeCompletedStep = (step) => {
    setItemsSteps((prev) => {
      const dataUpdate = [...prev];
      const idx = dataUpdate.findIndex((d) => d.number === step);
      if (idx !== -1) {
        dataUpdate[idx] = {
          ...dataUpdate[idx],
          completed: false,
        };
      }
      return dataUpdate;
    });
  }

  const formatPracticesType = (practiceType) => practiceType.map(e => ({
    id: e.transferScheduleId,
    value: e.name,
  }));

  const getPracticesType = () => {
    AppointmentApiInvoker.getSchedulesToReasing(data => {
      console.log("schedules",data)
      if (data?.length) {
        const dataFormat = formatPracticesType(data);
        setStepsValues((prev) => ({
          ...prev,
          practicesType: dataFormat,
        }));
      }
    }, (error) => console.error('** error getPracticeTypes', error));
  }

  const renderItemsSteps = () => itemsSteps.map((step) => {
    if (step.isOpcional) {
      step.optional = <Typography className={classes.optionalCaption} variant="caption" align="center">{t('label.optional')}</Typography>;
    }
    const labelProps = {
      optional: step.optional,
    }
    const stepProps = {
      completed: step.completed,
    }
    return (
      <Step id={`step-${step.number + 1}`} key={step.number} {...stepProps}>
        <StepLabel {...labelProps}>{t(step.label)}</StepLabel>
      </Step>
    );
  });

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <AppointmentsStep
            handleChange={handleChange}
            handleIsValidStep={handleIsValidStep}
            isConfirmStep={isConfirmStep}
            values={{...stepsValues, checkAllSchedules}}
            softFilter={softFilter}
            setFilters={setFilters}
            showGraphicsInfo={showGraphicsInfo}
            clearInformation={clearInformation}
            onSelectAllSchedules={onSelectAllSchedules}
          />
        );
      case 1:
        return (
          <ProfessionalsStep
            handleChange={handleChange}
            handleIsValidStep={handleIsValidStep}
            isConfirmStep={isConfirmStep}
            isNextStep={isNextStep}
            values={stepsValues}
            refStepsValues={refStepsValues}
            onSelectAllSchedules={onSelectAllSchedules}
            showSnackbarNotification={showSnackbarNotification}
          />
        );
      case 2:
        return (
          <DateStep
            handleChange={handleChange}
            handleIsValidStep={handleIsValidStep}
            values={stepsValues}
            refStepsValues={refStepsValues}
          />
        );
      case 3:
        return (
          <TimeStep
            handleChange={handleChange}
            handleIsValidStep={handleIsValidStep}
            values={stepsValues}
            refStepsValues={refStepsValues}
          />
        );
      case 4:
        return (
          <RequestChangesStep
            isConfirmStep={isConfirmStep}
            values={stepsValues}
          />
        );
      case 5:
        return (
          <CalendarStep
            handleChange={handleChange}
            showSnackbarNotification={showSnackbarNotification}
            values={stepsValues}
          />
        );
      default:
        break;
    }
  };

  useEffect(() => {
    clearInformation();
    getPracticesType();
  }, []);

  const stickyStepper = useRef();

  useLayoutEffect(() => {
    const contentStepper = document.getElementById('contentStepper');
    const tabsStepper = document.getElementById('tabsStepper');
    const fixedTop = stickyStepper.current.offsetTop;

    const fixedHeader = () => {
      if (window.scrollY > 150 && window.scrollY > fixedTop) {
        contentStepper.classList.add('stepsFixed');
        tabsStepper.classList.add('fixedTop');
      } else {
        contentStepper.classList.remove('stepsFixed');
        tabsStepper.classList.remove('fixedTop');
      }
    }
    window.addEventListener('scroll', fixedHeader);
  }, [])

  return (
    <GridContainer className={classes.root}>
      <GridItem xs={12}>
        <Card>
          <CardHeader icon>
            <CardIcon color="secondary">
              <ViewList />
            </CardIcon>
            <h4 className="card-title">Reasignación de choferes, días u horarios traslado</h4>
          </CardHeader>
          <CardBody className={classes.contentStepper} id="contentStepper">
            <Stepper
              className={`${classes.stepper} tabsStepper`}
              activeStep={activeStep}
              alternativeLabel
              ref={stickyStepper}
              id="tabsStepper"
            >
              {renderItemsSteps()}
            </Stepper>

            {renderStepContent(activeStep)}

            <NotificationState notification={notification} />
          </CardBody>
        </Card>
        <ButtonsSteps
          handleBack={handleBackSelect}
          handleChange={handleChange}
          handleNext={handleNext}
          handleSkipStep={handleSkipStep}
          values={{...stepsValues, activeStep, refStepsValues}}
          steps={itemsSteps}
          showSnackbarNotification={showSnackbarNotification}
          clearInformation={clearInformation}
          removeCompletedStep={removeCompletedStep}
        />
      </GridItem>
    </GridContainer>
  );
}

ReassignAppointmentsStepper.propTypes = {
  t: PropTypes.func.isRequired,
}

export default withTranslation()(ReassignAppointmentsStepper);
