import config from '../config/config';
import moment from 'moment';
const dateToServer = config.getDateToServer();

const handlePlanRecurrenceMonth = (planRecurrence, currentDate, endDatePlusOne, every, quantity, q, e) => {
  let yesterdary;
  let newQuantity = quantity;
  let monthDay = (planRecurrence.monthRecurrence || planRecurrence.monthDay)

  while (!(moment(currentDate).isSame(endDatePlusOne, 'day'))) {
    if (e == every) {
      if (currentDate.date() == monthDay) {
        q++;
      }
    }
    yesterdary = currentDate;
    currentDate = moment(currentDate).add(1, 'days');
    if (!moment(yesterdary).isSame(currentDate, 'month')) {
      if (e == every) {
        e = 1;
      } else {
        e++;
      }
    }
  }
  newQuantity = q;
  return newQuantity;
}

const handlePlanRecurrenceDay = (planRecurrence, startDate, endDate, every, quantity) => {
  let currentDate = startDate;
  const endDatePlusOne = moment(endDate, dateToServer).add(1, 'd');
  let e = every;
  let q = 0;

  if (planRecurrence && planRecurrence.recurrenceType === 'DAY') {
    while (!(moment(currentDate).isSame(endDatePlusOne, 'day'))) {
      if (e == every) {
        q++;
        e = 1;
      } else {
        e++;
      }
      currentDate = moment(currentDate).add(1, 'days');
    }
    quantity = q;
  } else if (planRecurrence && (planRecurrence.recurrenceType === 'WEEK' || planRecurrence.recurrenceType === 'INTRADAY')) {
    let weekDays = planRecurrence.weekRecurrence || planRecurrence.weekDays;
    if (weekDays == null) {
      weekDays = '';
    }
    weekDays = typeof weekDays === 'string' ? weekDays.split(',') : weekDays;
    let weekDaysSchedulePlan = [];
    if (weekDays.includes('L')) { weekDaysSchedulePlan.push(1) }
    if (weekDays.includes('M')) { weekDaysSchedulePlan.push(2) }
    if (weekDays.includes('X')) { weekDaysSchedulePlan.push(3) }
    if (weekDays.includes('J')) { weekDaysSchedulePlan.push(4) }
    if (weekDays.includes('V')) { weekDaysSchedulePlan.push(5) }
    if (weekDays.includes('S')) { weekDaysSchedulePlan.push(6) }
    if (weekDays.includes('D')) { weekDaysSchedulePlan.push(0) }

    let yesterdary;

    while (!(moment(currentDate).isSame(endDatePlusOne, 'day'))) {
      if (e == every) {
        if (weekDaysSchedulePlan.includes(currentDate.day())) {
          if (planRecurrence.recurrenceType === 'INTRADAY') {
            q = q + parseInt(planRecurrence.quantityIntraday ? planRecurrence.quantityIntraday : 1);
          } else {
            q++;
          }
        }
      }
      yesterdary = currentDate;
      currentDate = moment(currentDate).add(1, 'days');
      if (planRecurrence.recurrenceType === 'WEEK') {
        if (!moment(yesterdary).isSame(currentDate, 'week')) {
          if (e == every) {
            e = 1;
          } else {
            e++;
          }
        }
      }

    }
    quantity = q;
  } else if (planRecurrence && planRecurrence.recurrenceType === 'MONTH') {
    const count =  handlePlanRecurrenceMonth(planRecurrence, currentDate, endDatePlusOne, every, quantity, q, e);
    quantity = count;
  }
  return quantity;
}

export const useQuantityRequests = (plan, startDate, endDatePlan, endDateAppointment) => {

  const planRecurrence = plan.recurrence || plan.schedulePlan;

  if (startDate === '') {
    return [0];
  }
  let endDate = endDateAppointment;
  if (!(endDate instanceof Date)) {
    endDate = moment(endDate, dateToServer);
  }
  if (endDatePlan != null) {
    endDate = endDatePlan;
  }

  let quantity = 1;
  const every =  planRecurrence?.every;
  
  if (planRecurrence?.endType === 'OCURRENCES' && (planRecurrence.finishOcurrences || planRecurrence.ocurrences)) {
    quantity = planRecurrence.finishOcurrences || planRecurrence.ocurrences;
  } else {
    quantity = handlePlanRecurrenceDay(planRecurrence, startDate, endDate, every, quantity);
  }
  return [quantity];
}
