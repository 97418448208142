import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';

export default (appointment) => {

    pdfMake.vfs = vfsFonts.pdfMake.vfs;

    const documentDefinition = {
        pageOrientation: 'portrait',
        pageSize: 'A4',
        info: { author: 'Mobility', subject: 'Informe de traslado', },
        content: [
            {
                height: 96,
                width: 96,
                style:'rightme',
                image: ''
            },
            {
                style: 'header',
                text: 'Informe de traslado'
            },

            {
                table: {
                    widths: ['*', '*', '*', '*'],
                    headerRows: 1,
                    body: [

                        [
                            { colSpan: 4, fillColor: '#cdcdcd', text: 'Traslado', style: 'tableSectionTitle', alignment: 'center' },
                            {}, {}, {}
                        ],
                        [
                            {fillColor: '#F1EFEF', text: 'Financiador', style: 'label',alignment: 'left' },
                            { text: appointment.financer.financer, style: 'value', alignment: 'left' },
                            {fillColor: '#F1EFEF',  text: 'Prestador', style: 'label', alignment: 'left' },
                            { text: appointment.financer.provider, style: 'value', alignment: 'left' },
                        ],
                        [
                            {fillColor: '#F1EFEF', text: 'Número', style: 'label', alignment: 'left' },
                            { text: appointment.appointmentId, style: 'value', alignment: 'left' },
                            {fillColor: '#F1EFEF', text: 'Tipo', style: 'label', alignment: 'left' },
                            { text: appointment.trasladoName, style: 'value', alignment: 'left' },
                        ],
                        [
                            { colSpan: 4, fillColor: '#cdcdcd', text: 'Paciente', style: 'tableSectionTitle', alignment: 'center' },
                            {},
                            {},
                            {}
                        ],
                        [
                            {fillColor: '#F1EFEF', colSpan: 1, text: 'Apellido', style: 'label', alignment: 'left' },
                            { colSpan: 3, text: appointment.customer.lastName, style: 'value', alignment: 'left' },
                            {},{}
                        ],
                        [
                            { fillColor: '#F1EFEF',colSpan: 1, text: 'Nombre', style: 'label', alignment: 'left' },
                            { colSpan: 3, text: appointment.customer.firstName, style: 'value', alignment: 'left' },
                            {}, {}
                        ],
                        [
                            {fillColor: '#F1EFEF', text: 'Tipo de documento', style: 'label', alignment: 'left' },
                            { text: appointment.customer.typeDocument, style: 'value', alignment: 'left' },
                            {fillColor: '#F1EFEF', text: 'Nro. documento', style: 'label', alignment: 'left' },
                            { text: appointment.customer.document, style: 'value', alignment: 'left' },
                        ],
                        [
                            { colSpan: 4, fillColor: '#cdcdcd', text: 'Chofer', style: 'tableSectionTitle', alignment: 'center' },
                            {}, {}, {}
                        ],
                        [
                            {fillColor: '#F1EFEF', colSpan: 1, text: 'Apellido', style: 'label', alignment: 'left' },
                            { colSpan: 3, text: appointment.chofer.lastName, style: 'value', alignment: 'left' },
                            {},
                            {}
                        ],
                        [
                            {fillColor: '#F1EFEF', colSpan: 1, text: 'Nombre', style: 'label', alignment: 'left' },
                            { colSpan: 3, text: appointment.chofer.firstName, style: 'value', alignment: 'left' },
                            {},{}
                        ],
                        [
                            {fillColor: '#F1EFEF', text: 'Tipo de documento', style: 'label', alignment: 'left' },
                            { text: appointment.chofer.typeDocument, style: 'value', alignment: 'left' },
                            {fillColor: '#F1EFEF', text: 'Nro. documento', style: 'label', alignment: 'left' },
                            { text: appointment.chofer.document, style: 'value', alignment: 'left' },
                        ],

                        [
                            { colSpan: 4, fillColor: '#cdcdcd', text: 'Inicio', style: 'tableSectionTitle', alignment: 'center' },
                            {}, {}, {}
                        ],
                        [
                            {fillColor: '#F1EFEF', colSpan: 2, text: 'Direccion origen programada', style: 'label', alignment: 'left' },
                            {},
                            { colSpan: 2, text: appointment.customer.address, style: 'value', alignment: 'left' },
                            {}
                        ],
                        [
                            {fillColor: '#F1EFEF', text: 'Localidad', style: 'label', alignment: 'left' },
                            { text: appointment.customer.location, style: 'value', alignment: 'left' },
                            {fillColor: '#F1EFEF', text: 'Provincia', style: 'label', alignment: 'left' },
                            { text: appointment.customer.province, style: 'value', alignment: 'left' },
                        ],
                        [
                            {fillColor: '#F1EFEF', colSpan: 2, text: 'Ubicacion al iniciar el traslado', style: 'label', alignment: 'left' },
                            {},
                            { colSpan: 2, text: appointment.customer.address, style: 'value', alignment: 'left' },
                            {}
                        ],
                        [
                            {fillColor: '#F1EFEF', text: 'Localidad', style: 'label', alignment: 'left' },
                            { text: appointment.customer.location, style: 'value', alignment: 'left' },
                            {fillColor: '#F1EFEF', text: 'Provincia', style: 'label', alignment: 'left' },
                            { text: appointment.customer.province, style: 'value', alignment: 'left' },
                        ],
                        [
                            {fillColor: '#F1EFEF', text: 'Fecha', style: 'label', alignment: 'left' },
                            { text: appointment.startDate, style: 'value', alignment: 'left' },
                            {fillColor: '#F1EFEF', text: 'Hora', style: 'label', alignment: 'left' },
                            { text: appointment.startTime, style: 'value', alignment: 'left' },
                        ],
                        [
                            {fillColor: '#F1EFEF', text: 'Inicio Certificado', style: 'label', alignment: 'left' },
                            { text: 'Si', style: 'value', alignment: 'left', colSpan:3 },
                            {},
                            {}
                        ],
                        [
                            { colSpan: 4, fillColor: '#cdcdcd', text: 'Fin', style: 'tableSectionTitle', alignment: 'center' },
                            {}, {}, {}
                        ],
                        [
                            {fillColor: '#F1EFEF', colSpan: 2, text: 'Direccion destino programada', style: 'label', alignment: 'left' },
                            {},
                            { colSpan: 2, text: appointment.nosocomio.address, style: 'value', alignment: 'left' },
                            {}
                        ],
                        [
                            {fillColor: '#F1EFEF', text: 'Localidad', style: 'label', alignment: 'left' },
                            { text: appointment.nosocomio.location, style: 'value', alignment: 'left' },
                            {fillColor: '#F1EFEF', text: 'Provincia', style: 'label', alignment: 'left' },
                            { text: appointment.nosocomio.province, style: 'value', alignment: 'left' },
                        ],
                        [
                            {fillColor: '#F1EFEF', colSpan: 2, text: 'Ubicacion al finalizar el traslado', style: 'label', alignment: 'left' },
                            {},
                            { colSpan: 2, text: appointment.address_fin, style: 'value', alignment: 'left' },
                            {}
                        ],
                        [
                            {fillColor: '#F1EFEF', text: 'Localidad', style: 'label', alignment: 'left' },
                            { text: appointment.nosocomio.location, style: 'value', alignment: 'left' },
                            {fillColor: '#F1EFEF', text: 'Provincia', style: 'label', alignment: 'left' },
                            { text: appointment.nosocomio.province, style: 'value', alignment: 'left' },
                        ],
                        [
                            {fillColor: '#F1EFEF', text: 'Fecha', style: 'label', alignment: 'left' },
                            { text: appointment.realDate, style: 'value', alignment: 'left' },
                            {fillColor: '#F1EFEF', text: 'Hora', style: 'label', alignment: 'left' },
                            { text: appointment.realTime, style: 'value', alignment: 'left' },
                        ],
                        [
                            {fillColor: '#F1EFEF', text: 'Fin Certificado', style: 'label', alignment: 'left' },
                            { text: 'Si', style: 'value', alignment: 'left', colSpan:3 },
                            {},
                            {}
                        ],
                        [
                            { colSpan: 4, fillColor: '#cdcdcd', text: 'Trayecto', style: 'tableSectionTitle', alignment: 'center' },
                            {}, {}, {}
                        ],
                        [
                            {fillColor: '#F1EFEF', text: 'Distancia recorrido', style: 'label', alignment: 'left' },
                            { text: appointment.trayecto.distance, style: 'value', alignment: 'left' },
                            {fillColor: '#F1EFEF', text: 'Duración', style: 'label', alignment: 'left' },
                            { text: appointment.trayecto.duration, style: 'value', alignment: 'left' },
                        ],

                    ],
                }
            }
        ],
        styles: {
            rightme:
            {   
                alignment: 'right'
            },
            header: {
                fontSize: 25,
                alignment: 'center',
                margin: [0, 20, 0, 50]
            },
            tableSectionTitle: {
                fontSize: 14,
                bold: true,
            },
            value: {
                fontSize: 12,
            },
            label: {
                fontSize: 12,
                bold: true
            },
            qr: {
                alignment: 'center',
                margin: [0, 50, 0, 50]
            },

        }
    };
    pdfMake.createPdf(documentDefinition).download(`traslado-${appointment.appointmentId}.pdf`);
}