/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import SelectInput from '../../../components/SelectInput';
import ValidationInput from '../../../components/ValidationInput';
import ButtonSpinner from '../../ButtonSpinner';

const VehicleInfo = (props) => {
  const { data, handleDriverValue, t, saveDriver, active } = props;
  const { models, vehicle, brands, types,wheelChairSuitable, mode, save } = data;
  const [filteredModels, setFilteredModels] = useState([]);
  const maxDate = moment().format('YYYY-MM-DD');
  const minDate = moment().subtract(120, 'years').format('YYYY-MM-DD');
  console.log(vehicle)
  

  const getModels = (brand)=>{
    console.log("seleccionado", brand)
    console.log("listado;", models)
    const newFilteredModels = models.filter(model => model.vehicleBrandId === brand);
    setFilteredModels(newFilteredModels);
  }

  useEffect(() => {
    if(vehicle.vehicleBrand){
      getModels(vehicle.vehicleBrand)
    }
  }, [vehicle.vehicleBrand])
  

  return (
    
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <SelectInput
            className="select-input"
            disabled={mode === 'view'}
            label={t('vehicle.new.brand')}
            elements={brands}
            onSelectedValue={(value) => {
              handleDriverValue('vehicleBrand', value)
              getModels(value)
            }}
            value={vehicle.vehicleBrand}
            invalid={vehicle.vehicleBrand === '' && save}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <SelectInput
            className="select-input"
            disabled={mode === 'view'}
            label={t('vehicle.new.model')}
            elements={filteredModels}
            onSelectedValue={(value) => handleDriverValue('vehicleModel', value)}
            value={vehicle.vehicleModel}
            invalid={vehicle.vehicleModel === '' && save}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>

          <SelectInput
            className="select-input"
            disabled={mode === 'view'}
            label={t('vehicle.new.type')}
            elements={types}
            onSelectedValue={(value) => handleDriverValue('carType', value)}
            value={vehicle.carType}
            invalid={vehicle.carType === '' && save}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <SelectInput
            className="select-input"
            disabled={mode === 'view'}
            label={t('vehicle.new.suitableForChairs')}
            elements={wheelChairSuitable}
            onSelectedValue={(value) => handleDriverValue('wheelChairSuitable', value)}
            value={vehicle.wheelChairSuitable}
            invalid={vehicle.wheelChairSuitable === '' && save}
          />
        </GridItem>
        
        <GridItem xs={12} sm={12} md={6}>
          <ValidationInput
            text={t('vehicle.new.year')}
            onChangeValue={(value) => handleDriverValue('year', value)}
            value={vehicle.year}
            disabled={mode === 'view'}
            invalid={vehicle.year === '' && save}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <ValidationInput
            text={t('vehicle.new.identification')}
            onChangeValue={(value) => handleDriverValue('vehiclePatent', value)}
            value={vehicle.vehiclePatent}
            disabled={mode === 'view'}
            invalid={vehicle.vehiclePatent === '' && save}
          />
        </GridItem>
        
        {mode !== 'view' && mode !== 'edit-vehicle' && active === undefined &&
          <GridItem xs={12} className="edit-customer-submit flex-end">
            <ButtonSpinner
              onClick={() => saveDriver()}
              // disabled={!dataChanged || loading}
              label={"Registrar vehículo"}
              labelLoading={t('customers.new.saving')}
              // loading={loading}
              typeButton="submit"
              color="primary"
            />
          </GridItem>
        }
      </GridContainer>
    
  )
}

export default withTranslation()(VehicleInfo);
