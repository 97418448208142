import { ApiErrorParser, CoreException, Either } from "../../../sdk";

import { ApiReasonRejectedFromMap } from "./api-reason-rejected.from-map";
import { ApiReasonRejectedFromMappper } from "./api-reason-rejected.from-mapper";
import AppointmentApiInvoker from "../../../api/AppointmentApiInvoker";
import { ReasonRejected } from "../domain/rejected-reason";
import { ReasonsRejectedRepository } from "../application/repositiories/reasons-rejected-repository";
import { injectable } from "inversify";

const mapper = new ApiReasonRejectedFromMappper();
const errorParser = new ApiErrorParser();

@injectable()
export class ApiReasonsRejectedRepository implements ReasonsRejectedRepository {

  async getReasons(): Promise<Either<CoreException, ReasonRejected[]>> {
    return new Promise((resolve, _) => {
      let result: Either<CoreException, ReasonRejected[]>;
      AppointmentApiInvoker.getRejectedReasons((res: ApiReasonRejectedFromMap[]) => {
        const reasons = res.map(reason => mapper.fromMap(reason));
        result = Either.right(reasons);
        resolve(result);
      }, (error: CoreException) => {
        const err = errorParser.parse(error);
        result = Either.left(err);
        resolve(result);
      });
    })
  }

  async sendReason(itemId: number, reasonRejectedId: number): Promise<Either<CoreException, boolean>> {
    return new Promise((resolve, _) => {
      AppointmentApiInvoker.postRejectItemRequest(itemId, reasonRejectedId, () => {
        resolve(Either.right(true));
      }, (error: CoreException) => {
        const err = errorParser.parse(error);
        resolve(Either.left(err));
      });
    })
  }

}
